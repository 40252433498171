import React, { useEffect } from "react";
import { Form, Input, Modal, Select } from "antd";
import { EventGroup } from "../../../types";
import { useMutation, useQuery } from "react-query";
import { fetchEventActions, queryTypes } from "../../../query/queries";
import { editEventGroup } from "../../../query/mutations";
import { EventsCache } from "..";

type Props = {
  initialValues: EventGroup;
  visible:boolean,
  setVisible(arg: boolean):void,
};

const layout = {
  labelCol: { span: 5 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const GroupAdd: React.FC<Props> = ({ initialValues, visible, setVisible }) => {

  const [form] = Form.useForm();

  const { data: actionList, isLoading: actionListLoading } = useQuery(
    [queryTypes.EVENT_ACTIONS_LIST, 0, "", { action_name: 1, _id: 1 }, false],
    fetchEventActions
  );

  const [editAction] = useMutation(editEventGroup, {
    onSuccess: () => {
      EventsCache.refetchQueries([queryTypes.EVENT_GROUP_LIST], {stale: true});
      EventsCache.refetchQueries([queryTypes.EVENT_ACTIONS_LIST], {stale: true});
      setVisible(false);
    },
  });

  useEffect(() => {
    if (initialValues && initialValues.group_name && visible) {
      form.resetFields();
    }
  }, [initialValues, form, visible]);

  const modalOnOk = () => {
    form.submit();
  };

  const onFormFinish = (values: any) => {
    editAction({ ...initialValues, ...values });
  };

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        setVisible(false);
      }}
      onOk={modalOnOk}
      forceRender
      getContainer={false}
    >
      <Form
        form={form}
        preserve={false}
        initialValues={initialValues}
        {...layout}
        onFinish={onFormFinish}
      >
        <Form.Item name="group_name" label="Group name">
          <Input placeholder="Group Name" disabled={true} />
        </Form.Item>
        <Form.Item name="actions" label="Actions">
          <Select
            mode="multiple"
            allowClear
            autoClearSearchValue
            bordered
            loading={actionListLoading}
            placeholder="Select actions"
            filterOption={(input, option) => ((option?.label ?? '') as string).toLowerCase().includes(input.toLowerCase())}
          >
            {actionList &&
              actionList.docs.map((item) => (
                <Option value={item._id} key={item._id}>
                  {item.action_name}
                </Option>
              ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default GroupAdd;
