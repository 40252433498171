import React from "react";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Button, message, Upload, Image, Typography, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { Notifications } from "../../../types";
import { deleteIcon, sendIcon } from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
import { CREATE_MODE } from "../../PromoCodesPage/static/promoFormModes.js";

const props = (
  dispatch: Dispatch,
  notification: Notifications
): UploadProps => {
  return {
    maxCount: 1,
    showUploadList: false,
    beforeUpload: (file) => {
      const isImage = file.type.includes("image/");
      if (!isImage) {
        message.error(`${file.name} is not an image`);
      }
      return isImage || Upload.LIST_IGNORE;
    },
    onChange: (info) => {
      dispatch(
        sendIcon({
          file: info.fileList[0].originFileObj as File,
          _id: notification._id,
        })
      );
    },
    customRequest: () => {},
  };
};

const UploadIcon: React.FC<{
  formInitialValues: Notifications;
  formMode: string;
}> = ({ formInitialValues, formMode }) => {
  const dispatch = useDispatch();
  const { iconLoading, iconDeleteLoading } = useSelector(
    (state: RootState) => state.notifications
  );
  const isLoading = iconLoading || iconDeleteLoading;

  return (
    <Space size="small" direction="vertical">
      <Typography.Text>Icon:</Typography.Text>
      {formMode === CREATE_MODE ? (
        <Typography.Text type="secondary">
          The icon can only be uploaded/changed in edit mode after the
          notification has been created
        </Typography.Text>
      ) : (
        <>
          {formInitialValues.icon && (
            <Image width={200} src={formInitialValues.icon} />
          )}
          <Typography.Text type="danger">
            The icon is changed immediately
          </Typography.Text>
          <Upload {...props(dispatch, formInitialValues)} disabled={isLoading}>
            <Button icon={<UploadOutlined />}>
              {iconLoading ? "Uploading" : "Upload image"}
            </Button>
          </Upload>
          {formInitialValues.icon && (
            <Button
              danger
              onClick={() => dispatch(deleteIcon(formInitialValues._id))}
            >
              {iconDeleteLoading ? "Deleting" : "Delete"}
            </Button>
          )}
        </>
      )}
    </Space>
  );
};
export default UploadIcon;
