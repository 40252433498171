import { Button, Col, Row, message, Input, Space } from "antd";
import React, { useEffect, useState } from "react";
import { RootState } from "../../../redux/reducers";
import { connect, ConnectedProps } from "react-redux";
import {
  fetchActiveJobs,
  setMaintenance,
  setMaintenanceActive,
  setMaintenanceDisabled,
} from "../../../redux/actions";
import "./suspense.scss";
import {
  MaintenanceFailed,
  MaintenanceSuccessActive,
  MaintenanceSuccessDisabled,
} from "../../../redux/selectors/notificationsSelector";
import { useQuery } from "react-query";
import { getServiceSuspensionStatus } from "../Channels/query";

const JobsSuspense: React.FC<Props> = ({
  activeJobs,
  fetchActiveJobs,
  setMaintenanceActive,
  setMaintenanceDisabled,
  setMaintenance,
  maintenanceStatus,
  maintenanceFailed,
  maintenanceLoading,
  maintenanceSuccessActive,
  maintenanceSuccessDisabled,
}) => {
  const [minutes, setMinutes] = useState(10);
  useQuery("serviceSuspensionStatus", getServiceSuspensionStatus, {
    onSuccess: (data) => setMaintenance(data),
    refetchInterval: 10000,
  });

  useEffect(() => {
    fetchActiveJobs();
  }, [fetchActiveJobs]);

  useEffect(() => {
    if (maintenanceFailed) {
      message.error("Maintenance action error, check api logs for more info.");
    }
  }, [maintenanceFailed]);

  useEffect(() => {
    if (maintenanceSuccessActive) message.info("Maintenance active");
  }, [maintenanceSuccessActive]);

  useEffect(() => {
    if (maintenanceSuccessDisabled) message.info("Maintenance disabled");
  }, [maintenanceSuccessDisabled]);

  const handleClick = () => {
    if (!maintenanceStatus && !maintenanceLoading)
      setMaintenanceActive({ minutes });
    if (maintenanceStatus && !maintenanceLoading) setMaintenanceDisabled();
  };

  return (
    <div className="suspension-container">
      <Space size={"large"} direction={"vertical"}>
        <Col span={24} className="jobs-counter">
          <p>Active jobs</p>
          <p>{activeJobs}</p>
        </Col>
        <Col span={24} className="form-container">
          <Space size={"middle"} direction={"vertical"}>
            <Col>
              <Button
                type="primary"
                onClick={handleClick}
                loading={maintenanceLoading}
              >
                {maintenanceStatus
                  ? "Stop service maintenance"
                  : "Start service maintenance"}
              </Button>
            </Col>
            <Col>
              {!maintenanceStatus && (
                <>
                  <label>Minutes: </label>
                  <Input
                    placeholder="Maintenance time"
                    defaultValue={10}
                    value={minutes}
                    type="text"
                    onChange={(e) => setMinutes(Number(e.target.value))}
                  />
                </>
              )}
            </Col>
          </Space>
        </Col>
      </Space>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  activeJobs: state.jobs.activeJobs,
  maintenanceStatus: state.notifications.maintenance,
  maintenanceLoading: state.notifications.maintenanceLoading,
  maintenanceFailed: MaintenanceFailed(state),
  maintenanceSuccessActive: MaintenanceSuccessActive(state),
  maintenanceSuccessDisabled: MaintenanceSuccessDisabled(state),
});

const mapDispatchToProps = {
  fetchActiveJobs: fetchActiveJobs,
  setMaintenanceActive: setMaintenanceActive,
  setMaintenanceDisabled: setMaintenanceDisabled,
  setMaintenance: setMaintenance,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(JobsSuspense);
