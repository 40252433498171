import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../../redux/store';
import { getUserActions, UserActionSearchPayload } from '../../../redux/actions/index';
import Pagination from 'antd/lib/pagination';
import { userActions, userActionsPagination } from '../../../redux/selectors/userSelector';
import ActionHistoryList from './ActionHistoryList';

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const ActionHistory: React.FunctionComponent<Props> = ({
  userActions,
  userActionsPagination,
  getUserActions,
}) => {
  useEffect(() => {
    getUserActions();
  }, [getUserActions]);

  const handlePaginationChange = (page: number) => {
    getUserActions({ page } as UserActionSearchPayload);
  }

  return (
    <div className='user-details-credits'>
      <h3 className='subscription-details-header'>Events</h3>
      {userActions.length ?
        <>
          <ActionHistoryList actions={userActions} />
          <Pagination
            current={userActionsPagination.current}
            pageSize={userActionsPagination.pageSize}
            total={userActionsPagination.total}
            onChange={handlePaginationChange}
          />
        </> :
        <div className='user-details-credits-content'>
          <span>No actions have been taken.</span>
        </div>
      }
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  userActions: userActions(state),
  userActionsPagination: userActionsPagination(state)
})

const mapDispatch = {
  getUserActions
}

export default connect(mapState, mapDispatch)(ActionHistory);
