import React, { useState } from "react";
import { Button, Row, Space } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import { getAuthToken } from "../../utils/storage";
import { authorizationHeaders } from "../../utils/api";
import { API } from "../../config";

const DownloadFbCatalog = () => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadFbCatalog = async () => {
    setIsLoading(true);
    const token = getAuthToken();
    if (!token) return;
    try {
      const res = await axios.get(
        API("plans/get-facebook-catalog-xml"),
        authorizationHeaders(token)
      );
      const blob = new Blob([res.data], { type: "text/xml" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `fb_catalog_${new Date().toISOString()}.xml`;
      link.click();
    } catch {}
    setIsLoading(false);
  };

  return (
    <Space size={16}>
      <div>Facebook catalog:</div>
      <Button
        type="primary"
        shape="round"
        icon={<DownloadOutlined />}
        size="small"
        loading={isLoading}
        onClick={isLoading ? undefined : () => downloadFbCatalog()}
      >
        Download
      </Button>
    </Space>
  );
};

export default DownloadFbCatalog;
