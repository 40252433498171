import { Button } from 'antd';
import React from 'react';
import { useMutation } from 'react-query';
import { testEventMutation } from '../../../query/mutations';


const TestEvents:React.FC = () => {

  const [mutate] = useMutation(testEventMutation);

  return(<Button type="primary" onClick={() => mutate()}>TEST EVENT</Button>)
}

export default TestEvents;