import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import TextArea from 'antd/lib/input/TextArea';
import { RootStateType } from '../../redux/store';
import { isUserActionSet, userAction, userActionComment } from '../../redux/selectors/userSelector';
import { connect } from 'react-redux';
import { clearUserAction } from '../../redux/actions';
import { setUserActionComment } from '../../redux/actions/index';

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const ActionModal: React.FunctionComponent<Props> = ({
  userAction,
  clearUserAction,
  setUserActionComment,
  isUserActionSet,
  userActionComment
}) => {
  return (
    <Modal
      title={userAction.name}
      visible={isUserActionSet}
      onOk={userAction.onAccept}
      onCancel={() => {
        clearUserAction();
        // setAction('')
        // setComment('')
        // setUpdatedPurchasedCredits(0)
        // setUpdatedSubscriptionCredits(0)
      }}
      >
        {userAction.Component ?
          <userAction.Component /> : null}

        <div className='action-modal-comment'>
          <TextArea
            rows={5}
            value={userActionComment}
            placeholder='Optional comment'
            onChange={(e) => setUserActionComment(e.target.value)}
          />
        </div>
      </Modal>
  );
};

const mapState = (state: RootStateType) => ({
  isUserActionSet: isUserActionSet(state),
  userActionComment: userActionComment(state),
  userAction: userAction(state)
})

const mapDispatch = {
  setUserActionComment,
  clearUserAction
}

export default connect(mapState, mapDispatch)(ActionModal);
