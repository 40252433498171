import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  notification,
  Popover,
  Row,
  Switch,
  Typography,
} from "antd";
import React from "react";
import { useGetQuestionnaireConfig } from "../../../query/queries";
import { useEditQuestionnaireConfig } from "../../../query/mutations";
import { QuestionnaireConfig } from "../../../types";
import { QuestionCircleOutlined } from "@ant-design/icons";

type NotificationFormProps = {
  isQuestionnaire: boolean;
};
const NotificationForm = ({ isQuestionnaire }: NotificationFormProps) => {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const { data, isError } = useGetQuestionnaireConfig({
    onSuccess: (data: QuestionnaireConfig) => setFieldsValue(data.payload),
  });
  const [mutate, mutation] = useEditQuestionnaireConfig({
    onSuccess: () =>
      notification.success({
        message: "Success!",
        description: "Questionnaire config edited successfully!",
      }),
    onError: () =>
      notification.error({
        message: "Error!",
        description:
          "Editing questionnaire configuration failed. Check logs for more info.",
      }),
  });
  const onFinish = (fieldsValue: any) => {
    (async () => {
      await mutate(fieldsValue);
    })();
  };

  return (
    isQuestionnaire && (
      <div
        style={{
          background: "rgb(225 225 225 / 52%)",
          padding: "1rem",
          marginTop: "2rem",
        }}
      >
        <Typography.Title level={5} style={{ marginBottom: "1rem" }}>
          Questionnaire config
        </Typography.Title>
        {isError ? (
          <Typography.Text type="danger">Something went wrong!</Typography.Text>
        ) : (
          <Form
            name="QuestionnaireForm"
            initialValues={data && data.payload}
            form={form}
            onFinish={onFinish}
            disabled={mutation.isLoading}
          >
            <p>
              Help:{" "}
              <a
                href="https://deep-image.atlassian.net/l/cp/5AA3PWdw"
                target="_blank"
                rel="noreferrer"
              >
                https://deep-image.atlassian.net/l/cp/5AA3PWdw
              </a>
            </p>
            <Row>
              <Form.Item name="creditsPercentage" label="Credits percentage">
                <InputNumber min={0} max={100} />
              </Form.Item>
              <Popover
                placement="top"
                content={
                  <div>
                    <p>
                      The percentage of the user's subscription credits that
                      will be awarded for completing the questionnaire."
                    </p>
                    <p>Max: 100</p>
                    <p>Min: 0</p>
                  </div>
                }
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Row>
            <Col span={1} />
            <Row>
              <Form.Item name="maxCredits" label="Max credits">
                <InputNumber min={0} max={10000} />
              </Form.Item>
              <Popover
                placement="top"
                content={
                  <div>
                    <p>
                      The maximum amount of credits that the user can get for
                      completing the questionnaire.
                    </p>
                    <p>Max: 10 000</p>
                    <p>Min: 0</p>
                  </div>
                }
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Row>
            <Row>
              <Form.Item name="minCredits" label="Min credits">
                <InputNumber min={0} max={10000} />
              </Form.Item>
              <Popover
                placement="top"
                content={
                  <div>
                    <p>
                      The minimum amount of credits that the user can get for
                      completing the questionnaire. Cannot be greater then the
                      max credits.
                    </p>
                    <p>Max: 10 000</p>
                    <p>Min: 0</p>
                  </div>
                }
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Row>
            <Form.Item
              wrapperCol={{ span: 24 }}
              name="sharedUrl"
              label="Shared form url"
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item wrapperCol={{ span: 24 }} name="url" label="Form url">
              <Input type="text" />
            </Form.Item>
            <Form.Item
              name="paidUsers"
              label="Paid Users"
              valuePropName="checked"
            >
              <Switch checkedChildren="Paid" unCheckedChildren="All" />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {mutation.isLoading
                  ? "Loading..."
                  : "Edit questionnaire config"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    )
  );
};

export default NotificationForm;
