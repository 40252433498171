import { authorizationHeaders } from "./../../../utils/api";
import { getAuthToken } from "./../../../utils/storage";
import { API } from "../../../config";
import axios from "axios";

export type ChannelsStatsResponse = {
  api: number;
  web: number;
};

export const fetchChannelsStats = async (): Promise<ChannelsStatsResponse> => {
  const token = getAuthToken();
  if (!token) return {} as ChannelsStatsResponse;
  const { data } = await axios.get(
    API("jobs/channel/count"),
    authorizationHeaders(token)
  );
  return data;
};
export const getServiceSuspensionStatus = async (): Promise<boolean> => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required")
  }
  const { data } = await axios.get(
    API("notifications/maintenance/status"),
    authorizationHeaders(token)
  );
  return data;
};
