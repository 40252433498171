import React from "react";
import { connect } from "react-redux";
import { RootStateType } from "../../redux/store";
import { user, isAuthenticated } from "../../redux/selectors/authSelector";
import { Link } from "react-router-dom";
import { logout } from "../../redux/actions";
import {
  DASHBOARD_PAGE,
  EVENTS_PAGE,
  JOBS_PAGE,
  NOTIFICATION_PAGE,
  PROMO_CODES_PAGE,
  RATING_JOBS_PAGE,
  USERS_PAGE,
  SHOPIFY_PRODUCTS_PAGE,
  IMAGE_STATS, PLATFORMS_PAGE, PROMPTS_PAGE, PAYMENTS_PAGE
} from "../../paths";

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const Header: React.FunctionComponent<Props> = ({
  user,
  isAuthenticated,
  logout,
}) => {
  return (
    <div className="header">
      {isAuthenticated ? (
        <>
          <div className="header-leftside">
            <Link to={DASHBOARD_PAGE}>Dashboard</Link>
            <div className="header-link">
              <Link to={USERS_PAGE}>Users</Link>
            </div>
            <Link to={PROMO_CODES_PAGE}>Promo Codes</Link>
            <div className="header-link">
              <Link to="/domains">Domains</Link>
            </div>
            <div className="header-link">
              <Link to={NOTIFICATION_PAGE}>Notifications</Link>
            </div>
            <div className="header-link">
              <Link to={JOBS_PAGE}>Jobs</Link>
            </div>
            <div className="header-link">
              <Link to={EVENTS_PAGE}>Events</Link>
            </div>
            <div className="header-link">
              <Link to={RATING_JOBS_PAGE}>Ratings</Link>
            </div>
            <div className="header-link">
              <Link to={IMAGE_STATS}>Image Stats</Link>
            </div>
            <div className="header-link">
              <Link to={SHOPIFY_PRODUCTS_PAGE}>Shopify products</Link>
            </div>
            <div className="header-link">
              <Link to={PLATFORMS_PAGE}>Platforms</Link>
            </div>
            <div className="header-link">
              <Link to={PROMPTS_PAGE}>Prompts</Link>
            </div>
            <div className="header-link">
              <Link to={PAYMENTS_PAGE}>Payments</Link>
            </div>
          </div>
          <div className="header-rightside">
            <span className="header-email">{user.email}</span>
            <Link to="#" onClick={() => logout()}>
              Log out
            </Link>
          </div>
        </>
      ) : (
        <div className="header-leftside">
          <Link to={DASHBOARD_PAGE}>Dashboard</Link>
        </div>
      )}
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  user: user(state),
  isAuthenticated: isAuthenticated(state),
});

const mapDispatch = {
  logout,
};

export default connect(mapState, mapDispatch)(Header);
