import {EventAction, EventGroup, Platform, Prompt, PromptTag, QuestionnaireConfig, ShopifyProduct} from './../types';
import { authorizationHeaders } from "../utils/api";
import { API } from "../config";
import axios from "axios";
import { getAuthToken } from "../utils/storage";
import {MutationConfig, useMutation} from "react-query";

export  const createEventGroup = async (values: any): Promise<any> => {
  const token = getAuthToken();
  if(!token) return {};
  const res = await axios.post(API("events/groups"), values, authorizationHeaders(token));
  return res.data;
}

export const createEventAction = async (values: any): Promise<any> => {
  const token = getAuthToken()
  if(!token) return {};
  const res = await axios.post(API("events/actions"), values, authorizationHeaders(token));
  return res.data;
}

export const testEventMutation = async (): Promise<any> => {
  const token = getAuthToken();
  if(!token) return {};
  const res = await axios.post(API("events/test"), {}, authorizationHeaders(token));
  return res.data;
}

export const editEventGroup = async(values: EventGroup ) => {
  const token = getAuthToken();
  if (!token) return {};
  const res = await axios.put(API("events/groups"), values, authorizationHeaders(token));
  return res.data;
}

export const deleteEventAction = async(_id: string ): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};
  const res = await axios.delete(API(`events/actions/${_id}`), authorizationHeaders(token));
  return res.data;
}

export const editEventAction = async (values: EventAction): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};
  const res = await axios.put(API(`events/actions/`), values, authorizationHeaders(token));
  return res.data;
}

export const editShopifyProduct = async (product: ShopifyProduct): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};
  const postData = {
    _id: product._id,
    id: product.id,
    name: product.name,
    providers: {
      shopify: {
        billing: product.billing,
        images: product.images,
      }
    },
    price: product.price
  }
  const res = await axios.post(API(`shopify/alterproduct`), postData, authorizationHeaders(token));
  return res.data;
}

export const editPlatform = async (platform: Platform): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};

  let res
  if (platform._id) {
    res = await axios.put(API(`platforms`), platform, authorizationHeaders(token));
  } else {
    res = await axios.post(API(`platforms`), platform, authorizationHeaders(token));
  }

  return res.data;
}

export const deletePlatform = async(_id: string ): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};
  const res = await axios.delete(API(`platforms/${_id}`), authorizationHeaders(token));
  return res.data;
}

const editQuestionnaireConfig = async(payload: QuestionnaireConfig ): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};
  const res = await axios.put(API(`questionnaire/config`), payload, authorizationHeaders(token));
  return res.data;
}

export const useEditQuestionnaireConfig = (params = {}) => {
  return useMutation(editQuestionnaireConfig, {
    ...params
  })
}

export const editPrompt = async (prompt: Prompt): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};

  let res
  if (prompt._id) {
    res = await axios.put(API(`prompts`), prompt, authorizationHeaders(token));
  } else {
    res = await axios.post(API(`prompts`), prompt, authorizationHeaders(token));
  }

  return res.data;
}


export const deletePrompt = async(_id: string ): Promise<any> => {
  const token = getAuthToken();
  if (!token) return {};
  const res = await axios.delete(API(`prompts/${_id}`), authorizationHeaders(token));
  return res.data;
}

export const addPromptTag = async (name: string) => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required");
  }

  const res = await axios.post<PromptTag>(API(`prompts/tag`), {name}, authorizationHeaders(token));
  return res.data;
}

export const updatePromptTag = async (tag: PromptTag) => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required");
  }

  const res = await axios.patch<PromptTag>(API(`prompts/tag/${tag.id}`), {name: tag.name}, authorizationHeaders(token));
  return res.data;
}

export const deletePromptTag = async (id: string) => {
  const token = getAuthToken();
  if (!token) {
    throw Error("Authorization token required");
  }

  const res = await axios.delete<PromptTag>(API(`prompts/tag/${id}`), authorizationHeaders(token));
  return res.data;
}
