import {createReducer} from "@reduxjs/toolkit";
import {fetchAllRatingJobs, fetchAllRatingJobsFailed, fetchAllRatingJobsSuccess} from "../actions";
import { Job, JobsOrderUsages, MappedJob, Pagination, ProcessingJob } from "../../types";
import {getImageUrlByProxy} from "../../utils/api";
import _ from 'lodash';

const DEFAULT_PAGINATION = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  page: 1,
  nextPage: null,
  prevPage: null,
  pagingCounter: 1,
  totalDocs: 0,
  totalPages: 0,
};

const DEFAULT_STATE = {
  jobs: [] as Array<MappedJob>,
  jobsSuccess: false as Boolean,
  jobsFailed: false as Boolean,
  jobsLoading: false as Boolean,
  sort: {} as JobsOrderUsages & { rating ?: number},
};

const mapImages = (jobs: Job[]) => jobs.map((job) => ({
  ...job,
  origin_img_url: "",
  result_img_url: "",
  user: job.result.filter((resultJob => resultJob.user)).map((job => job.user))[0]
}));

const ratingAllJobsReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(fetchAllRatingJobs, (state, action) => {
      state.jobsFailed = false;
      state.jobsSuccess = false;
      state.jobsLoading = true;
    })
    .addCase(fetchAllRatingJobsSuccess, (state, action) => {
      const { docs, sort } = action.payload;
      state.jobsSuccess = true;
      state.jobsFailed = false;
      state.jobsLoading = false;
      state.jobs = mapImages(docs);
      state.sort = sort || {};
    })
    .addCase(fetchAllRatingJobsFailed, (state, action) => {
      state.jobsFailed = true;
      state.jobsLoading = false;
      state.jobsSuccess = false;
    });
});

export default ratingAllJobsReducer;
