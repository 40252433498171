import React from "react";
import { Tabs } from "antd";
import FeatureFlag from "./FeatureFlag";
import Users from "./Users";

const { TabPane } = Tabs;

const UsersPage: React.FunctionComponent = () => {
  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab="Users" key="1">
        <Users />
      </TabPane>
      <TabPane tab="Feature flag token" key="2">
        <FeatureFlag />
      </TabPane>
    </Tabs>
  );
};

export default UsersPage;
