import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../../redux/store';
import { InputNumber } from 'antd';
import { totalSubscriptionCredits, purchasedCredits, subscriptionCredits, updatedCredits } from '../../../redux/selectors/userSelector';
import { setUpdatedCredits } from '../../../redux/actions';
import { UpdatedCredits } from '../../../types';

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const ManageCredits: React.FunctionComponent<Props> = ({
  updatedCredits,
  purchasedCredits,
  subscriptionCredits,
  totalSubscriptionCredits,
  setUpdatedCredits
}) => {
  useEffect(() => {
    setUpdatedCredits({
      purchasedCredits,
      subscriptionCredits
    } as UpdatedCredits);
  }, [setUpdatedCredits, purchasedCredits, subscriptionCredits]);

  return (
    <div className='manage-credits'>
      <p>Purchased credits:</p>
      <InputNumber
        value={updatedCredits.purchasedCredits}
        onChange={(x: any) => {
          setUpdatedCredits({
            purchasedCredits: x,
            subscriptionCredits: updatedCredits.subscriptionCredits
          });
        }}
      />

      <p className='manage-credits-label'>Subscription credits:</p>
      <InputNumber
        value={updatedCredits.subscriptionCredits}
        onChange={(x: any) => {
          setUpdatedCredits({
            purchasedCredits: updatedCredits.purchasedCredits,
            subscriptionCredits: x
          });
        }}
      /> / {totalSubscriptionCredits}

      <p className='manage-credits-label'>Add/subtract amount:</p>
      <InputNumber
        placeholder='Add 100 credits'
        onChange={(x: any) => {
          setUpdatedCredits({
            purchasedCredits: updatedCredits.purchasedCredits,
            subscriptionCredits: updatedCredits.subscriptionCredits,
            additionalAmount: x
          });
        }}
      />
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  updatedCredits: updatedCredits(state),
  purchasedCredits: purchasedCredits(state),
  subscriptionCredits: subscriptionCredits(state),
  totalSubscriptionCredits: totalSubscriptionCredits(state)
})

const mapDispatch = {
  setUpdatedCredits
}

export default connect(mapState, mapDispatch)(ManageCredits);