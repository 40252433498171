import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './components/Login';
import { RootStateType } from './redux/store';
import { token } from './redux/selectors/authSelector';
import { connect } from 'react-redux';
import MainPage from './components/MainPage';
import DashboardPage from './components/Dashboard';
import Header from './components/Header';
import {
  DASHBOARD_PAGE,
  USERS_PAGE,
  LOGIN_PAGE,
  MAIN_PAGE,
  USER_DETAILS,
  DOMAINS_PAGE,
  PROMO_CODES_PAGE,
  NOTIFICATION_PAGE,
  JOBS_PAGE,
  EVENTS_PAGE,
  RATING_JOBS_PAGE,
  SHOPIFY_PRODUCTS_PAGE,
  IMAGE_STATS, PLATFORMS_PAGE, PROMPTS_PAGE, PAYMENTS_PAGE,
} from './paths';
import Details from './components/Users/Details';
import Domains from './components/Domains/index';
import PromoCodesPage from './components/PromoCodesPage/PromoCodesPage';
import NotificationsPage from './components/NotificationsPage/NotificationsPage';
import JobsPage from './components/JobsPage/JobsPage';
import EventsPage from './components/EventsPage';
import Ratings from './components/Ratings/index';
import ShopifyProducts from './components/ShopifyProductsPage/index';
import UsersPage from './components/Users';
import ImageStats from './components/ImageStats/image_stats'
import Platforms from "./components/PlatformsPage";
import Prompts from "./components/PromptsPage";
import Payments from "./components/Payments";

type Props = ReturnType<typeof mapStateToProps>;

const Routes: React.FunctionComponent<Props> = ({ token }) => (
  <>
    {token && <Header />}
    <Switch>
      <Route exact path={LOGIN_PAGE} component={Login} />
      <Route exact path={USER_DETAILS(':id')} component={Details} />
      {!token && <Redirect to={LOGIN_PAGE} />}
      <Route exact path={JOBS_PAGE} component={JobsPage}/>
      <Route exact path={PROMO_CODES_PAGE} component={PromoCodesPage}/>
      <Route exact path={NOTIFICATION_PAGE} component={NotificationsPage}/>
      <Route exact path={MAIN_PAGE} component={MainPage} />
      <Route exact path={DASHBOARD_PAGE} component={DashboardPage} />
      <Route exact path={USERS_PAGE} component={UsersPage} />
      <Route exact path={DOMAINS_PAGE} component={Domains} />
      <Route exact path={EVENTS_PAGE} component={EventsPage} />
      <Route exact path={RATING_JOBS_PAGE} component={Ratings} />
      <Route exact path={IMAGE_STATS} component={ImageStats} />
      <Route exact path={SHOPIFY_PRODUCTS_PAGE} component={ShopifyProducts} />
      <Route exact path={PLATFORMS_PAGE} component={Platforms} />
      <Route exact path={PROMPTS_PAGE} component={Prompts} />
      <Route exact path={PAYMENTS_PAGE} component={Payments} />
      <Redirect to={MAIN_PAGE} />
    </Switch>
  </>
);

const mapStateToProps = (state: RootStateType) => ({
  token: token(state),
});

export default connect(mapStateToProps)(Routes);
