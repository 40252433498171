import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Modal, Table, Space, Button, Form, Input } from "antd";
import { ShopifyProduct, ShopifyProductsList } from "../../types";
import { fetchShopifyProductsQuery, queryTypes } from "../../query/queries";
import { editShopifyProduct } from "../../query/mutations";
import { useMutation, useQuery } from "react-query";
import { toNumber } from "lodash";

const ShopifyProducts: React.FC<Props> = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<
    ShopifyProduct | undefined
  >(undefined);

  const { data: items } = useQuery(
    [queryTypes.SHOPIFY_PRODUCTS_LIST],
    fetchShopifyProductsQuery
  );

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(selectedProduct);
  }, [form, selectedProduct]);

  const openEditModal = (product: ShopifyProduct) => {
    setSelectedProduct(product);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [editProduct] = useMutation(editShopifyProduct, {
    onSuccess: () => {
      console.log("Success");
    },
    onError: () => {
      console.error(`Error occured, check logs`);
    },
  });

  const getSortedProducts = () => {
    let i = 0;
    const suppliedList = items?.items.map((p) => {
      p.key = i++;
      return p;
    });
    return suppliedList?.sort(
      (a, b) =>
        ((a.billing < b.billing && 1) || -1) &
        ((a.images > b.images && 1) || -1)
    );
  };
  const [fetchedData, setFetchedData] = useState<ShopifyProduct[] | undefined>(
    undefined
  );

  useEffect(() => {
    setFetchedData(getSortedProducts());
  }, [items]);

  const onFinish = (values: ShopifyProduct) => {
    setIsModalVisible(false);
    values.images = toNumber(values.images)
    values.price = toNumber(values.price)
    editProduct(values);
    console.log("Success:", values);
    setFetchedData(getSortedProducts());
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const columns = [
    {
      title: "Product ID",
      dataIndex: "id",
      key: "id",
      render: (id: String) => id,
    },
    {
      title: "Product name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Billing",
      dataIndex: "billing",
      key: "billing",
    },
    {
      title: "Credits",
      dataIndex: "images",
      key: "images",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Action",
      key: "action",
      render: (product: ShopifyProduct) => (
        <Button type="primary" onClick={() => openEditModal(product)}>
          Edit
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Modal
        title="Edit Product"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
      >
        <Space>
          <Form
            form={form}
            name="update_product"
            labelCol={{
              span: 16,
            }}
            wrapperCol={{
              span: 8,
            }}
            initialValues={selectedProduct}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item name="_id" label="Native ID">
              <Input disabled />
            </Form.Item>

            <Form.Item
              name="id"
              label="ID"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Product name"
              name="name"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Billing"
              name="billing"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Credits"
              name="images"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number"/>
            </Form.Item>

            <Form.Item
              label="Price"
              name="price"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input type="number"/>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 16,
                span: 16,
              }}
            >
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </Modal>
      <Table columns={columns} dataSource={fetchedData} />
    </div>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {};

export default connector(ShopifyProducts);
