import {APP_NAME} from '../config';

export const postHeaders = {
  headers: {
    'x-application-name': APP_NAME,
    'Content-Type': 'application/json',
    'x-forwarded-for': '0.0.0.0'
  }
}

export const authorizationHeaders = (token: string) => ({
  headers: {
    authorization: `Bearer ${token}`,
    'x-application-name': APP_NAME 
  }
})

export const getImageUrlByProxy = (imageUrl: string) => imageUrl
