import React, { useState } from "react";
import { EventGroup } from "../../../types";
import "./index.scss";
import GroupAdd from "../GroupEdit";
import GroupTable from "./GroupTable";

const GroupsList: React.FC = () => {
  const [formValues, setFormValues] = useState({} as EventGroup);
  const [modalVisible, setModalVisible] = useState(false);

  const openModal = (values: EventGroup) => {
    setFormValues(values);
    setModalVisible(true);
  };

  return (
    <>
      <GroupAdd
        initialValues={formValues}
        visible={modalVisible}
        setVisible={setModalVisible}
      />
      <GroupTable openModal={openModal} />
    </>
  );
};

export default GroupsList;
