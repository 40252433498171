import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Switch,
  Modal,
  Col,
  Row,
  Popover,
  Select,
  Space,
} from "antd";
import type { SelectProps } from "antd";
import "./style.scss";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  ApplicablePlans,
  PROMO_TYPE_PRODUCT_TYPES,
  PromoType,
  PromoTypeCreatePayload,
  PROMO_TYPE_KINDS,
} from "../../../../types";
import { CREATE_MODE, EDIT_MODE } from "../../static/promoFormModes";
import { QuestionCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import { popOverTypeForm } from "../../static/popOverMessages.js";
import { useGetDistinctCreditAmountFromPlans } from "../../../../query/queries";

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};

type Props = {
  visible: boolean;
  initialValues: PromoType;
  closeFormModal(): void;
  onTypeEditSubmit(type: PromoType): void;
  createType(values: PromoTypeCreatePayload): void;
  deleteType(id: string): void;
};

const TypesForm: React.FunctionComponent<Props> = ({
  initialValues,
  closeFormModal,
  onTypeEditSubmit,
  createType,
  deleteType,
  visible,
}) => {
  const [form] = Form.useForm();
  const [formMode, setFormMode] = useState(CREATE_MODE);
  const { resetFields } = form;
  const kind = Form.useWatch("kind", form);
  const mode = Form.useWatch("mode", form);
  const isStripeKind = kind === PROMO_TYPE_KINDS.STRIPE_SUBSCRIPTION;
  const [applicablePlans, setApplicablePlans] = useState<
    SelectProps["options"]
  >();
  useGetDistinctCreditAmountFromPlans({
    onSuccess: (data: ApplicablePlans) => {
      setApplicablePlans(data.map((n) => ({ label: n, value: n })));
    },
  });

  useEffect(() => {
    if (mode && kind === PROMO_TYPE_KINDS.STRIPE_SUBSCRIPTION) {
      form.setFieldValue("mode", false);
    }
  }, [form, kind, mode]);

  useEffect(() => {
    if (Object.keys(initialValues).length) {
      setFormMode(EDIT_MODE);
      resetFields();
    } else {
      setFormMode(CREATE_MODE);
      resetFields();
    }
  }, [initialValues, resetFields]);

  useEffect(() => {
    form.resetFields();
  }, [form, initialValues]);

  const onFinish = (values: any) => {
    values.usage = Number(values.usage);
    values.value = Number(values.value);
    values.user_usage = Number(values.user_usage);
    values.product_type = Number(values.product_type);

    if (typeof values.mode === "undefined") {
      if (initialValues.mode) {
        values.mode = initialValues.mode;
      } else {
        values.mode = false;
      }
    }
    if (formMode === EDIT_MODE) {
      const merged = { ...initialValues, ...values };
      onTypeEditSubmit(
        _.pick(merged, [
          "mode",
          "name",
          "usage",
          "user_usage",
          "_id",
          "__v",
          "value",
          "kind",
          "product_type",
          "applicable_plans",
        ])
      );
    }
    if (formMode === CREATE_MODE) {
      createType(
        _.pick(values, [
          "name",
          "value",
          "usage",
          "user_usage",
          "mode",
          "creator",
          "kind",
          "product_type",
          "applicable_plans",
        ])
      );
    }
    closeFormModal();
  };

  const modalConfirm = () => {
    Modal.confirm({
      title: `Delete code ${form.getFieldValue("name")}`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: (close) => {
        deleteType(initialValues._id);
        close();
        closeFormModal();
        form.resetFields();
      },
    });
  };

  const rules = {
    name: [{ required: true }],
    value: [{ required: true }],
    usage: [{ required: true }],
    user_usage: [{ required: true }],
    product_type: [{ required: true }],
    kind: [{ required: true }],
  };

  return (
    <Modal
      title={
        formMode === CREATE_MODE ? "Create new promo type" : "Edit promo type"
      }
      okText={formMode === CREATE_MODE ? "Create" : "Edit"}
      cancelText="Cancel"
      onOk={() => {
        form.validateFields().then((values) => {
          onFinish(values);
          form.resetFields();
          closeFormModal();
        });
      }}
      onCancel={() => {
        form.resetFields();
        closeFormModal();
      }}
      visible={visible}
      forceRender
    >
      {formMode === EDIT_MODE && (
        <Button
          className="btn-delete-position-right"
          type="primary"
          danger
          onClick={modalConfirm}
        >
          Delete
        </Button>
      )}
      <div style={{ clear: "both" }}></div>
      <Form
        {...layout}
        form={form}
        name="code-form"
        onFinish={onFinish}
        initialValues={initialValues}
      >
        <Form.Item name="name" label="Name" rules={rules.name}>
          <Row>
            <Col span={18}>
              <Form.Item name="name" noStyle>
                <Input
                  id="name"
                  disabled={formMode === EDIT_MODE && isStripeKind}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="value" label="Value" rules={rules.value}>
          <Row>
            <Col span={8}>
              <Form.Item name="value" noStyle>
                <Input
                  type="number"
                  disabled={formMode === EDIT_MODE && isStripeKind}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Popover
                placement="top"
                title="Value"
                content={popOverTypeForm.value}
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="usage" label="Usage" rules={rules.usage}>
          <Row>
            <Col span={8}>
              <Form.Item name="usage" noStyle>
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Popover
                placement="top"
                title="Usage"
                content={popOverTypeForm.usage}
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="user_usage"
          label="User usage"
          rules={rules.user_usage}
        >
          <Row>
            <Col span={8}>
              <Form.Item name="user_usage" noStyle>
                <Input type="number" />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Popover
                placement="top"
                title="User usage"
                content={popOverTypeForm.user_usage}
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="product_type"
          label="Product type"
          rules={rules.product_type}
        >
          <Row>
            <Col span={8}>
              <Form.Item name="product_type" noStyle>
                <Select
                  options={Object.entries(PROMO_TYPE_PRODUCT_TYPES)
                    .filter(([key]) => isNaN(Number(key)))
                    .map(([key, value]) => ({
                      value,
                      label: key,
                    }))}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Popover
                placement="top"
                title="Product type"
                content={popOverTypeForm.product_type}
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item name="kind" label="kind" rules={rules.kind}>
          <Row>
            <Col span={18}>
              <Form.Item name="kind" noStyle>
                <Select
                  options={Object.values(PROMO_TYPE_KINDS).map((v) => ({
                    value: v,
                    label: v,
                  }))}
                  disabled={formMode === EDIT_MODE}
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Popover
                placement="top"
                title="Kind"
                content={popOverTypeForm.kind}
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item name="mode" label="Mode" valuePropName="checked">
          <Row>
            <Col span={8}>
              <Form.Item name="mode" noStyle valuePropName="checked">
                <Switch
                  disabled={isStripeKind}
                  checkedChildren="Two-way"
                  unCheckedChildren="One-way"
                />
              </Form.Item>
            </Col>
            <Col span={2}>
              <Popover
                placement="top"
                title="Mode"
                content={popOverTypeForm.mode}
                trigger="hover"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item
          name="applicable_plans"
          label="Applicable plans"
          wrapperCol={{ span: 22 }}
        >
          <Row align="middle" wrap={false}>
            <Col span={22}>
              <Form.Item name="applicable_plans" noStyle>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select plans"
                  options={applicablePlans}
                />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Popover
              placement="top"
              title="Mode"
              content={popOverTypeForm.mode}
              trigger="hover"
            >
              <QuestionCircleOutlined />
            </Popover>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TypesForm;
