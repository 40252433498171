import {
  Button,
  Form,
  Input,
  DatePicker,
  Typography,
  message,
  Row,
  Col,
  Descriptions,
  Spin,
} from "antd";
import axios from "axios";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { API } from "../../config";
import { RootState } from "../../redux/reducers";
import { authorizationHeaders } from "../../utils/api";
import { getAuthToken } from "../../utils/storage";

const { Title } = Typography;
const { RangePicker } = DatePicker;

const disabledDate = (current: any) => {
  return current <= moment().startOf("day");
};

type generateFeatureFlagTokenPayload = {
  feature_flag: string;
  start: Date;
  end: Date;
};

type generateFeatureFlagTokenResponse = {
  token: string;
  info: generateFeatureFlagTokenPayload;
};

type generateFeatureFlagTokenForm = {
  feature_flag: string;
  datetime: [Moment, Moment];
};

const FeatureFlag: React.FC<Props> = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<generateFeatureFlagTokenResponse>();

  const generateFeatureFlagToken = async (
    payload: generateFeatureFlagTokenPayload
  ) => {
    const token = getAuthToken();
    if (!token) return;
    setIsLoading(true);

    try {
      const response = await axios.post(
        API("generate-feature-flag-token"),
        payload,
        authorizationHeaders(token)
      );

      setData(response.data as generateFeatureFlagTokenResponse);
    } catch (e: any) {
      message.error(e?.response.data?.message || "Error occured, check logs");
    }
    setIsLoading(false);
  };

  const onFinish = (data: generateFeatureFlagTokenForm) => {
    generateFeatureFlagToken({
      feature_flag: data.feature_flag,
      start: data.datetime[0].toDate(),
      end: data.datetime[1].toDate(),
    });
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Row justify="center">
      <Col xs={20} sm={18} md={15} xl={12} xxl={8}>
        <Spin spinning={isLoading}>
          <Form
            layout="vertical"
            form={form}
            name="control-hooks"
            onFinish={onFinish}
            initialValues={{ remember: true }}
            autoComplete="off"
          >
            <Title style={{ textAlign: "center", marginTop: 50 }} level={2}>
              Generate token
            </Title>
            <Form.Item
              label="Feature flag"
              name="feature_flag"
              rules={[
                { required: true, message: "Please input feature flag name!" },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Datetime range"
              name="datetime"
              rules={[
                {
                  required: true,
                  message: "Please input your datetime range!",
                },
              ]}
            >
              <RangePicker
                style={{ width: "100%" }}
                disabledDate={disabledDate}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [
                    moment("00:00:00", "HH:mm:ss"),
                    moment("11:59:59", "HH:mm:ss"),
                  ],
                }}
                format="YYYY-MM-DD HH:mm:ss"
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="primary"
                htmlType="submit"
              >
                Generate
              </Button>
              <Button htmlType="button" onClick={onReset}>
                Reset
              </Button>
            </Form.Item>
          </Form>
        </Spin>
        {data && (
          <Descriptions
            style={{ width: "100%", marginTop: 50 }}
            title="Token"
            column={1}
            bordered
          >
            <Descriptions.Item style={{ wordBreak: "break-all" }} label="Token">
              {data.token}
            </Descriptions.Item>
            <Descriptions.Item label="Feature flag">
              {data.info.feature_flag}
            </Descriptions.Item>
            <Descriptions.Item label="Start">
              {new Date(data.info.start).toLocaleString()}
            </Descriptions.Item>
            <Descriptions.Item label="End">
              {new Date(data.info.end).toLocaleString()}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(FeatureFlag);
