import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers";
import {
  Col,
  DatePicker,
  Divider,
  Modal,
  Row,
  Slider,
  Table,
  Input,
  Space,
  Spin,
  Select,
  Checkbox, Image,
} from "antd";
import { fetchRatingJobs, fetchAllRatingJobs } from "../../redux/actions";
import {
  fetchJobsRatingsPayload,
  MappedJob, ProcessingJob,
  User,
} from "../../types";
import { PaginationProps } from "antd/lib/pagination";
import { ratingJobsPagination } from "../../redux/selectors/userSelector";
import _ from "lodash";
import { ImgComparisonSlider } from "@img-comparison-slider/react";
import {  USER_DETAILS } from "../../paths";
import { Link } from "react-router-dom";
import { DeepPartial } from "redux";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { getSortOrderFromValue, setSearchSortObject } from "../../utils/codes";
import moment from "moment";
import RatingsSummary from "./ratings_summary_plot";
import RatingsPie from "./ratings_summary_pie";
import { getImageUrlByProxy } from "../../utils/api";

const getFullDate = (date: string): string => {
  const dateAndTime = date.split("T");

  return `${dateAndTime[0]
    .split("-")
    .reverse()
    .join("-")} ${dateAndTime[1].replace("Z", "")}`;
};

const ACTIONS = [
  "transform",
  "batch_transform",
  "transform_multiple",
  "transform_ecommerce",
];

export const OPERATIONS = {
  ganzoom2: "2x",
  ganzoom3: "3x",
  ganzoom4: "4x",
  "ganzoom2-jpg90": "2x - jpg",
  "ganzoom3-jpg90": "3x - jpg",
  "ganzoom4-jpg90": "4x - jpg",
  "ganenhance1-jpg90": "enhance",
  "ganzoomnoise1-jpg90": "denoise",
  remove_background: "remove_background",
  enhance_light: "enhance_light",
  enhance_light_contrast: "enhance_light_contrast",
  enhance_light_v2: "enhance_light_v2",
  enhance_color_v2: "enhance_color_v2",
  enhance_color: "enhance_color",
  enhance_color_contrast: "enhance_color_contrast",
  face_enhance: "face_enhance",
  generate: 'generate',
  generate_background: "generate_background",
  generate_face: "generate_face",
  generate_control: "generate_control",
};

const RATINGS = {
  star_1: 1,
  star_2: 2,
  star_3: 3,
  star_4: 4,
  star_5: 5,
}

const RatingJobs: React.FC<Props> = ({
  sortOrder,
  pagination,
  jobs,
  jobsAll,
  fetchJobs,
  fetchAllJobs,
  loading,
  user,
}) => {
  const [payload, setPayload] = useState<fetchJobsRatingsPayload>({
    page: 1,
    limit: 10,
    sort: { timestamp: -1 },
    paginationEnabled: true,
    search: {
      status: "complete",
      source: "deep_image",
      from: moment().startOf('day').toDate()
    },
  });

  const [payloadSummary, setPayloadSummary] = useState<fetchJobsRatingsPayload>({
    page: 1,
    limit: 10,
    sort: { timestamp: -1 },
    paginationEnabled: false,
    search: {
      status: "complete",
      source: "deep_image",
      from: moment().startOf('day').toDate()
    },
  });

  const updatePayload = (data: DeepPartial<fetchJobsRatingsPayload>) => {
    setPayload((prev) => {
      return { ...prev, ...data, search: { ...prev.search, ...data.search } };
    });
  };

  const updatePayloadSummary = (data: DeepPartial<fetchJobsRatingsPayload>) => {
    setPayloadSummary((prev) => {
      return { ...prev, ...data, search: { ...prev.search, ...data.search } };
    });
  };

  useEffect(() => {
    fetchJobs(payload);
    fetchAllJobs(payloadSummary);
  }, [fetchJobs, payload, payloadSummary]);

  const [comparisionVisible, setComparisonVisible] = useState(false);
  const [record, setRecord] = useState({} as MappedJob);

  const handleTableChange = (
    pagination: PaginationProps,
    sorter: any,
    filters: any
  ) => {
    if (pagination.pageSize && pagination.current) {
      const sort = setSearchSortObject(filters);
      updatePayload({
        page: pagination.current,
        limit: pagination.pageSize,
        sort,
      });
    }
  };

  const onChangeRatings = (rating: CheckboxValueType[]) => {
    updatePayload({ search: {rating} });
    updatePayloadSummary({ search: {rating} });
  };

  const onSearch = (search: string) => {
    updatePayload({ search: { search } });
    updatePayloadSummary({ search: { search } });
  };

  const onChangeFromDate = (_: unknown, from: string) => {
    updatePayload({ search: { from: new Date(from) } });
    updatePayloadSummary({ search: { from: new Date(from) } });
  };

  const onChangeToDate = (_: unknown, to: string) => {
    updatePayload({ search: { to: new Date(to) } });
    updatePayloadSummary({ search: { to: new Date(to) } })
  };

  const onChangeAction = (action: string) => {
    updatePayload({ search: { action } });
    updatePayloadSummary({ search: { action } });
  };

  const onChangeRatingOption = (option: string) => {
    updatePayload({ search: { option } });
    updatePayloadSummary({ search: { option } });
  };

  const onChangeCheckBoxPublishAgreement = ({
    target: { checked: publishAgreement },
  }: CheckboxChangeEvent) => {
    updatePayload({ search: { publishAgreement } });
    updatePayloadSummary({ search: { publishAgreement } });
  };

  const onChangeOperations = (operations: CheckboxValueType[]) => {
    updatePayload({ search: { operations } });
    updatePayloadSummary({ search: { operations } });
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "origin_img_url",
      key: "origin_img_url",
      render: (url: string) => (
        <Row justify="center">
          <img style={{ maxWidth: 500, maxHeight: 200 }} src={url} />
        </Row>
      ),
    },
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "timestamp",
      sorter: (a: any, b: any) =>
        moment(a.timestamp).unix() - moment(b.timestamp).unix(),
      sortOrder: sortOrder.timestamp
        ? getSortOrderFromValue(sortOrder.timestamp)
        : null,
      render: (date: string) => getFullDate(date),
    },
    {
      title: "Hash",
      dataIndex: "hash",
      key: "hash",
      render: (hash: any, data: MappedJob) => {
        return <a href={`https://deep-image.ai/app/application/options/images/${hash}`} target="_blank" rel="noreferrer">{hash}</a>;
      },
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user: any, data: MappedJob) => {
        return <Link to={USER_DETAILS(user._id)}>{user.email}</Link>;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_: string, data: MappedJob) => {
        const jobWithAction = data.result.find((j) => j.action);
        return jobWithAction ? jobWithAction.action : "N/A";
      },
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      render: (channel: string, row: any) =>
        `${channel || "web"} - ${row.is_mobile ? "mobile" : "desktop"}`,
    },
    // {
    //   title: "Source image",
    //   dataIndex: "origin_img_url",
    //   key: "origin_img_url",
    //   render: ((img_url: string, row: any) => {
    //     return row.action === 'batch_transform' ?
    //       <a href={img_url}>Download</a> :
    //       <Image
    //         width={100}
    //         src={img_url}
    //       />
    //   })
    // },
    // {
    //   title: "Result image",
    //   dataIndex: "result_img_url",
    //   key: "result_img_url",
    //   render: ((img_url: string, row: any) => {
    //     return row.action === 'batch_transform' ?
    //       <a href={img_url}>Download</a> :
    //       <Image
    //         width={100}
    //         src={img_url}
    //       />
    //   })
    // },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      sorter: (a: any, b: any) => a.rating - b.rating,
      sortOrder: sortOrder.rating
        ? getSortOrderFromValue(sortOrder.rating)
        : null,
    },
    {
      title: "Rating option",
      dataIndex: "rating_description",
      key: "rating_description_option",
      render: (rating_description: any, row: any) => {
        return rating_description
          ? rating_description.optionDescription
          : "N/A";
      },
    },
    {
      title: "Description",
      dataIndex: "rating_description",
      key: "rating_description",
      render: (rating_description: any, row: any) => {
        return rating_description ? rating_description.text : "N/A";
      },
    },
    {
      title: "Publish",
      dataIndex: "rating_description",
      key: "rating_description_publish",
      render: (rating_description: any, row: any) => {
        return rating_description
          ? rating_description.publishAgreement
            ? "Yes"
            : "No"
          : "";
      },
    },
    {
      title: "Operations",
      dataIndex: "data",
      key: "operations",
      render: (data: any, row: any) => {
        const parseOperations = (data: any) => {
          const transStr = data.transformations // @ts-ignore
              .map((trans: string) => OPERATIONS[trans])
              .join(",")

          let generatorStr = ''
          if (data.background?.generate?.adapter_type) {
            generatorStr = `generate_${data.background.generate.adapter_type}`
          } else if (!data.background?.generate?.adapter_type && !data.image_id) {
            generatorStr = 'generate'
          } else if (data.background?.generate && !data.background?.generate?.adapter_type && data.image_id) {
            generatorStr = 'generate_background'
          }

          return `${transStr},${generatorStr}`
        }

        return data && data.transformations
          ? parseOperations(data)
          : "N/A";
      },
    },
    {
      title: "Image source",
      dataIndex: "result",
      key: "origin",
      render: ((result: Array<ProcessingJob>, row: any) => {
        const processed = _.filter(result, (job: ProcessingJob) => job.status === 'complete' && job.source === 'deep_image')
        let img_url

        if (row.action === 'transform') {
          img_url = processed.length > 0 && processed[0].result ? processed[0].result.origin_url: ''
        } else if (row.action === 'batch_transform') {
          img_url = processed.length > 0 && processed[0].result ? processed[0].result.result_url: ''

        } else if (row.action === 'ecommerce_transform') {
          img_url = processed.length > 0 && processed[0].result ? processed[0].result.result_url: ''
        }

        return img_url ?
          <Image
            width={100}
            src={img_url}
          />: <div/>
      })
    },
    {
      title: "Image result",
      dataIndex: "result",
      key: "result",
      render: ((result: Array<ProcessingJob>, row: any) => {
        const processed = _.filter(result, (job: ProcessingJob) => job.status === 'complete' && job.source === 'deep_image')
        let img_url

        img_url = processed.length > 0 && processed[0].result ? processed[0].result.result_url: ''

        if (img_url) {
          img_url = img_url.replace('minio', 'localhost')
          img_url = img_url.replace('http://ain.teonite.net/neuroapi-store/', 'https://deep-image.ai/images/')
        }

        return row.action === 'batch_transform' ?
          <a href={img_url} target="_blank">Download</a> :
          <Image
            width={100}
            src={img_url}
          />
      })
    },

  ];

  return (
    <div>
      <h1>Ratings</h1>
      <Divider />
      <Spin tip="Loading..." spinning={loading as boolean} size="large">
        <div
          style={{
            padding: "0 20px",
            display: "flex",
            flexDirection: "column",
            rowGap: 20,
          }}
        >
          <Space size={20} direction="vertical">
            <Checkbox onChange={onChangeCheckBoxPublishAgreement}>
              Can publish
            </Checkbox>
            <Space direction="vertical">
              <span>Operations:</span>
              <Checkbox.Group
                style={{ width: "100%" }}
                onChange={onChangeOperations}
              >
                <Row style={{ columnGap: 10 }}>
                  {Object.entries(OPERATIONS).map(([key, value]) => (
                    <Col key={key}>
                      <Checkbox value={key}>{value}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Space>
          </Space>
          <Space
            size={40}
            style={{
              display: "flex",
              flexWrap: "wrap",
              rowGap: 20,
              marginBottom: 20,
            }}
          >
            <Space direction="vertical" style={{ width: 250 }}>
              <span>Rating:</span>
              <Space>
                <Checkbox.Group
                  style={{ width: "100%" }}
                  onChange={onChangeRatings}
                >
                  <Row style={{ columnGap: 10 }}>
                    {Object.entries(RATINGS).map(([key, value]) => (
                      <Col key={key}>
                        <Checkbox value={key}>{value}</Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Space>
            </Space>
            <Space direction="vertical">
              <span>Action:</span>
              <Select
                style={{ width: 200 }}
                allowClear
                onChange={onChangeAction}
              >
                {ACTIONS.map((action) => (
                  <Select.Option key={action} value={action}>
                    {action}
                  </Select.Option>
                ))}
              </Select>
            </Space>
            <Space direction="vertical">
              <span>Rating option:</span>
              <Select
                style={{ width: 200 }}
                allowClear
                onChange={onChangeRatingOption}
              >
                <Select.Option value="option1">
                  I don't see the difference
                </Select.Option>
                <Select.Option value="option2">It took too long</Select.Option>
                <Select.Option value="option3">
                  Too many artifacts
                </Select.Option>
                <Select.Option value="option4">Other</Select.Option>
              </Select>
            </Space>
            <Space direction="vertical">
              <span>From:</span>
              <DatePicker
                style={{ width: 200 }}
                showTime
                defaultValue={moment(payload.search.from)}
                format="YYYY-MM-DD HH:mm"
                onChange={onChangeFromDate}
              />
            </Space>
            <Space direction="vertical">
              <span>To:</span>
              <DatePicker
                style={{ width: 200 }}
                showTime
                format="YYYY-MM-DD HH:mm"
                onChange={onChangeToDate}
              />
            </Space>
            <Space direction="vertical">
              <span>Search in description:</span>
              <Input.Search style={{ width: 200 }} onSearch={onSearch} />
            </Space>
          </Space>
          <Divider
            type="horizontal" style={{
              width: "auto",
              height: "2px",
              backgroundColor: "#000",
            }}
          />
          <div style={{
              margin: "0 0 50px 0",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "400px",
            }}>
            <div style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              height: "100%",
            }}>
              <h2 style={{
                marginBottom: "50px"
              }}>Ratings distribution</h2>
              <RatingsSummary jobs={jobsAll}></RatingsSummary>
            </div>
            <Divider
              type="vertical" style={{
                height: "auto",
                backgroundColor: "#000",
                marginLeft: "50px",
                marginRight: "50px"
              }}
            />
            <div style={{
              display: "flex",
              flexDirection: "column",
              width: "30%",
              height: "100%",
            }}>
              <h2 style={{
                marginBottom: "50px"
              }}>Operations distribution</h2>
              <RatingsPie jobs={jobsAll}></RatingsPie>
            </div>
          </div>
          <Table
            dataSource={jobs}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              pageSizeOptions: ["10", "20", "30", "50", "100"],
              showSizeChanger: true,
            }}
            bordered
            rowKey={(value) => value._id}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  if (record.origin_img_url && record.result_img_url) {
                    setRecord(record);
                    setComparisonVisible(true);
                  }
                },
              };
            }}
          />
        </div>
      </Spin>
      <Modal
        title="Images"
        visible={comparisionVisible}
        onOk={() => setComparisonVisible(false)}
        onCancel={() => setComparisonVisible(false)}
        width={1000}
      >
        <ImgComparisonSlider>
          <img slot="first" src={record.origin_img_url} width={950} />
          <img slot="second" src={record.result_img_url} width={950} />
        </ImgComparisonSlider>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = {
  fetchJobs: fetchRatingJobs,
  fetchAllJobs: fetchAllRatingJobs,
};

const mapStateToProps = (state: RootState) => ({
  jobs: state.ratingJobs.jobs,
  jobsAll: state.ratingAllJobs.jobs,
  loading: state.ratingJobs.jobsLoading,
  pagination: ratingJobsPagination(state),
  sortOrder: state.ratingJobs.sort,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { user: User };

export default connector(RatingJobs);
