import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, Form, Input, Modal, Popover, Row, Select,} from "antd";
import {ExclamationCircleOutlined, QuestionCircleOutlined,} from "@ant-design/icons";
import "./style.scss";
import {PromoCode, PromoCodeCreatePayload, PROMO_TYPE_KINDS, PromoTypeMinimal,} from "../../../../types";
import _ from "lodash";
import {REF_TYPE} from "../../static/promoTypes.js";
import {CREATE_MODE, EDIT_MODE} from "../../static/promoFormModes";
import voucher_generator from "voucher-code-generator";
import {getVoucherGeneratorConfig} from "../../../../utils/codes";
import {popOverCodesForm} from "../../static/popOverMessages";

const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 11, span: 13 },
};

type Props = {
  initialValues: PromoCode;
  onCodeEditSubmit(value: PromoCode): void;
  typesArr: Array<PromoTypeMinimal>;
  deleteCode(_id: string): void;
  createCode(values: PromoCodeCreatePayload): void;
  visible: boolean;
  closeFormModal(): void;
};

type InitialValues = {
  code: string | undefined;
  type: string | undefined;
  owner: string | undefined;
  expires: string | undefined;
};

const CodesForm: React.FunctionComponent<Props> = ({
  initialValues,
  onCodeEditSubmit,
  typesArr,
  deleteCode,
  createCode,
  visible,
  closeFormModal,
}) => {
  const [form] = Form.useForm();
  const [formMode, setFormMode] = useState(CREATE_MODE);
  const [formInitialValues, setFormInitialValues] = useState(
    {} as InitialValues
  );
  const [typeMode, setTypeMode] = useState(false);
  const { resetFields } = form;

  useEffect(() => {
    if (Object.keys(initialValues).length) {
      const getInitialFormValues = () => {
        const expDate = initialValues.expires
          ? new Date().toISOString().split("T")[0]
          : undefined;

        return {
          code: initialValues.code,
          type: initialValues.type.name,
          owner: initialValues.owner ? initialValues.owner.email : "",
          expires: expDate,
          generate_checkbox: ["letters"],
          generate_length_limit: 5,
        };
      };

      setFormMode(EDIT_MODE);
      setFormInitialValues(getInitialFormValues());
    } else {
      setFormMode(CREATE_MODE);
      resetFields();
    }
  }, [initialValues, resetFields]);

  useEffect(() => {
    form.resetFields();
  }, [form, formInitialValues]);

  const onFinish = (values: any) => {
    if (!values.owner) {
      values.owner = "";
    }
    if (!values.expires) {
      values.expires = "";
    }
    if (formMode === EDIT_MODE) {
      const merged = {
        ...initialValues,
        ...values,
        type: _.pick(
          _.find(typesArr, (o) => o.name === values.type),
          ["_id", "name", "mode"]
        ),
      };
      onCodeEditSubmit(
        _.pick(merged, ["_id", "__v", "code", "expires", "owner", "type"])
      );
      closeFormModal();
    }
    if (formMode === CREATE_MODE) {
      const codeType: PromoTypeMinimal | undefined = _.find(typesArr, [
        "name",
        values.type,
      ]);
      if (codeType) {
        const merged = { ...values, type: codeType };
        const sendVals: PromoCodeCreatePayload = _.pick(merged, [
          "code",
          "expires",
          "owner",
          "type",
        ]);
        createCode(sendVals);
        closeFormModal();
      }
    }
  };

  const modalConfirm = () => {
    Modal.confirm({
      title: `Delete code ${form.getFieldValue("code")}`,
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "Delete",
      cancelText: "Cancel",
      onOk: (close) => {
        deleteCode(initialValues._id);
        close();
        form.resetFields();
        closeFormModal();
      },
    });
  };

  const generateCode = () => {
    const optionsCheckbox = form.getFieldValue("generate_checkbox");
    const length = form.getFieldValue("generate_length_limit")
      ? form.getFieldValue("generate_length_limit")
      : 10;
    const config_seed = {
      generator_config: optionsCheckbox,
      limit_length: length,
      type: form.getFieldValue("type"),
    };
    const options = getVoucherGeneratorConfig(config_seed);
    form.setFieldsValue({ code: voucher_generator.generate(options)[0] });
  };

  const rules = {
    code: [{ required: true }, { min: 4 }],
    type: [
      { required: true },
      () => ({
        validator(rule: any, value: any) {
          if (!_.find(typesArr, { name: value }))
            return Promise.reject("Invalid type!");
          return Promise.resolve();
        },
      }),
    ],
    owner: [{ required: typeMode }],
  };


  const handleFormValueChange = (changedValues: any) => {
    if (changedValues.type) {
      const type = _.find(typesArr, { name: changedValues.type });
      if (type) setTypeMode(type.mode);
    }
  };

  return (
    <Modal
      forceRender
      visible={visible}
      title={
        formMode === CREATE_MODE ? "Create new promo code" : "Edit promo code"
      }
      okText={formMode === CREATE_MODE ? "Create" : "Edit"}
      cancelText="Cancel"
      onCancel={() => {
        form.resetFields();
        closeFormModal();
      }}
      width="45vw"
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            onFinish(values);
            form.resetFields();
            closeFormModal();
          })
      }}
    >
      {formMode === EDIT_MODE && (
        <Button
          type="primary"
          className="btn-codes-delete-position-right"
          danger
          onClick={modalConfirm}
        >
          Delete
        </Button>
      )}
      <div style={{ clear: "both" }}></div>
      <Form
        {...layout}
        form={form}
        name="code-form"
        initialValues={formMode === EDIT_MODE ? formInitialValues : {}}
        onValuesChange={handleFormValueChange}
      >
        <Form.Item name="type" label="Type" rules={rules.type}>
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select type"
            disabled={
              (initialValues.type && (initialValues.type.name === REF_TYPE || initialValues.type.kind === PROMO_TYPE_KINDS.STRIPE_SUBSCRIPTION))
            }
          >
            {typesArr.map((type) => {
              return (
                <Option key={type._id} value={type.name}>
                  {type.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="code" label="Code" rules={rules.code}>
          <Input disabled={initialValues.type && initialValues.type.kind === PROMO_TYPE_KINDS.STRIPE_SUBSCRIPTION} />
        </Form.Item>
        <Form.Item
          name="generate_checkbox"
          label="Generate options"
          wrapperCol={{ span: 16 }}
        >
          <Checkbox.Group style={{ width: "100%" }}>
            <Row>
              <Col span={24}>
                <Checkbox value="type_name">Include type</Checkbox>
                <Checkbox value="letters">Letters</Checkbox>
                <Checkbox value="numbers">Numbers</Checkbox>
                <Popover
                  placement="top"
                  title="Generator options"
                  content={popOverCodesForm.checkboxGroup}
                  trigger="hover"
                  className="question-icon-center"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </Col>
            </Row>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item name="generate_length_limit" label="length limit">
          <Row>
            <Col span={8}>
              <Form.Item name="generate_length_limit" noStyle>
                <Input type="number" />
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            type="primary"
            style={{ backgroundColor: "#389e0d", borderColor: "#237804" }}
            onClick={generateCode}
          >
            Generate code
          </Button>
        </Form.Item>
        <Form.Item name="owner" label="Owner (email)" rules={rules.owner}>
          <Row>
            <Col span={22}>
              <Input disabled={!typeMode}/>
            </Col>
            <Col span={2}>
              <Popover
                placement="top"
                title="Owner"
                content={popOverCodesForm.owner}
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item name="expires" label="Expires">
          <Row>
            <Col span={14}>
              <Form.Item name="expires" noStyle>
                <Input type="date"/>
              </Form.Item>
            </Col>
            <Col span={2}>
              <Popover
                placement="top"
                title="Expires"
                content={popOverCodesForm.expires}
                trigger="hover"
                className="question-icon-center"
              >
                <QuestionCircleOutlined />
              </Popover>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CodesForm;
