import React from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import { 
  totalCredits,
  purchasedCredits,
  subscriptionCredits,
  totalSubscriptionCredits,
  userHasPlan
} from '../../redux/selectors/userSelector';

type Props = ReturnType<typeof mapState>;

const Credits: React.FunctionComponent<Props> = ({
  userHasPlan,
  totalCredits,
  purchasedCredits,
  subscriptionCredits,
  totalSubscriptionCredits
}) => {
  return (
    <div className='user-details-credits'>
      <h3 className='subscription-details-header'>Credits</h3>
      <div className='user-details-credits-content'>
        <div className='user-details-credits-content-info'>
          <p>Balance: {totalCredits}</p>
          <p>Purchased: {purchasedCredits}</p>
          <p>Subscription:
            {userHasPlan ?
              <span> {subscriptionCredits}/{totalSubscriptionCredits}</span> :
              <span> not set</span>}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  userHasPlan: userHasPlan(state),
  totalCredits: totalCredits(state),
  purchasedCredits: purchasedCredits(state),
  subscriptionCredits: subscriptionCredits(state),
  totalSubscriptionCredits: totalSubscriptionCredits(state),
})

export default connect(mapState)(Credits);
