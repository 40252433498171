import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {Flex, Switch, Table} from 'antd';
import { usersTable, userPagination } from '../../redux/selectors/userSelector';
import { getUsers, UserSearchPayload, goToUserDetails } from '../../redux/actions/index';
import { PaginationProps } from 'antd/lib/pagination';
import SearchBar from './SearchBar';

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const columns = [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  // {
  //   title: 'ID',
  //   dataIndex: 'id',
  //   key: 'id'
  // },
  {
    title: 'Created',
    dataIndex: 'createdAt',
    key: 'createdAt'
  },
  {
    title: 'Plan',
    dataIndex: 'plan',
    key: 'plan'
  },
  {
    title: 'Is plan paid',
    dataIndex: 'isPaidPlanActive',
    key: 'isPaidPlanActive'
  },
  {
    title: 'Total credits',
    dataIndex: 'totalCredits',
    key: 'totalCredits'
  },
  {
    title: 'Purchased',
    dataIndex: 'purchasedCredits',
    key: 'purchasedCredits'
  },
  {
    title: 'Subscription',
    dataIndex: 'subscriptionCredits',
    key: 'subscriptionCredits'
  },
  {
    title: 'Stripe ID',
    dataIndex: 'stripe',
    key: 'stripe'
  },
  {
    title: 'UTM Source - Medium - Campaign',
    dataIndex: 'utm',
    key: 'utm',
    render: (text: string, data: any) => {
      console.log(data)
      return `${data?.utm?.source || ''} ${data?.utm?.medium || ''} ${data?.utm?.campaign || ''}`
    }
  }
];

const Users: React.FunctionComponent<Props> = ({
  users,
  pagination,
  getUsers,
  goToUserDetails
}) => {
  const [payable, setPayable] = useState(false);
  const [enterprise, setEnterprise] = useState(false)

  useEffect(() => {
    getUsers({
      page: pagination.current,
      limit: pagination.pageSize,
      payableUser: payable,
      enterprise
    } as UserSearchPayload)
  }, [getUsers, payable, enterprise]);

  const handleTableChange = (pagination: PaginationProps) => {
    if (pagination.pageSize && pagination.current) {
      getUsers({ 
        page: pagination.current,
        limit: pagination.pageSize,
        payableUser: payable,
        enterprise
      } as UserSearchPayload);
    }
  }

  const handleRow = (record: any) => ({
    onClick: () => goToUserDetails(record.id)
  })

  return (
    <div>
      <SearchBar />
      <Flex wrap={"wrap"}>
        <div className='user-filter'>
          <label>Subscription?</label>
          <Switch onChange={(e) => {
            setPayable(e)
          }}/>
        </div>
        <div className='user-filter'>
          <label>Enterprise?</label>
          <Switch value={enterprise} onChange={setEnterprise}/>
        </div>
      </Flex>
      <Table 
        dataSource={users}
        columns={columns}
        pagination={pagination}
        onChange={handleTableChange}
        onRow={handleRow}
      />
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  users: usersTable(state),
  pagination: userPagination(state)
})

const mapDispatch = {
  getUsers,
  goToUserDetails
}

export default connect(mapState, mapDispatch)(Users);
