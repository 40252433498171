import React from 'react';
import { Action } from '../../../types';
import { convertTimestampToMDY } from '../../../utils/dates';

type Props = {
  action: Action;
};

const ActionHistoryListItem: React.FunctionComponent<Props> = ({
  action
}) => {
  return (
    <div className='user-details-credits-content' key={action._id}>
       <div className='user-action-subtitle'>
        <p>{convertTimestampToMDY(action.createdAt)}</p>
       </div>
      <div className='user-action-message'>
        <p>{action.message}</p>
      </div>
      {action.comment ?
        <div className='user-action-comment'>
          <p>{action.comment}</p>
        </div> : null}
    </div>
  );
};

export default ActionHistoryListItem;
