import { Button, Modal, message } from 'antd';
import  { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import React from 'react';
import { useMutation } from 'react-query';
import { EventsCache } from '../..';
import { deleteEventAction } from '../../../../query/mutations';

type Props = {
  _id: string,
  name: string,
}

const { confirm } = Modal;

const ActionDelete:React.FC<Props> = ({ _id, name }) => {

  const [deleteAction] = useMutation(deleteEventAction, {
    onSuccess: () => {
      EventsCache.refetchQueries([], { stale: true });
      message.success("Action deleted!", 2);
    },
    onError: () =>{ message.error("Error check api logs for more info.", 2);},
  });

  const showConfirm = () => {
    confirm({
      title: `Do you want to delete ${name} action ?`,
      icon: <ExclamationCircleOutlined />,
      content: 'Action Will be deleted and removed from any linked group.',
      onOk: () => {
        deleteAction(_id);
      },
      onCancel: () => {},
    });
  }

  return(<Button type="primary" danger onClick={showConfirm} icon={<DeleteOutlined />}></Button>)

}

export default ActionDelete;