import React from 'react';
import { Action } from '../../../types';
import ActionHistoryListItem from './ActionListItem';

type Props = {
  actions: Array<Action>;
};

const ActionHistoryList: React.FunctionComponent<Props> = ({
  actions
}) => {
  return (
    <div className='user-action-history'>
      {actions.map(action => <ActionHistoryListItem action={action} key={action._id} />)}
    </div>
  );
};

export default ActionHistoryList;
