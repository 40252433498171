import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import userSaga from './userSaga';
import promoCodesSaga from './promoCodesSaga';
import domainSaga from './domainSaga';
import notificationsSaga from './notificationsSaga';
import jobsSaga from './jobsSaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    promoCodesSaga(),
    domainSaga(),
    notificationsSaga(),
    jobsSaga(),
  ]);
}
