export const convertSortOrder = (arg: number): "ascend" | "descend" | null => {
  switch (arg) {
    case 1:
      return "ascend";
    case -1:
      return "descend";
    default:
      return null;
  }
};

export const convertSortOrderQuery = (arg: string | null | undefined): number => {
  switch (arg) {
    case "ascend":
      return 1;
    case "descend":
      return -1;
    default:
      return 1;
  }
};
