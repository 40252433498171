import { createReducer } from "@reduxjs/toolkit";
import {
  fetchPromoCodes,
  fetchPromoCodesFailed,
  fetchPromoCodesMinimal,
  fetchPromoCodesMinimalFailed,
  fetchPromoCodesMinimalSuccess,
  fetchPromoCodesSuccess,
  fetchPromoTypes,
  fetchPromoTypesFailed,
  fetchPromoTypesMinimal,
  fetchPromoTypesMinimalSuccess,
  fetchPromoTypesSuccess,
  fetchPromoUsages,
  fetchPromoUsagesFailed,
  fetchPromoUsagesSuccess,
  generatePromoCodes,
  generatePromoCodesFailed,
  generatePromoCodesSuccess,
  setPromoCodeSearch,
} from "../actions";
import {
  Pagination,
  PromoCode,
  PromoCodeMinimal,
  PromoType,
  PromoTypeMinimal,
  PromoUsage,
  sortOrderCodes,
  sortOrderTypes,
  sortOrderUsages,
} from "../../types";

const DEFAULT_PAGINATION = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  page: 1,
  nextPage: null,
  prevPage: null,
  pagingCounter: 1,
  totalDocs: 0,
  totalPages: 0,
};

const DEFAULT_STATE = {
  codes: [] as Array<PromoCode>,
  codesLoading: false as boolean,
  codesGeneration: false as boolean,
  codesGenerationError: false as boolean,
  codesGenerationSuccess: false as boolean,
  codeCheckLoading: false as boolean,
  codeCheckAvailable: true as boolean,
  codesSearch: "" as string,
  codesPagination: DEFAULT_PAGINATION as Pagination,
  codesSort: {} as sortOrderCodes,
  types: [] as Array<PromoType>,
  typesMinimal: [] as Array<PromoTypeMinimal>,
  typesLoading: false as boolean,
  typesSearch: "" as string,
  typesPagination: DEFAULT_PAGINATION as Pagination,
  typesSort: {} as sortOrderTypes,
  usages: [] as Array<PromoUsage>,
  usagesSort: {} as sortOrderUsages,
  usagesLoading: false as boolean,
  usagesPagination: DEFAULT_PAGINATION as Pagination,
  codesMinimal: [] as Array<PromoCodeMinimal>,
  codesMinimalLoading: false as boolean,
  codesMinimalSuccess: false as boolean,
  codesMinimalFailed: false as boolean,
};

const promoCodesReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(fetchPromoCodes, (state, action) => {
      state.codesLoading = true;
    })
    .addCase(fetchPromoCodesSuccess, (state, action) => {
      const { docs, sort, ...pagination } = action.payload;
      state.codes = docs;
      state.codesSort = sort || {};
      state.codesPagination = pagination;
      state.codesLoading = false;
    })
    .addCase(fetchPromoCodesFailed, (state, action) => {
      state.codes = [];
      state.codesLoading = false;
    })
    .addCase(fetchPromoTypes, (state, action) => {
      state.typesLoading = true;
    })
    .addCase(fetchPromoTypesSuccess, (state, action) => {
      const { docs, sort, ...pagination } = action.payload;
      state.types = docs;
      state.typesSort = sort || {};
      state.typesLoading = false;
      state.typesPagination = pagination;
    })
    .addCase(fetchPromoTypesFailed, (state, action) => {
      state.typesLoading = false;
    })
    .addCase(fetchPromoUsages, (state, action) => {
      state.usagesLoading = true;
    })
    .addCase(fetchPromoUsagesSuccess, (state, action) => {
      const { docs, sort, ...pagination } = action.payload;
      state.usages = docs;
      state.usagesSort = sort || {};
      state.usagesLoading = false;
      state.usagesPagination = pagination;
    })
    .addCase(fetchPromoUsagesFailed, (state, action) => {
      state.usagesLoading = false;
    })
    .addCase(setPromoCodeSearch, (state, action) => {
      state.codesSearch = action.payload;
    })
    .addCase(fetchPromoTypesMinimal, (state, action) => {
      state.typesLoading = true;
    })
    .addCase(fetchPromoTypesMinimalSuccess, (state, action) => {
      state.typesLoading = false;
      state.typesMinimal = action.payload.types;
    })
    .addCase(generatePromoCodes, (state, action) => {
      state.codesGeneration = true;
      state.codesGenerationSuccess = false;
      state.codesGenerationError = false;
    })
    .addCase(generatePromoCodesSuccess, (state, action) => {
      state.codesGeneration = false;
      state.codesGenerationError = false;
      state.codesGenerationSuccess = true;
    })
    .addCase(generatePromoCodesFailed, (state, action) => {
      state.codesGenerationError = true;
      state.codesGeneration = false;
    })
    .addCase(fetchPromoCodesMinimalSuccess, (state, action) => {
      state.codesMinimal = action.payload.codes;
      state.codesMinimalSuccess = true;
      state.codesMinimalFailed = true;
      state.codesMinimalLoading = false;
    })
    .addCase(fetchPromoCodesMinimal, (state, action)=> {
      state.codesMinimalLoading = true;
      state.codesMinimalFailed = false;
      state.codesMinimalSuccess = false;
    })
    .addCase(fetchPromoCodesMinimalFailed, (state, action) => {
      state.codesMinimalLoading = false;
      state.codesMinimalFailed = true;
      state.codesMinimalSuccess = false;
    })
});

export default promoCodesReducer;
