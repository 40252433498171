import React, { useEffect } from "react";
import "./style.scss";
import { Col, Row, Table } from "antd";
import { PromoUsage, SearchPromoUsages } from "../../../types";
import moment from "moment";
import { RootState } from "../../../redux/reducers";
import { fetchPromoUsages } from "../../../redux/actions";
import { connect, ConnectedProps } from "react-redux";
import LoadingSpinner from "../LoadingSpinner";
import { PaginationProps } from "antd/lib/pagination";
import { usagePagination } from "../../../redux/selectors/codesSelector";
import { setSearchSortObject } from "../../../utils/codes";

const mapDispatchToProps = {
  fetchUsages: fetchPromoUsages,
};

const mapStateToProps = (state: RootState) => ({
  usages: state.promoCodes.usages,
  pagination: usagePagination(state),
  usagesLoading: state.promoCodes.usagesLoading,
  sortOrder: state.promoCodes.usagesSort,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const PromoUsages: React.FunctionComponent<Props> = ({
  sortOrder,
  pagination,
  usages,
  usagesLoading,
  fetchUsages,
}) => {
  useEffect(() => {
    fetchUsages({
      page: 1,
      limit: 10,
    } as SearchPromoUsages);
  }, [fetchUsages]);

  const getSortOrderFromValue = (
    value: number
  ): "ascend" | "descend" | undefined => {
    if (value === -1) return "descend";
    if (value === 1) return "ascend";
    return undefined;
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
      sorter: (a: PromoUsage, b: PromoUsage) => a._id.localeCompare(b._id),
    },
    {
      title: "User",
      dataIndex: ["user", "email"],
      key: "user",
      sorter: (a: PromoUsage, b: PromoUsage) => {
        if (a.user && b.user && a.user.email && b.user.email) {
          return a.user.email.localeCompare(b.user.email);
        } else {
          return 0;
        }
      },
    },
    {
      title: "code",
      dataIndex: ["code", "code"],
      key: "code",
    },
    {
      title: "Stripe Info",
      dataIndex: "stripe_info",
      key: "stripe_info",
    },
    {
      title: "Bonus credits",
      dataIndex: "bonus_credits",
      key: "bonus_credits",
      sorter: (a: PromoUsage, b: PromoUsage) =>
        a.bonus_credits - b.bonus_credits,
      sortOrder: sortOrder.bonus_credits
        ? getSortOrderFromValue(sortOrder.bonus_credits)
        : null,
    },
    {
      title: "Payment Name",
      dataIndex: "payment_name",
      key: "payment_name",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortOrder.createdAt
        ? getSortOrderFromValue(sortOrder.createdAt)
        : null,
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: any, b: any) =>
        moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortOrder: sortOrder.updatedAt
        ? getSortOrderFromValue(sortOrder.updatedAt)
        : null,
    },
  ];

  const handleTableChange = (
    pagination: PaginationProps,
    sorter: any,
    filters: any
  ) => {
    if (pagination.pageSize && pagination.current) {
      const sort = setSearchSortObject(filters);
      fetchUsages({
        page: pagination.current,
        limit: pagination.pageSize,
        sort: sort,
      } as SearchPromoUsages);
    }
  };

  return (
    <Row>
      {!usagesLoading && (
        <Col span={24}>
          <h1 className="tab-header">Usages</h1>
          <Table
            dataSource={usages}
            columns={columns}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              pageSizeOptions: ["10", "20", "30", "50", "100"],
              showSizeChanger: true,
            }}
            bordered
            rowKey={(value) => value._id}
          />
        </Col>
      )}
      {usagesLoading && <LoadingSpinner />}
    </Row>
  );
};

export default connector(PromoUsages);
