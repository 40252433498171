import React from "react";
import PromoTypes from "./Types";
import "./style.scss";
import PromoCodes from "./Codes";
import PromoUsages from "./Usage";
import { Tabs } from "antd";
import PromoCodesGenerator from "./Generator";

const { TabPane } = Tabs;

const PromoCodesPage: React.FunctionComponent = () => {
  return (
    <Tabs defaultActiveKey="2">
      <TabPane tab="Codes" key="2">
        <PromoCodes />
      </TabPane>
      <TabPane tab="Usage" key="3">
        <PromoUsages />
      </TabPane>
      <TabPane tab="Generate Codes" key="4">
        <PromoCodesGenerator />
      </TabPane>
      <TabPane tab="Types" key="1">
        <PromoTypes />
      </TabPane>
    </Tabs>
  );
};

export default PromoCodesPage;
