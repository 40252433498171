import React from 'react';
import { connect } from 'react-redux';
import { Input } from 'antd';
import { setUserSearch, searchUsers } from '../../redux/actions/index';
import { RootStateType } from '../../redux/store';
import { userSearch } from '../../redux/selectors/userSelector';

const { Search } = Input;

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const SearchBar: React.FunctionComponent<Props> = ({
  userSearch,
  setUserSearch,
  searchUsers
}) => {
  return (
    <div className='search'>
      <Search
        value={userSearch}
        placeholder='Seach users by email'
        onChange={(e) => setUserSearch(e.target.value)}
        onSearch={searchUsers}
      />
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  userSearch: userSearch(state)
})

const mapDispatch = {
  setUserSearch,
  searchUsers
}

export default connect(mapState, mapDispatch)(SearchBar);
