import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Modal, Table, Space, Button, Form, Input } from "antd";
import { fetchImageStatsQuery, queryTypes } from "../../query/queries";
import { useQuery } from "react-query";
import { toNumber } from "lodash";
import ImageStatsDiagram from "./stats_diagram";

const ImageStatsComponent: React.FC<Props> = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { data } = useQuery(
    [queryTypes.IMAGE_STATS],
    fetchImageStatsQuery
  );

  return (
    <div>
        {
            data?.items && data.items.map((item, index) => {
                return (
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "50px",
                        backgroundColor: "#c2d2ff"
                    }}>
                        <ImageStatsDiagram item={item}/>
                    </div>
                )
            })
        }
    </div>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {};

export default connector(ImageStatsComponent);