import React from "react";
import { Column } from "@ant-design/plots";

const RatingsSummary = (jobs: any) => {
  const oneStarCount = jobs.jobs.filter((job: any) => job.rating === 1).length
  const twoStarCount = jobs.jobs.filter((job: any) => job.rating === 2).length
  const threeStarCount = jobs.jobs.filter((job: any) => job.rating === 3).length
  const fourStarCount = jobs.jobs.filter((job: any) => job.rating === 4).length
  const fiveStarCount = jobs.jobs.filter((job: any) => job.rating === 5).length

  const data = [
    {
      stars: "1 star",
      count: oneStarCount,
    },
    {
      stars: "2 star",
      count: twoStarCount,
    },
    {
      stars: "3 star",
      count: threeStarCount,
    },
    {
      stars: "4 star",
      count: fourStarCount,
    },
    {
      stars: "5 star",
      count: fiveStarCount,
    },
  ];
  const config = {
    data,
    xField: "stars",
    yField: "count",
    label: {
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
    },
    meta: {
      stars: {
        alias: "star rating",
      },
      count: {
        alias: "number of stars provided",
      },
    },
    minColumnWidth: 100,
    maxColumnWidth: 100,
  };
  return <Column {...config} />;
};

export default RatingsSummary;
