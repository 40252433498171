import { createReducer } from "@reduxjs/toolkit";
import { Notifications, NotificationsSortOrder, Pagination } from "../../types";
import {
  createNotification,
  createNotificationFailed,
  createNotificationSuccess,
  deleteNotification,
  deleteNotificationFailed,
  deleteNotificationSuccess,
  editNotification,
  editNotificationFailed,
  editNotificationSuccess,
  fetchNotifications,
  fetchNotificationsFailed,
  fetchNotificationsSuccess,
  notificationsFormFinish,
  sendIcon,
  sendIconFailed,
  verifyNotification,
  verifyNotificationFailed,
  verifyNotificationSuccess,
  setMaintenanceActive,
  setMaintenanceActiveFailed,
  setMaintenanceActiveSuccess,
  setMaintenanceDisabled,
  setMaintenanceDisabledFailed,
  setMaintenanceDisabledSuccess,
  sendIconSuccess, deleteIcon, deleteIconSuccess, deleteIconFailed, setMaintenance
} from "../actions";

const DEFAULT_PAGINATION = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  page: 1,
  nextPage: null,
  prevPage: null,
  pagingCounter: 1,
  totalDocs: 0,
  totalPages: 0,
};

const DEFAULT_STATE = {
  notifications: [] as Array<Notifications>,
  notificationsLoading: false as boolean,
  notificationFormLoading: false as boolean,
  sort: {} as NotificationsSortOrder,
  pagination: DEFAULT_PAGINATION as Pagination,
  notificationVerify: false as boolean,
  notificationVerifySuccess: false,
  notificationVerifyFailed: false,
  notificationVerifyFailedMessage: "" as string,
  notificationFailed: false,
  notificationSuccess: false,
  iconLoading: false as boolean,
  iconFailed: false,
  iconDeleteLoading: false,
  maintenance: false as boolean,
  maintenanceLoading: false as boolean,
  maintenanceFailed: false,
  maintenanceSuccess: false,
};

const notificationsReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(fetchNotifications, (state, action) => {
      state.notificationsLoading = true;
    })
    .addCase(fetchNotificationsSuccess, (state, action) => {
      const { docs, sort, ...pagination } = action.payload;
      state.notifications = docs;
      state.sort = sort || {};
      state.notificationsLoading = false;
      state.pagination = pagination;
    })
    .addCase(fetchNotificationsFailed, (state, action) => {
      state.notificationsLoading = false;
    })
    .addCase(verifyNotification, (state, action) => {
      state.notificationVerify = true;
      state.notificationVerifyFailed = false;
      state.notificationVerifySuccess = false;
      state.notificationVerifyFailedMessage = "";
    })
    .addCase(verifyNotificationSuccess, (state, action) => {
      state.notificationVerify = false;
      state.notificationVerifySuccess = true;
      state.notificationFailed = false;
      state.notificationSuccess = false;
      state.notificationFormLoading = false;
    })
    .addCase(verifyNotificationFailed, (state, action) => {
      state.notificationVerifySuccess = false;
      state.notificationVerify = false;
      state.notificationVerifyFailed = true;
    })
    .addCase(createNotification, (state, payload) => {
      state.notificationFormLoading = true;
      state.notificationFailed = false;
      state.notificationSuccess = false;
    })
    .addCase(createNotificationSuccess, (state, payload) => {
      state.notificationFormLoading = false;
      state.notificationSuccess = true;
      state.notificationFailed = false;
    })
    .addCase(createNotificationFailed, (state, payload) => {
      state.notificationFailed = true;
      state.notificationSuccess = false;
      state.notificationFormLoading = false;
    })
    .addCase(editNotification, (state, action) => {
      state.notificationFormLoading = true;
      state.notificationFailed = false;
      state.notificationSuccess = false;
    })
    .addCase(editNotificationSuccess, (state, action) => {
      state.notificationFormLoading = false;
      state.notificationSuccess = true;
      state.notificationFailed = false;
    })
    .addCase(editNotificationFailed, (state, action) => {
      state.notificationFormLoading = false;
      state.notificationSuccess = false;
      state.notificationFailed = true;
    })
    .addCase(deleteNotification, (state, action) => {
      state.notificationFormLoading = true;
      state.notificationFailed = false;
      state.notificationSuccess = false;
    })
    .addCase(deleteNotificationSuccess, (state, action) => {
      state.notificationFormLoading = false;
      state.notificationFailed = false;
      state.notificationSuccess = true;
    })
    .addCase(deleteNotificationFailed, (state, action) => {
      state.notificationFormLoading = false;
      state.notificationFailed = true;
      state.notificationSuccess = false;
    })
    .addCase(notificationsFormFinish, (state, action) => {
      state.notificationFormLoading = false;
      state.notificationSuccess = false;
      state.notificationVerify = false;
      state.notificationVerifyFailed = false;
      state.notificationVerifySuccess = false;
      state.notificationFailed = false;
      state.iconFailed = false;
      state.iconLoading = false;
    })
    .addCase(sendIcon, (state, action)=>{
      state.iconLoading = true;
      state.iconFailed = false;
    })
    .addCase(sendIconSuccess, (state, action) => {
      state.iconLoading = false;
      state.iconFailed = false;
    })
    .addCase(sendIconFailed, (state, action) => {
      state.iconFailed = true;
      state.iconLoading = false;
    })
    .addCase(deleteIcon, (state, action)=>{
      state.iconDeleteLoading = true;
    })
    .addCase(deleteIconSuccess, (state, action) => {
      state.iconDeleteLoading = false;
    })
    .addCase(deleteIconFailed, (state, action) => {
      state.iconDeleteLoading = false;
    })
    .addCase(setMaintenanceActive, (state, action) => {
      state.maintenanceLoading = true;
      state.maintenanceFailed = false;
      state.maintenanceSuccess = false;
    })
    .addCase(setMaintenanceActiveSuccess, (state, action) => {
      state.maintenance = true;
      state.maintenanceFailed = false;
      state.maintenanceSuccess = true;
      state.maintenanceLoading = false;
    })
    .addCase(setMaintenanceActiveFailed, (state, action) => {
      state.maintenanceFailed = true;
      state.maintenanceLoading = false;
      state.notificationSuccess = false;
    })
    .addCase(setMaintenanceDisabled, (state, action) => {
      state.maintenance = false;
      state.maintenanceLoading = true;
      state.maintenanceFailed = false;
      state.maintenanceSuccess = false;
    })
    .addCase(setMaintenanceDisabledSuccess, (state, action) => {
      state.maintenance = false;
      state.maintenanceFailed = false;
      state.maintenanceLoading = false;
      state.maintenanceSuccess = true;
    })
    .addCase(setMaintenanceDisabledFailed, (state, action) => {
      state.maintenanceFailed = true;
      state.maintenanceLoading = false;
      state.maintenanceSuccess = false;
    })
    .addCase(setMaintenance,(state,action)=>{
      state.maintenance = action.payload;
    })
});

export default notificationsReducer;
