import React from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  stripeSubscription,
  stripeSubscriptionStart,
  stripeSubscriptionCurrentPeriodStart,
  stripeSubscriptionCurrentPeriodEnd,
  isStripeSubscriptionCancelled,
  isStripeSubscriptionActive,
  stripeSubscriptionCancelAt,
  stripeSubscriptionDashboardUrl,
  stripeSubscriptionPriceCurrencyInterval
} from '../../redux/selectors/userSelector';
import { Col } from 'antd';

type Props = ReturnType<typeof mapState>;

const SubscriptionDetails: React.FunctionComponent<Props> = ({
  stripeSubscription,
  stripeSubscriptionStart,
  stripeSubscriptionCurrentPeriodStart,
  stripeSubscriptionCurrentPeriodEnd,
  isStripeSubscriptionCancelled,
  isStripeSubscriptionActive,
  stripeSubscriptionCancelAt,
  stripeSubscriptionDashboardUrl,
  stripeSubscriptionPriceCurrencyInterval
}) => {
  if (!stripeSubscription) return <Col span={12}/>;

  return (
  <Col span={5}>
    <div className='user-details-credits'>
      <h3 className='subscription-details-header'>
        <span>Subscription </span>
        {stripeSubscriptionDashboardUrl ?
          <a
            href={stripeSubscriptionDashboardUrl}
            target='_blank'
            rel='noopener noreferrer'
          >
            View in Stripe
          </a> : ''}
      </h3>
      <div className='subscription-details-content'>
        {isStripeSubscriptionCancelled &&
          <div className='subscription-details-content-cancelled'>
            <p className='bold'>Subscription will be cancelled on</p>
            <p className='bold'>{stripeSubscriptionCancelAt}</p>
          </div>}
        {isStripeSubscriptionActive &&
          <div className='subscription-details-content-cycle'>
            <p className='subscription-details-content-cycle-header bold'>{stripeSubscriptionPriceCurrencyInterval}</p>
            {/* <p className='subscription-details-content-cycle-header bold'>{stripeSubscription.plan.amount / 100} {stripeSubscription.plan.currency.toUpperCase()} / {stripeSubscription.plan.interval}</p> */}
            <p className='subscription-details-content-cycle-header'>Current cycle is active</p>
            <p className='subscription-details-content-cycle-text'>from <span className='bold'>{stripeSubscriptionCurrentPeriodStart}</span></p>
            <p className='subscription-details-content-cycle-text'>until <span className='bold'>{stripeSubscriptionCurrentPeriodEnd}</span>.</p>
          </div>}
          <div className='subscription-details-content-info'>
            {isStripeSubscriptionActive ?
              <p>Subscription is active.</p> :
              <p>Subscription is <span className='bold'>NOT</span> active.</p>}
            <p>Subscription was created on {stripeSubscriptionStart}.</p>
          </div>
      </div>
    </div>
  </Col>
  );
};

const mapState = (state: RootStateType) => ({
  stripeSubscription: stripeSubscription(state),
  stripeSubscriptionStart: stripeSubscriptionStart(state),
  stripeSubscriptionCurrentPeriodStart: stripeSubscriptionCurrentPeriodStart(state),
  stripeSubscriptionCurrentPeriodEnd: stripeSubscriptionCurrentPeriodEnd(state),
  isStripeSubscriptionCancelled: isStripeSubscriptionCancelled(state),
  isStripeSubscriptionActive: isStripeSubscriptionActive(state),
  stripeSubscriptionCancelAt: stripeSubscriptionCancelAt(state),
  stripeSubscriptionDashboardUrl: stripeSubscriptionDashboardUrl(state),
  stripeSubscriptionPriceCurrencyInterval: stripeSubscriptionPriceCurrencyInterval(state)
})

export default connect(mapState)(SubscriptionDetails);
