import axios from "axios";
import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import { API } from "../../config";
import { getAuthToken } from "../../utils/storage";
import { authorizationHeaders } from "../../utils/api";
import {ActionCreatorWithoutPayload, PayloadAction} from "@reduxjs/toolkit";
import {
  createNotification,
  createNotificationFailed,
  createNotificationSuccess, deleteIcon, deleteIconFailed, deleteIconSuccess,
  deleteNotification,
  deleteNotificationFailed,
  deleteNotificationSuccess,
  editNotification,
  editNotificationFailed,
  editNotificationSuccess,
  fetchNotifications,
  fetchNotificationsFailed,
  fetchNotificationsSuccess,
  sendIcon,
  sendIconFailed,
  sendIconSuccess,
  setMaintenanceActive,
  setMaintenanceActiveFailed,
  setMaintenanceActiveSuccess,
  setMaintenanceDisabled,
  setMaintenanceDisabledFailed,
  setMaintenanceDisabledSuccess,
  verifyNotification,
  verifyNotificationFailed,
  verifyNotificationSuccess,
} from "../actions";
import {
  CreateNotificationPayload,
  DeleteIconPayload,
  EditNotificationPayload,
  NotificationVerifyPayload,
  SearchNotifications,
  SendIconPayload,
  setMaintenanceActivePayload
} from "../../types";
import {message, notification} from "antd";


function* fetchNotificationsSaga({
  payload,
}: PayloadAction<SearchNotifications | undefined>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    const params = payload ? { ...payload } : {};
    const request = yield call(axios.get, API("notifications/"), {
      ...authorizationHeaders(token),
      params,
    });
    yield put(fetchNotificationsSuccess(request.data));
  } catch (e) {
    yield put(fetchNotificationsFailed(e));
  }
}

function* verifyNotificationSaga({
  payload,
}: PayloadAction<NotificationVerifyPayload>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    const params = { ...payload };
    yield call(axios.get, API("notifications/verify/"), {
      ...authorizationHeaders(token),
      params,
    });
    yield put(verifyNotificationSuccess());
  } catch (e) {
    yield put(verifyNotificationFailed(e));
  }
}

function* createNotificationSaga({
  payload,
}: PayloadAction<CreateNotificationPayload>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(
      axios.post,
      API("notifications/"),
      payload,
      authorizationHeaders(token)
    );
    yield put(createNotificationSuccess());
  } catch (e) {
    notification.error({
      message: "Operation failed!",
      description: e?.response?.data?.message || "Check logs for more info.",
    });
    yield put(createNotificationFailed(e));
  }
}

function* editNotificationSaga({
  payload,
}: PayloadAction<EditNotificationPayload>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(
      axios.patch,
      API("notifications/"),
      payload,
      authorizationHeaders(token)
    );
    yield put(editNotificationSuccess());
  } catch (e) {
    notification.error({
      message: "Operation failed!",
      description: e?.response?.data?.message || "Check logs for more info.",
    });
    yield put(editNotificationFailed(e));
  }
}

function* deleteNotificationSaga({ payload }: PayloadAction<string>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(
      axios.delete,
      API(`notifications/${payload}`),
      authorizationHeaders(token)
    );
    yield put(deleteNotificationSuccess());
  } catch (e) {
    notification.error({
      message: "Operation failed!",
      description: e?.response?.data?.message || "Check logs for more info.",
    });
    yield put(deleteNotificationFailed(e));
  }
}

function* sendIconSaga({ payload }: PayloadAction<SendIconPayload>) {
  try {
    const token = getAuthToken();
    const {file, _id} = payload
    if (!token) return;
    const bodyFormData = new FormData();
    bodyFormData.append('files', file, file.name);
    yield call(
      axios.post,
      API(`notifications/${_id}/icon`),
      bodyFormData,
      { ...authorizationHeaders(token), ...{ 'Content-Type': 'multipart/form-data' } }
    );
    yield put(sendIconSuccess());
  } catch (e) {
    notification.error({
      message: "Operation failed!",
      description: e?.response?.data?.message || "Check logs for more info.",
    });
    yield put(sendIconFailed(e));
  }
}
function* deleteIconSaga({ payload: id }: PayloadAction<DeleteIconPayload>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(
      axios.delete,
      API(`notifications/${id}/icon`),
      { ...authorizationHeaders(token), ...{ 'Content-Type': 'multipart/form-data' } }
    );
    yield put(deleteIconSuccess());
  } catch (e) {
    notification.error({
      message: "Operation failed!",
      description: e?.response?.data?.message || "Check logs for more info.",
    });
    yield put(deleteIconFailed());
  }
}

function* setMaintenanceActiveSaga({ payload }: PayloadAction<setMaintenanceActivePayload>){
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(axios.post, API('notifications/maintenance/start'), payload, authorizationHeaders(token));
    yield put(setMaintenanceActiveSuccess());
  }catch(e){
    yield put(setMaintenanceActiveFailed(e));
  }
}

function* setMaintenanceDisabledSaga() {
    try {
    const token = getAuthToken();
    if (!token) return;
    yield call(axios.post, API('notifications/maintenance/end'), {}, authorizationHeaders(token));
    yield put(setMaintenanceDisabledSuccess());
    }catch(e){
      yield put(setMaintenanceDisabledFailed(e));
    }
}

export default function* notificationsSaga() {
  yield takeLatest(fetchNotifications, fetchNotificationsSaga);
  yield takeLatest(verifyNotification, verifyNotificationSaga);
  yield takeEvery(createNotification, createNotificationSaga);
  yield takeEvery(createNotificationSuccess, fetchNotificationsSaga);
  yield takeEvery(editNotification, editNotificationSaga);
  yield takeEvery(editNotificationSuccess, fetchNotificationsSaga);
  yield takeEvery(deleteNotification, deleteNotificationSaga);
  yield takeEvery(deleteNotificationSuccess, fetchNotificationsSaga);
  yield takeEvery(sendIconSuccess, fetchNotificationsSaga);
  yield takeEvery(deleteIconSuccess, fetchNotificationsSaga);
  yield takeLatest(sendIcon, sendIconSaga);
  yield takeLatest(deleteIcon, deleteIconSaga);
  yield takeLatest(setMaintenanceActive, setMaintenanceActiveSaga);
  yield takeLatest(setMaintenanceDisabled, setMaintenanceDisabledSaga);
  yield takeLatest(setMaintenanceActiveSuccess, fetchNotificationsSaga);
  yield takeLatest(setMaintenanceDisabledSuccess, fetchNotificationsSaga);
}
