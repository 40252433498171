import { Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { SorterResult, FilterDropdownProps } from "antd/lib/table/interface";
import React, { useState } from "react";
import { usePaginatedQuery } from "react-query";
import { fetchEventGroups, queryTypes } from "../../../../query/queries";
import {
  EventGroup,
  EventGroupSearch,
  fetchEventGroupsSelectType,
  groupPaginationConfig,
} from "../../../../types";
import {
  convertSortOrder,
  convertSortOrderQuery,
} from "../../../../utils/table";
import Search from "antd/lib/input/Search";

type Props = {
  openModal(value: EventGroup): void;
};

const GroupTable: React.FC<Props> = ({ openModal }) => {
  const [search, setSearch] = useState({} as EventGroupSearch);
  const [paginationOptions, setPaginationOptions] = useState({
    select: {
      _id: 1,
      group_name: 1,
      actions: 1,
      group_description: 1,
      group_title: 1,
    },
    pagination: true,
    page: 1,
    limit: 10,
    sort: { createdAt: -1 },
  } as groupPaginationConfig);

  const { resolvedData, isLoading, canFetchMore } = usePaginatedQuery(
    [queryTypes.EVENT_GROUP_LIST, search, paginationOptions],
    fetchEventGroups
  );

  const handleTableChange = (
    pagination: any,
    filters: any,
    sorter: SorterResult<EventGroup> | SorterResult<EventGroup>[],
    { action }: any
  ) => {
    switch (action) {
      case "paginate":
        setPaginationOptions((old) => ({
          ...old,
          page: pagination.current,
          limit: pagination.pageSize,
        }));
        break;
      case "sort":
        let sortObject: fetchEventGroupsSelectType = {};
        console.log(sorter);
        if (!Array.isArray(sorter)) {
          switch (sorter.field) {
            case "group_description":
              sortObject.group_description = convertSortOrderQuery(
                sorter.order
              );
              break;
            case "group_title":
              sortObject.group_title = convertSortOrderQuery(sorter.order);
              break;
            default:
              sortObject.createdAt = -1;
              break;
          }
          if (!sorter.order) sortObject = { createdAt: -1 };
        }
        setPaginationOptions((old) => ({ ...old, sort: sortObject }));
        break;
    }
  };

  const tableColumns = [
    {
      title: "Title",
      dataIndex: "group_title",
      sorter: true,
      sortOrder: paginationOptions.sort.group_title
        ? convertSortOrder(paginationOptions.sort.group_title)
        : null,
    },
    {
      title: "Description",
      dataIndex: "group_description",
      sorter: true,
      sortOrder: paginationOptions.sort.group_description
        ? convertSortOrder(paginationOptions.sort.group_description)
        : null,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (data:any) => data && data.length
    },
  ];

  const handleOnRow = (record: EventGroup, rowIndex: number | undefined) => {
    return {
      onClick: () => openModal(record),
    }
  }

  return (
    <>
      <Search
        onSearch={(value) => setSearch({ group_title: value })}
        allowClear
        placeholder="Search groups by title"
      />
      <Table
        dataSource={resolvedData ? resolvedData.docs : []}
        columns={tableColumns}
        bordered
        loading={isLoading}
        rowKey={(item: EventGroup) => item._id}
        onRow={handleOnRow}
        pagination={
          resolvedData
            ? {
                pageSize: resolvedData.limit,
                total: resolvedData.totalDocs,
                showSizeChanger: true,
                pageSizeOptions: ["5", "10", "20", "50", "100"],
              }
            : {}
        }
        onChange={handleTableChange}
      />
    </>
  );
};

export default GroupTable;
