import React from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  userHasPlan,
  userPlan,
  isUserPlanPaid,
  userPlanStart
} from '../../redux/selectors/userSelector';

type Props = ReturnType<typeof mapState>;

const Plan: React.FunctionComponent<Props> = ({
  userHasPlan,
  userPlan,
  isUserPlanPaid,
  userPlanStart,
}) => {
  if (!userHasPlan) return null;

  return (
    <div className='user-details-credits'>
      <h3 className='subscription-details-header'>
        Plan
        <span>
          {userPlan ?
            <span>
              {userPlan.name} <span className='font-green'>{isUserPlanPaid ? 'PAID' : 'FREE'}</span>
            </span>
          : null}
        </span>
      </h3>
      <div className='user-details-credits-content'>
        <div className='user-details-credits-content-info'>
          <h4>Identifiers:</h4>
          <div className='card-identifiers'>
            <p>Database: {userPlan._id}</p>
            {isUserPlanPaid &&
              <p>Stripe: {userPlan.stripe}</p>}
          </div>
          <p>Started on: {userPlanStart}</p>        
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  userHasPlan: userHasPlan(state),
  userPlan: userPlan(state),
  isUserPlanPaid: isUserPlanPaid(state),
  userPlanStart: userPlanStart(state)
})

export default connect(mapState)(Plan);
