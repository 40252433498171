import React, { useEffect, useState } from "react";
import { Row, Col, Button, Form, Select, Input, Checkbox, Popover } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../redux/reducers";
import {
  fetchPromoTypesMinimal,
  generatePromoCodes,
} from "../../../redux/actions";
import _ from "lodash";
import { QuestionCircleOutlined, CheckCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import "./style.scss";
import { getVoucherGeneratorConfig } from "../../../utils/codes";
import { popoverGeneratorForm } from "../static/popOverMessages";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 8 },
};
const tailLayout = {
  wrapperCol: { offset: 10, span: 8 },
};
const { Option } = Select;

const PromoCodesGenerator: React.FC<Props> = ({
  generateCodes,
  typesMinimal,
  fetchTypesMinimal,
  generateCodesLoading,
  generateCodesError,
  generateCodesSuccess
}) => {
  const [form] = Form.useForm();
  const [ownerDisabled, setOwnerDisabled] = useState(true);
  useEffect(() => {
    fetchTypesMinimal();
  }, [fetchTypesMinimal]);

  const isTypeTwoWay = (typeName: string): boolean => {
    const type = _.find(typesMinimal, { name: typeName });
    return type ? type.mode : false;
  };

  const rules = {
    type: [{ required: true }],
    owner: [
      ({ getFieldValue }: any) => ({
        validator(rule: any, value: any) {
          if (isTypeTwoWay(getFieldValue("type")) && !value) {
            return Promise.reject("That type requires owner field.");
          }
          return Promise.resolve();
        },
      }),
      { required: isTypeTwoWay(form.getFieldValue("type")) },
    ],
    expires: [{ required: false }],
    amount: [
      { required: true },
      () => ({
        validator(rule: any, value: any) {
          if (value < 1) {
            return Promise.reject("Value needs to be greater then 0.");
          }
          return Promise.resolve();
        },
      }),
    ],
    limit_length: [
      () => ({
        validator(rule: any, value: any) {
          if (value < 4) return Promise.reject("Value must be greater then 3");
          return Promise.resolve();
        },
      }),
    ],
  };

  const getOptions = () => {
    return typesMinimal.map((type) => (
      <Option key={type._id} value={type.name}>
        {type.name}
      </Option>
    ));
  };

  const onFinish = (values: any) => {
    const owner = values.owner ? { owner: values.owner } : { owner: "" };
    const expires = values.expires
      ? { expires: values.expires }
      : { expires: "" };
    const foundType = _.find(typesMinimal, { name: values.type });
    const type = { type: foundType ? foundType._id : "" };
    const config = getVoucherGeneratorConfig(values);
    const amount = { amount: values.amount as number };
    generateCodes(_.merge(owner, amount, expires, type, { config }));
    form.resetFields();
  };

  const handleTypeChange = () => {
    const formTypeValue = form.getFieldValue("type");
    if (formTypeValue) {
      const existingType = _.find(typesMinimal, { name: formTypeValue });
      if (existingType && existingType.mode)
        setOwnerDisabled(!existingType.mode);
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Form
          {...layout}
          form={form}
          name="generate-codes-form"
          onFinish={onFinish}
          initialValues={{
            generator_config: ["letters"],
            limit_length: 5,
            amount: 2,
          }}
        >
          <Form.Item
            name="type"
            label="Code type"
            rules={rules.type}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 4 }}
          >
            <Select
              showSearch
              placeholder="Select code type"
              onChange={handleTypeChange}
            >
              {getOptions()}
            </Select>
          </Form.Item>
          <Form.Item name="amount" label="Amount" rules={rules.amount}>
            <Row>
              <Col span={4}>
                <Form.Item name="amount" noStyle>
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Popover
                  placement="top"
                  title="Amount"
                  content={popoverGeneratorForm.amount}
                  trigger="hover"
                  className="question-icon-center"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item
            name="generator_config"
            label="Generator options"
            wrapperCol={{ span: 8 }}
          >
            <Checkbox.Group style={{ width: "100%" }}>
              <Row>
                <Col span={24}>
                  <Checkbox value="type_name">Type name</Checkbox>
                  <Checkbox value="letters">Letters</Checkbox>
                  <Checkbox value="numbers">Numbers</Checkbox>
                  <Popover
                    placement="top"
                    title="Generator options"
                    content={popoverGeneratorForm.checkboxGroup}
                    trigger="hover"
                  >
                    <QuestionCircleOutlined />
                  </Popover>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name="limit_length"
            label="Code length"
            rules={rules.limit_length}
          >
            <Row>
              <Col span={4}>
                <Form.Item name="limit_length" noStyle>
                  <Input type="number" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Popover
                  placement="top"
                  title="Code limit"
                  content={popoverGeneratorForm.limit_length}
                  trigger="hover"
                  className="question-icon-center"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name="owner" label="Owner(email)" rules={rules.owner}>
            <Row>
              <Col span={13}>
                <Form.Item name="owner" noStyle>
                  <Input type="email" disabled={ownerDisabled} />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Popover
                  placement="top"
                  title="Owner"
                  content={popoverGeneratorForm.owner}
                  trigger="hover"
                  className="question-icon-center"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name="expires" label="Expires" rules={rules.expires}>
            <Row>
              <Col span={13}>
                <Form.Item name="expires" noStyle>
                  <Input type="date" />
                </Form.Item>
              </Col>
              <Col span={2}>
                <Popover
                  placement="top"
                  title="Expires"
                  content={popoverGeneratorForm.expires}
                  trigger="hover"
                  className="question-icon-center"
                >
                  <QuestionCircleOutlined />
                </Popover>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item {...tailLayout}>
            <Button type="primary" htmlType="submit" loading={generateCodesLoading} icon={generateCodesError ? <ExclamationCircleOutlined/> : generateCodesSuccess ? <CheckCircleOutlined/> : null}>
              Generate
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({
  typesMinimal: state.promoCodes.typesMinimal,
  typesLoading: state.promoCodes.typesLoading,
  generateCodesLoading: state.promoCodes.codesGeneration,
  generateCodesError: state.promoCodes.codesGenerationError,
  generateCodesSuccess: state.promoCodes.codesGenerationSuccess,
});

const mapDispatchToProps = {
  fetchTypesMinimal: fetchPromoTypesMinimal,
  generateCodes: generatePromoCodes,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(PromoCodesGenerator);
