import React from "react";
import JobsSuspense from "./Suspense/Suspense";
import { Row, Col } from "antd";
import "./jobsPage.scss";
import Channels from "./Channels/Channels";
const JobsPage: React.FC = () => {
  return (
    <Row justify="center" className="jobs-page">
      <Col span={24} className="col-fill">
        <Channels />
      </Col>
      <JobsSuspense />
    </Row>
  );
};

export default JobsPage;
