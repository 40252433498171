import { createReducer } from '@reduxjs/toolkit';
import {
  loginFailed,
  loginSuccess,
  logout,
  loginGoogleOAuthSuccess
} from '../actions';
import { User } from '../../types';

const DEFAULT_STATE = {
  token: null as string | null,
  user: {} as User
};

const authReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(loginSuccess, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    })
    .addCase(loginFailed, (state, action) => {
      state.token = null;
    })
    .addCase(logout, (state, action) => {
      return DEFAULT_STATE;
    })
    .addCase(loginGoogleOAuthSuccess, (state, action) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    })
);

export default authReducer;
