import { createSelector } from '@reduxjs/toolkit';
import { RootStateType } from '../store';
import { User } from '../../types';
import { APP_NAME } from '../../config';
import { convertTimestampToDateString, convertUnixTimestampToDateString } from '../../utils/dates';
import {codesBranch} from "./codesSelector";

export const userBranch = (state: RootStateType) => state.user;
export const userChannels = createSelector(userBranch, (branch) => branch.channels)
export const users = createSelector(userBranch, (branch) => branch.users);
export const userDetails = createSelector(userBranch, (branch) => branch.user);
export const isUserSet = createSelector(userDetails, (user) => !!Object.keys(user).length);
export const userHasPlan = createSelector(
  [isUserSet, userDetails], (isUserSet, user) =>
    isUserSet && user.subscription && user.subscription.plan_usage[APP_NAME] &&
    Object.keys(user.subscription.plan_usage[APP_NAME]).length
);
// TODO: should be named subscription or sth similar
export const userApplication = createSelector(
  [isUserSet, userDetails], (isSet, user) =>
    isSet && user.subscription.plan_usage[APP_NAME]
);
export const userPlan = createSelector(
  [userHasPlan, userDetails], (userHasPlan, user) =>
    userHasPlan && user.subscription.plan_usage[APP_NAME].plan
);
export const userPlanCredits = createSelector(
  [userPlan], (plan) => plan.config[APP_NAME].available_requests
);
export const isUserPlanPaid = createSelector(
  [userPlan], (plan) => !!plan.stripe
);
export const userPlanStart = createSelector(
  [userApplication], (app) => convertTimestampToDateString(app.started)
);
export const subscriptionCredits = createSelector(
  [isUserSet, userDetails, userHasPlan],
  (isUserSet, user, hasPlan) => isUserSet && hasPlan &&
    user.subscription.plan_usage[APP_NAME].available_requests
);
export const totalSubscriptionCredits = createSelector(
  [userHasPlan, userDetails], (userHasPlan, user) =>
    userHasPlan && user.subscription.plan_usage[APP_NAME].total
);
export const purchasedCredits = createSelector(
  [isUserSet, userDetails], (isSet, user) => isSet && user.purchased_credits
);
export const totalCredits = createSelector(
  [subscriptionCredits, purchasedCredits], (subscriptionCredits, purchasedCredits) =>
    subscriptionCredits + purchasedCredits
);
export const isUserSuspended = createSelector(
  [isUserSet, userDetails], (isSet, user) => isSet && user.disabled
);
export const isAccountInactive = createSelector(
  [isUserSet, userDetails], (isSet, user) => isSet && user.email_confirmed
);
export const hasAdminPanelAccess = createSelector(
  [isUserSet, userDetails], (isSet, user) => isSet && user.permissions.adminPanelAccess
);
export const isEnterpriseAccount = createSelector(
  [isUserSet, userDetails], (isSet, user) => isSet && !!user?.enterprise
);
export const usersTable = createSelector(userBranch, (branch) =>
  branch.users.map(
    (user: User, i: number) => {
      // TODO: create a user class
      const hasPlan = user.subscription.plan_usage[APP_NAME] &&
        Object.keys(user.subscription.plan_usage[APP_NAME]).length
      // const applications = plans.join(' ')

      const subscriptionCredits = hasPlan ? user.subscription.plan_usage[APP_NAME].available_requests : 0
      const totalSubscriptionCredits = hasPlan ? user.subscription.plan_usage[APP_NAME].total : 0
      const purchasedCredits = user.purchased_credits

      const isPlanPaid = hasPlan && user.subscription.plan_usage[APP_NAME].plan.stripe

      return {
        key: i.toString(),
        email: user.email,
        suspended: user.disabled ? 'YES':  '-',
        id: user._id,
        isPaidPlanActive: isPlanPaid ? 'YES' : '-',
        totalCredits: purchasedCredits + subscriptionCredits,
        subscriptionCredits: `${subscriptionCredits}/${totalSubscriptionCredits}`,
        purchasedCredits,
        plan: hasPlan ? user.subscription.plan_usage[APP_NAME].plan.name : '-',
        stripe: user.stripe.customer || '-',
        createdAt: user.createdAt,
        utm: user.utm
      }
    }
  )
);
export const userPagination = createSelector(userBranch, (branch) => ({
  total: branch.pagination.totalDocs,
  current: branch.pagination.page,
  pageSize: branch.pagination.limit
}));
export const userSearch = createSelector(userBranch, (branch) => branch.search);
export const userAction = createSelector(userBranch, (branch) => branch.action);
export const isUserActionSet = createSelector(userBranch, (branch) => !!Object.keys(branch.action).length)
export const userActionComment = createSelector(userBranch, (branch) => branch.action.comment || '');
export const updatedCredits = createSelector(userBranch, (branch) => branch.updatedCredits);

export const isStripeCustomer = createSelector(
  [isUserSet, userDetails], (isSet, user) => isSet && !!user.stripe.customer);
export const stripeSubscription = createSelector(
  [isStripeCustomer, userDetails], (isStripeCustomer, user) =>
    isStripeCustomer && user.stripe.data && user.stripe.data.subscriptions &&
      user.stripe.data.subscriptions.data.find((subscription: any) =>
        subscription.metadata.application === APP_NAME)
);
export const stripeSubscriptionPriceCurrencyInterval = createSelector(
  stripeSubscription, (subscription) => subscription && subscription.plan && `${subscription.plan.amount / 100} ${subscription.plan.currency.toUpperCase()} / ${subscription.plan.interval}`
);
export const isStripeSubscriptionActive = createSelector(
  [stripeSubscription], (subscription) => subscription && subscription.status === 'active'
);
export const isStripeSubscriptionCancelled = createSelector(
  stripeSubscription, (subscription) => subscription && subscription.cancel_at_period_end
);
export const stripeSubscriptionCurrentPeriodStart = createSelector(
  [stripeSubscription], (subscription) =>
  subscription && subscription.current_period_start && convertUnixTimestampToDateString(subscription.current_period_start)
);
export const stripeSubscriptionCurrentPeriodEnd = createSelector(
  [stripeSubscription], (subscription) =>
  subscription && subscription.current_period_end && convertUnixTimestampToDateString(subscription.current_period_end)
);
export const stripeSubscriptionStart = createSelector(
  stripeSubscription, (subscription) =>
    subscription && convertUnixTimestampToDateString(subscription.start_date)
);
export const stripeSubscriptionCancelAt = createSelector(
  [stripeSubscription, isStripeSubscriptionCancelled], (subscription, isCancelled) =>
    isCancelled && subscription && convertUnixTimestampToDateString(subscription.cancel_at)
);
export const isStripeCustomerInLivemode = createSelector(
  [isUserSet, isStripeCustomer, userDetails], (isUserSet, isStripeCustomer, user) =>
  isUserSet && isStripeCustomer && user.stripe.data.livemode
);
export const stripeCustomerDashboardUrl = createSelector(
  [isUserSet, userDetails, isStripeCustomer, isStripeCustomerInLivemode],
  (isUserSet, user, isStripeCustomer, isStripeCustomerInLivemode) =>
    isUserSet && isStripeCustomer ?
      isStripeCustomerInLivemode ?
      `https://dashboard.stripe.com/customers/${user.stripe.customer}` :
      `https://dashboard.stripe.com/test/customers/${user.stripe.customer}` : null
);
export const stripeSubscriptionDashboardUrl = createSelector(
  [isUserSet, stripeSubscription], (isSet, subscription) =>
    isSet && subscription ?
      subscription.livemode ?
      `https://dashboard.stripe.com/subscriptions/${subscription.id}` :
      `https://dashboard.stripe.com/test/subscriptions/${subscription.id}` : null
);
export const userActions = createSelector(
  userBranch, (branch) => branch.actions
);
export const userActionsPagination = createSelector(
  userBranch, (branch) => ({
    total: branch.actionPagination.totalDocs,
    current: branch.actionPagination.page,
    pageSize: branch.actionPagination.limit
  })
);

export const userFetchEnterpriseData = createSelector(userBranch, (branch) => branch.enterprise);

export const jobsBranch = (state: RootStateType) => state.userJobs;

export const jobsPagination = createSelector(jobsBranch, (branch) => {
  return ({
    total : branch.pagination.total,
    current : branch.pagination.page,
    pageSize : branch.pagination.limit,
  })
});

export const jobsFetchEnterpriseData = createSelector(jobsBranch, (branch) => branch.enterprise);

export const ratingJobsBranch = (state: RootStateType) => state.ratingJobs;

export const ratingJobsPagination = createSelector(ratingJobsBranch, (branch) => {
  return ({
    total : branch.pagination.total,
    current : branch.pagination.page,
    pageSize : branch.pagination.limit,
  })
});
