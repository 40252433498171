import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  notification,
  Switch,
  Select,
} from "antd";
import React, { useState, useEffect, useCallback } from "react";
import { connect, ConnectedProps } from "react-redux";
import {
  createNotification,
  deleteNotification,
  editNotification,
  notificationsFormFinish,
  sendIcon,
  verifyNotification,
} from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
import {
  CreateNotificationPayload,
  EditNotificationPayload,
  Notifications,
  PromoCodeMinimal,
} from "../../../types";
import {
  EDIT_MODE,
  CREATE_MODE,
} from "../../PromoCodesPage/static/promoFormModes.js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import _ from "lodash";
import "./style.scss";
import { fetchPromoCodesMinimal } from "../../../redux/actions/index";
import UploadIcon from "./UploadIcon";
import QuestionnaireForm from "../QuestionnaireForm";

const { Option } = Select;

const NotificationForm: React.FC<Props> = ({
  formInitialValues,
  visible,
  closeModal,
  verifyNotification,
  verifying,
  verifyingFailed,
  verifyingSuccess,
  loading,
  success,
  failed,
  createNotification,
  editNotification,
  deleteNotification,
  formFinish,
  sendIcon,
  iconLoading,
  fetchCodes,
  codes,
  codesLoading,
  codesSuccess,
  codesFailed,
}) => {
  const [formMode, setFormMode] = useState(CREATE_MODE);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [codesList, setCodesList] = useState([] as Array<PromoCodeMinimal>);
  const [form] = Form.useForm();
  const { resetFields } = form;

  const isQuestionnaire = formInitialValues && formInitialValues.name === "QUESTIONNAIRE_NOTIFICATION"
  const isMaintenance = formInitialValues && formInitialValues.name === "MAINTENANCE_NOTIFICATION"

  const defaultValues = {
  name: "",
  title: "",
  description: "",
  icon: "",
  code: "",
  auth: false,
  admin: false,
  active_start: "",
  active_end: "",
  };

  const sendCompleteFromAction = useCallback(
  (values: any) => {
    values = { ...defaultValues, ..._.pickBy(values, _.identity) };
    if (!values.icon && formInitialValues.icon) values.icon = formInitialValues.icon;
    if (formInitialValues.code && !values.code) values.code = "";
    if (editorState) {
    const draftState = convertToRaw(editorState.getCurrentContent());
    values.description = draftToHtml(draftState);
    }
    values = _.omit(values, ["icon"]);
    if (typeof values.auth === typeof null || !values.auth)
    values.auth = false;
    if (formMode === CREATE_MODE) {
    createNotification({
      ...values,
      type: values.type || 'header'
    } as CreateNotificationPayload);
    } else {
    editNotification({
      ...values,
      _id: formInitialValues._id,
    } as EditNotificationPayload);
    }
  },
  [
    createNotification,
    editNotification,
    editorState,
    formInitialValues._id,
    formMode,
    defaultValues,
    formInitialValues.code,
    formInitialValues.icon
  ]
  );

  useEffect(() => {
  setCodesList(codes);
  }, [codesSuccess, codes]);

  useEffect(() => {
  if (Object.keys(formInitialValues).length) {
    setFormMode(EDIT_MODE);
    const contentBlock = htmlToDraft(formInitialValues.description);
    if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);
    setEditorState(editorState);
    }
  } else {
    setFormMode(CREATE_MODE);
    setEditorState(EditorState.createEmpty());
  }
  }, [formInitialValues, visible]);

  useEffect(() => {
  resetFields();
  }, [form, formInitialValues, resetFields]);

  useEffect(() => {
  fetchCodes();
  }, [fetchCodes]);

  useEffect(() => {
  if (
    verifyingSuccess &&
    !verifyingFailed &&
    loading === false &&
    failed === false &&
    success === false
  ) {
    form.validateFields().then((values) => {
    sendCompleteFromAction(values);
    });
  }
  }, [
  verifyingSuccess,
  form,
  failed,
  loading,
  success,
  verifyingFailed,
  sendCompleteFromAction,
  ]);

  useEffect(() => {
  if (success === true) {
    notification.success({
    message: "Success!",
    description:
      formMode === CREATE_MODE
      ? "Notification created successfully! "
      : "Notifications edited successfully! ",
    });
    form.resetFields();
    formFinish();
    closeModal();
  }
  }, [success, form, closeModal, formFinish, formMode]);

  useEffect(() => {
  form.setFields([
    {
    name: "active_start",
    errors: ["There is another notification active in that period"],
    },
  ]);
  }, [verifyingFailed, form]);

  const rules = {
  name: [{ required: true }, { min: 4 }, { max: 30 }],
  title: [{ required: true }, { min: 4 }],
  description: [{ required: true }],
  code: [
    () => ({
    validator(rule: any, value: any) {
      if (!_.find(codes, ["code", value]))
      return Promise.reject("Code must exist.");
      return Promise.resolve();
    },
    }),
  ],
  active_start: [
    { required: false },
    () => ({
    validator() {
      const start = new Date(form.getFieldValue("active_start"));
      const end = new Date(form.getFieldValue("active_end"));
      if (start && end) {
      if (start > end) {
        return Promise.reject("Start cannot be later then end.");
      }
      }
      return Promise.resolve();
    },
    }),
  ],
  active_end: [{ required: false }],
  };

  const handleFileInputChange = (event: any) => {
  const fileUpload = event.target.files[0];
  if (fileUpload) {
    sendIcon({file: fileUpload, _id: formInitialValues._id});
  }
  };

  return (
  <Modal
    visible={visible}
    title={
    formMode === CREATE_MODE ? "Create notification" : "Edit notification"
    }
    okText={formMode === CREATE_MODE ? "Create" : "Edit"}
    onOk={() => {
    if (!verifying && !loading) {
      form.validateFields().then((values) => {
      if (
        values.active_start &&
        values.active_end &&
        formMode !== EDIT_MODE
      ) {
        verifyNotification({
        start: values.active_start,
        end: values.active_end,
        });
      } else {
        if (verifyingFailed === false || failed === true) {
        sendCompleteFromAction(values);
        }
      }
      });
    }
    }}
    onCancel={() => {
    closeModal();
    resetFields();
    }}
    width="45vw"
    confirmLoading={
    verifying
      ? true
      : loading
      ? true
      : iconLoading
      ? true
      : codesLoading
      ? true
      : false
    }
    forceRender={true}
  >
    {formMode === EDIT_MODE && (
    <Row justify="end">
      <Col span={2}>
      <Button
        disabled={isQuestionnaire || isMaintenance}
        type="primary"
        danger
        onClick={() => deleteNotification(formInitialValues._id)}
        className="notification-delete-form-button"
      >
        Delete
      </Button>
      </Col>
    </Row>
    )}
    <Form
    name="NotificationForm"
    initialValues={_.omit(formInitialValues, ['icon'])}
    form={form}
    >
    <Row>
      <Col span={24}>
      <Row>
        <Col span={10}>
        <Form.Item name="name" label="Name" rules={rules.name}>
          <Input disabled={isQuestionnaire || isMaintenance} type="text" placeholder="Enter name" />
        </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={10}>
        <Form.Item name="title" label="Title" rules={rules.title}>
          <Input type="text" placeholder="Enter title" />
        </Form.Item>
        </Col>
      </Row>
      </Col>
    </Row>
    <Row gutter={[16, 8]}>
      <Col span={10}>
      <Form.Item
        name="active_start"
        label="Start"
        rules={rules.active_start}
      >
        <Input type="date" allowClear={true} />
      </Form.Item>
      </Col>
      <Col span={10}>
      <Form.Item name="active_end" label="End" rules={rules.active_end}>
        <Input type="date" allowClear={true} />
      </Form.Item>
      </Col>
    </Row>
    <Row gutter={[18, 8]}>
      <Col span={8}>
      <Form.Item name="code" label="Code">
        <Select
        disabled={isQuestionnaire || isMaintenance}
        showSearch
        style={{ width: 120 }}
        placeholder="Select code"
        allowClear={true}
        >
        {codesList.map((code) => {
          return (
          <Option key={code._id} value={code.code}>
            {code.code}
          </Option>
          );
        })}
        </Select>
      </Form.Item>
      </Col>
      <Col span={6}>
      <Form.Item name="auth" label="Auth" valuePropName="checked">
        <Switch checkedChildren="Logged in" unCheckedChildren="ALL" />
      </Form.Item>
      </Col>
      <Col span={6}>
      <Form.Item name="type" label="Type">
        <Select
        style={{ width: 100 }}
        placeholder="Select type"
        defaultValue="header"
        >
          <Option value="header">
            Header
          </Option>
          <Option value="popup">
            Popup
          </Option>
          <Option value="popup-corner">
            Popup corner
          </Option>
        </Select>
      </Form.Item>
      </Col>
    </Row>
    <Row>
      <Form.Item name="admin" label="Admin preview" valuePropName="checked">
        <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
      </Form.Item>
    </Row>
    <Form.Item name="description" label="Message" rules={rules.description}>
      <Editor
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={setEditorState}
      />
    </Form.Item>
    </Form>
    <UploadIcon formInitialValues={formInitialValues} formMode={formMode}/>
    {<QuestionnaireForm isQuestionnaire={isQuestionnaire} />}
  </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  verifying: state.notifications.notificationVerify,
  verifyingSuccess: state.notifications.notificationVerifySuccess,
  verifyingFailed: state.notifications.notificationVerifyFailed,
  iconLoading: state.notifications.iconLoading,
  loading: state.notifications.notificationFormLoading,
  success: state.notifications.notificationSuccess,
  failed: state.notifications.notificationFailed,
  codes: state.promoCodes.codesMinimal,
  codesLoading: state.promoCodes.codesMinimalLoading,
  codesFailed: state.promoCodes.codesMinimalFailed,
  codesSuccess: state.promoCodes.codesMinimalSuccess,
});

const mapDispatchToProps = {
  verifyNotification: verifyNotification,
  editNotification: editNotification,
  createNotification: createNotification,
  deleteNotification: deleteNotification,
  formFinish: notificationsFormFinish,
  sendIcon: sendIcon,
  fetchCodes: fetchPromoCodesMinimal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {
  visible: boolean;
  formInitialValues: Notifications;
  closeModal(): void;
};

export default connector(NotificationForm);
