import axios from "axios";
import { takeEvery, call, put, select, takeLatest } from "redux-saga/effects";
import {
  editPromoType,
  editPromoTypeFailed,
  editPromoTypeSuccess,
  fetchPromoCodes,
  fetchPromoCodesFailed,
  fetchPromoCodesSuccess,
  fetchPromoTypes,
  fetchPromoTypesSuccess,
  fetchPromoUsages,
  fetchPromoUsagesFailed,
  fetchPromoUsagesSuccess,
  editPromoCode,
  editPromoCodeFailed,
  editPromoCodeSuccess,
  deletePromoCode,
  deletePromoCodeSuccess,
  deletePromoCodeFailed,
  createPromoCode,
  createPromoCodeSuccess,
  createPromoCodeFailed,
  createPromoType,
  createPromoTypeSuccess,
  createPromoTypeFailed,
  deletePromoTypeSuccess,
  deletePromoType,
  deletePromoTypeFailed,
  generatePromoCodes,
  generatePromoCodesFailed,
  fetchPromoTypesMinimalSuccess,
  fetchPromoTypesMinimalFailed,
  generatePromoCodesSuccess,
  fetchPromoTypesMinimal,
  fetchPromoCodesMinimalSuccess,
  fetchPromoCodesMinimalFailed,
  fetchPromoCodesMinimal, fetchJobs,
} from "../actions/index";
import { API } from "../../config";
import { getAuthToken } from "../../utils/storage";
import { authorizationHeaders } from "../../utils/api";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  fetchJobsPayload,
  GeneratePromoCodesPayload,
  PromoCode,
  PromoCodeCreatePayload,
  PromoType,
  PromoTypeCreatePayload,
  SearchPromoUsages,
  SearchType,
} from "../../types";
import {
  codeSearch,
  typesSearch,
} from "../selectors/codesSelector";

function* fetchPromoCodesSaga({
  payload,
}: PayloadAction<SearchType | undefined>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    const search = yield select(codeSearch);
    const searchPayload = search ? { search } : {};
    const payloadParam = payload ? payload : {};
    const params = { ...payloadParam, ...searchPayload };
    const request = yield call(axios.get, API("codes/"), {
      ...authorizationHeaders(token),
      params,
    });
    yield put(fetchPromoCodesSuccess(request.data));
  } catch (e) {
    yield put(fetchPromoCodesFailed(e));
  }
}

function* fetchPromoTypesSaga({
  payload,
}: PayloadAction<SearchType | undefined>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    const search = yield select(typesSearch);
    const searchPayload = search ? { search } : {};
    const paramsPayload = payload ? payload : {};
    const params = { ...paramsPayload, ...searchPayload };
    const request = yield call(axios.get, API("types/"), {
      ...authorizationHeaders(token),
      params,
    });
    yield put(fetchPromoTypesSuccess(request.data));
  } catch (e) {
    yield put(fetchPromoCodesFailed);
  }
}

function* fetchPromoUsagesSaga({
  payload,
}: PayloadAction<SearchPromoUsages | undefined>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    const paramsPayload = payload ? payload : {};
    const request = yield call(axios.get, API("usages/"), {
      ...authorizationHeaders(token),
      params : paramsPayload,
    });
    yield put(fetchPromoUsagesSuccess(request.data));
  } catch (e) {
    yield put(fetchPromoUsagesFailed(e));
  }
}

function* editPromoTypeSaga({ payload }: PayloadAction<PromoType>) {
  try {
    const token = getAuthToken();
    if (!token) return;

    yield call(
      axios.patch,
      API("types/"),
      payload,
      authorizationHeaders(token)
    );
    yield put(editPromoTypeSuccess());
    yield put(fetchPromoTypes());
    yield put(fetchPromoCodes());
  } catch (e) {
    yield put(editPromoTypeFailed(e));
  }
}

function* editPromoCodeSaga({ payload }: PayloadAction<PromoCode>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(
      axios.patch,
      API("codes/"),
      payload,
      authorizationHeaders(token)
    );
    yield put(editPromoCodeSuccess());
  } catch (e) {
    yield put(editPromoCodeFailed(e));
  }
}

function* deletePromoCodeSaga({ payload }: PayloadAction<string>) {
  try {
    const token = getAuthToken();
    if (!token) return;

    yield call(
      axios.delete,
      API(`codes/${payload}`),
      authorizationHeaders(token)
    );
    yield put(deletePromoCodeSuccess());
  } catch (e) {
    yield put(deletePromoCodeFailed(e));
  }
}

function* getCodesMinimalSaga(){
  try{
      const token = getAuthToken();
      if (!token) return;
      const res = yield call(axios.get, API("codes/list"), authorizationHeaders(token));
      yield put(fetchPromoCodesMinimalSuccess(res.data));
  }catch(e){
    yield put(fetchPromoCodesMinimalFailed(e));
  }
}

function* createPromoCodeSaga({ payload }: PayloadAction<PromoCodeCreatePayload>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(axios.post, API("codes/"), payload, authorizationHeaders(token));
    yield put(createPromoCodeSuccess());
  } catch (e) {
    yield put(createPromoCodeFailed(e));
  }
}

function* createPromoTypeSaga({ payload }: PayloadAction<PromoTypeCreatePayload>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(axios.post, API("types/"), payload, authorizationHeaders(token));
    yield put(createPromoTypeSuccess());
    yield put(fetchPromoTypesMinimal());
  } catch (e) {
    yield put(createPromoTypeFailed(e));
  }
}

function* deletePromoTypeSaga({ payload }: PayloadAction<string>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    yield call(
      axios.delete,
      API(`types/${payload}`),
      authorizationHeaders(token)
    );
    yield put(deletePromoTypeSuccess());
    yield put(fetchPromoTypesMinimal());
  } catch (e) {
    yield put(deletePromoTypeFailed(e));
  }
}

function* fetchPromoTypesMinimalSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;
    const response = yield call(
      axios.get,
      API(`types/minimal`),
      authorizationHeaders(token)
    );
    yield put(fetchPromoTypesMinimalSuccess(response.data));
  } catch (e) {
    yield put(fetchPromoTypesMinimalFailed(e));
  }
}

function* generatePromoCodesSaga({
  payload,
}: PayloadAction<GeneratePromoCodesPayload>) {
  try {
    const token = getAuthToken();
    if (!token) return;
    console.log(payload);
    yield call(axios.post, API("codes/generate"), payload, authorizationHeaders(token));
    yield put(generatePromoCodesSuccess());
    yield put(fetchPromoTypes());
    yield put(fetchPromoCodes());
  } catch (e) {
    yield put(generatePromoCodesFailed(e));
  }
}

export default function* promoCodesSaga() {
  yield takeLatest(fetchPromoCodes, fetchPromoCodesSaga);
  yield takeLatest(fetchPromoTypes, fetchPromoTypesSaga);
  yield takeLatest(fetchPromoUsages, fetchPromoUsagesSaga);
  yield takeEvery(editPromoType, editPromoTypeSaga);
  yield takeEvery(editPromoCode, editPromoCodeSaga);
  yield takeEvery(editPromoCodeSuccess, fetchPromoCodesSaga);
  yield takeEvery(deletePromoCode, deletePromoCodeSaga);
  yield takeEvery(deletePromoCodeSuccess, fetchPromoCodesSaga);
  yield takeEvery(createPromoCode, createPromoCodeSaga);
  yield takeEvery(createPromoCodeSuccess, fetchPromoCodesSaga);
  yield takeEvery(createPromoType, createPromoTypeSaga);
  yield takeEvery(createPromoTypeSuccess, fetchPromoTypesSaga);
  yield takeEvery(deletePromoType, deletePromoTypeSaga);
  yield takeEvery(deletePromoTypeSuccess, fetchPromoTypesSaga);
  yield takeEvery(generatePromoCodes, generatePromoCodesSaga);
  yield takeLatest(fetchPromoTypesMinimal, fetchPromoTypesMinimalSaga);
  yield takeLatest(fetchPromoCodesMinimal, getCodesMinimalSaga);

}
