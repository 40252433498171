import React, {useCallback, useEffect, useState} from "react";
import { Button, Col, Row, Table } from "antd";
import "./style.scss";
import moment from "moment";
import { RootState } from "../../redux/reducers";
import { PaginationProps } from "antd/lib/pagination";
import { connect, ConnectedProps } from "react-redux";
import { fetchNotifications } from "../../redux/actions";
import { notificationsPagination } from "../../redux/selectors/notificationsSelector";
import LoadingSpinner from "../PromoCodesPage/LoadingSpinner";
import { Notifications, SearchType } from "../../types";
import { getSortOrderFromValue, setSearchSortObject } from "../../utils/codes";
import NotificationForm from "./NotificationForm";

const NotificationsPage: React.FC<Props> = ({
  notifications,
  fetchNotifications,
  notificationsLoading,
  pagination,
  sortOrder,
}) => {
  const [formVisible, setFormVisible] = useState(false);
  const [formInitialValues, setFormInitialValues] = useState(
    {} as Notifications
  );

  const openForm = () => setFormVisible(true);
  const closeForm = () => setFormVisible(false);
  const parseNotification = useCallback((notification: Notifications) => {
    if (notification.active_end && notification.active_start) {
      return {
        ...notification,
        active_end: new Date(notification.active_end).toISOString().split("T")[0],
        active_start: new Date(notification.active_start)
            .toISOString()
            .split("T")[0],
      };
    }
    return notification
  },[])

  useEffect(() => {
    if( formInitialValues._id ) {
      const notification = notifications.find(({_id})=> _id === formInitialValues._id)
      if (notification && notification.icon !== formInitialValues.icon) {
        setFormInitialValues(p => ({...p, icon: notification.icon}))
      }
    }
  }, [notifications]);


  useEffect(() => {
    fetchNotifications({
      page: 1,
      limit: 10,
    } as SearchType);
  }, [fetchNotifications]);

  if (notificationsLoading)
    return (
      <Row>
        <LoadingSpinner />
      </Row>
    );

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: Notifications, b: Notifications) =>
        a.name.localeCompare(b.name),
      sortOrder: sortOrder.name ? getSortOrderFromValue(sortOrder.name) : null,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a: Notifications, b: Notifications) =>
        a.title.localeCompare(b.title),
      sortOrder: sortOrder.title
        ? getSortOrderFromValue(sortOrder.title)
        : null,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text: string, record: any) => {
        return (<div dangerouslySetInnerHTML={{__html: record.description}}></div>);
      }
    },
    {
      title: "Promo code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Icon",
      dataIndex: "icon",
      key: "icon",
      render: (text: string, record: any) => {
        return (<>{record.icon ? <img src={record.icon} alt="Alt" style={{maxWidth: "125px", maxHeight: "125px"}}/> : <span>None</span>}</>)
      }
    },
    {
      title: "Active",
      key: "Active",
      render: (text: string, record: any) => {
        if (record.active_end && record.active_start) {
          const start = new Date(record.active_start);
          const end = new Date(record.active_end);
          const now = Date.now();
          if (start.getTime() < now && end.getTime() > now) {
            return <span style={{color: "red", fontWeight: "bold"}}>Active</span>;
          }
        }
        return <span>Inactive</span>;
      },
    },
    {
      title: "Auth",
      key: "auth",
      width: 100,
      render: (text: string, record: any) => {
        return (<span>{record.auth ? "Logged In" : "ALL"}</span>);
      }
    },
    {
      title: "Admin preview",
      key: "admin",
      width: 100,
      render: (text: string, record: any) => {
        return (<span>{record.admin ? "Active" : "Inactive"}</span>);
      }
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.expires).unix() - moment(b.expires).unix(),
      sortOrder: sortOrder.createdAt
        ? getSortOrderFromValue(sortOrder.createdAt)
        : null,
    },
    {
      title: "Creator",
      dataIndex: "creator",
      key: "creator",
    },
  ];

  const addNotification = () => {
    setFormInitialValues({} as Notifications);
    openForm();
  };

  const onRowClick = (record: Notifications) => {
    return {
      onClick: () => {
        setFormInitialValues(parseNotification(record))
        openForm();
      },
    };
  };

  const handleTableChange = (
    pagination: PaginationProps,
    sorter: any,
    filters: any
  ) => {
    if (pagination.pageSize && pagination.current) {
      const sort = setSearchSortObject(filters);
      fetchNotifications({
        page: pagination.current,
        limit: pagination.pageSize,
        sort: sort,
      } as SearchType);
    }
  };


  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={22}>
            <h1 className="tab-header">Notifications</h1>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              className="btn-position-right"
              onClick={addNotification}
            >
              Add new
            </Button>
          </Col>
        </Row>
        <NotificationForm
          visible={formVisible}
          closeModal={closeForm}
          formInitialValues={formInitialValues}
        />
        <Table
          dataSource={notifications}
          columns={columns}
          pagination={{
            ...pagination,
            pageSizeOptions: ["10", "20", "30", "50", "100"],
            showSizeChanger: true,
          }}
          bordered
          onRow={onRowClick}
          rowKey={(value) => value._id}
          onChange={handleTableChange}
        />
      </Col>
    </Row>
  );
};

const mapStateToProps = (state: RootState) => ({
  notifications: state.notifications.notifications,
  notificationsLoading: state.notifications.notificationsLoading,
  pagination: notificationsPagination(state),
  sortOrder: state.notifications.sort,
});

const mapDispatchToProps = {
  fetchNotifications: fetchNotifications,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

export default connector(NotificationsPage);
