import React from "react";
import { Column } from "@ant-design/plots";
import { sortBy } from "lodash";

const ImageStatsDiagram = ({ item }: any) => {

    const statsImgTypeKeys = Object.keys(item.img_types_stats)
    const dataPerType = statsImgTypeKeys.map((value, index) => {
        const sizeKeys = Object.keys(item.img_types_stats[value])
        const data = sizeKeys.map((v, i) => {
            const label = (v: any) => {
                const splitName = String(v).split("_")
                console.log(splitName[1] + "Mpx - " + splitName[2] + "Mpx")
                return splitName[1] + "Mpx - " + splitName[2] + "Mpx" 
            }
            return {
                size: label(v),
                count: item.img_types_stats[value][v]
            }
        })
        return {imgType: value, data: data}
    })

  
  const configs = dataPerType.map((conf, i) => {
    return {
        imgType: conf.imgType,
        sortDirections: ['ascend'],
        data: conf.data,
        xField: "size",
        yField: "count",
        label: {
          style: {
            fill: "#FFFFFF",
            opacity: 0.6,
          },
          autoRotate: true,
        },
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: true,
          },
        },
        meta: {
          stars: {
            alias: "Size range of an image in megapixels",
          },
          count: {
            alias: "Images count",
          },
        },
        minColumnWidth: 10,
        maxColumnWidth: 100,
      };
    })

    return (
        <div>
            <h1 style={{textAlign: "center", backgroundColor: "white"}}>{"Source: " + String(item.source_type).toUpperCase()}</h1>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                alignContent: "stretch",
                flexWrap: "wrap"
            }}>
                {
                    configs.map((config, index) => (
                        <div style={{
                            margin: "20px",
                            width: "500px"
                        }}>
                            <h2>{config.imgType}</h2>
                            <Column {...config} />
                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export default ImageStatsDiagram;