import { Button, Input } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { RootStateType } from '../../redux/store';
import {
  blacklistDomain,
  clearDomainState
} from '../../redux/actions';
import {
  blacklistError,
  blacklistingInProgress,
  blacklistSuccess
} from '../../redux/selectors/domainSelector';

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const Domains: React.FunctionComponent<Props> = ({
  blacklistDomain,
  blacklistingInProgress,
  blacklistSuccess,
  blacklistError,
  clearDomainState
}) => {
  const [value, setValue] = useState('');

  const submit = () => {
    clearDomainState();
    blacklistDomain(value);
    setValue('');
  }

  return (
    <div className='domains'>
      <div className='domains-container'>
        <div className='domains-message'>
          <p>
            <span className='font-success'>{blacklistSuccess && 'Domain has been blacklisted.'}</span>
            <span className='font-error'>{blacklistError ? blacklistError : ''}</span>
          </p>
        </div>
        <h3 className='domains-header'>Blacklist a domain</h3>
        <div className='domains-content'>
          <Input
            placeholder='Blacklist a domain'
            value={value}
            onChange={e => setValue(e.target.value)}
          />
          <div className='domains-content-button'>
            <Button onClick={submit}>
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootStateType) => ({
  blacklistingInProgress: blacklistingInProgress(state),
  blacklistSuccess: blacklistSuccess(state),
  blacklistError: blacklistError(state)
})

const mapDispatch = {
  blacklistDomain,
  clearDomainState
}

export default connect(mapState, mapDispatch)(Domains);
