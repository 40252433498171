import axios from 'axios';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeEvery, put, select, call, takeLatest } from 'redux-saga/effects';
import {
  getUsers,
  getUsersSuccess,
  getUserActions,
  UserSearchPayload,
  UserActionSearchPayload,
  setUserSearch,
  searchUsers,
  goToUserDetails,
  getUserDetails,
  setUserDetailsSuccess,
  suspendAccount,
  manageCredits,
  activateSuspendedAccount,
  clearUserAction,
  sendPasswordResetEmail,
  sendAccountActivationEmail,
  grantAdminPanelAccess,
  revokeAdminPanelAccess,
  cancelStripeSubscription,
  restoreStripeSubscription,
  getUserActionsSuccess,
  getUserChannelsSuccess,
  getUserChannelsFailed,
  getUserChannelsStats,
  fetchJobs,
  fetchPromoUsagesSuccess,
  fetchPromoUsagesFailed,
  fetchJobsSuccess,
  fetchJobsFailed,
  fetchRatingJobs,
  fetchRatingJobsSuccess,
  fetchRatingJobsFailed,
  fetchAllRatingJobs,
  fetchAllRatingJobsSuccess,
  fetchAllRatingJobsFailed,
  activateNewAccount, toggleEnterprise, toggleEnterpriseData,
} from '../actions/index';
import { authorizationHeaders } from '../../utils/api';
import { API } from '../../config';
import { getAuthToken } from '../../utils/storage';
import {
  userSearch,
  userDetails,
  userAction as userActionSelector,
  updatedCredits as updatedCreditsSelector, userFetchEnterpriseData, jobsFetchEnterpriseData
} from '../selectors/userSelector';
import { push } from 'connected-react-router';
import { USER_DETAILS } from '../../paths';
import {User, UserAction, UpdatedCredits, fetchJobsPayload} from '../../types';
import {notification} from "antd";


function* getUsersSaga({ payload }: PayloadAction<UserSearchPayload>): any {
  try {
    // TODO: should be set in a reducer
    const token = getAuthToken();
    if (!token) return;

    const search = yield select(userSearch);
    const searchPayload = search ? { search } : {};

    const params2 = payload ? payload : {} as UserSearchPayload;
    if (!params2.payableUser) {
      delete params2.payableUser
    }
    if (!params2.enterprise) {
      delete params2.enterprise
    }

    const params = { ...params2, ...searchPayload };

    const response = yield axios.get(API('users'), {
      ...authorizationHeaders(token),
      params
    });
    // TODO: separate action
    yield put(getUsersSuccess(response.data));
  } catch (e) {

  }
}

function* getUserActionsSaga({ payload }: PayloadAction<UserActionSearchPayload>): any {
  try {
    const token = getAuthToken();
    if (!token) return;

    const params = payload ? payload : {};
    const user: User = yield select(userDetails);

    const response = yield axios.get(API(`users/${user._id}/actions`), {
      ...authorizationHeaders(token),
      params
    });
    yield put(getUserActionsSuccess(response.data));
  } catch (e) {

  }
}

function* searchUsersSaga({ payload }: PayloadAction<string>) {
  yield put(setUserSearch(payload));
  yield put(getUsers({ search: payload } as UserSearchPayload));
}

function* goToUserDetailsSaga({ payload }: PayloadAction<string>) {
  yield put(push(USER_DETAILS(payload)));
}

function* getUserDetailsSaga({ payload }: PayloadAction<string>): any {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user = yield select(userDetails)
    let fetchEnterpriseData = yield select(userFetchEnterpriseData)
    if (user && user._id !== payload && fetchEnterpriseData) {
      yield put(toggleEnterpriseData())
      fetchEnterpriseData = yield select(userFetchEnterpriseData)
    }
    const response = yield axios.get(API(`${fetchEnterpriseData ? "enterprise/" : ""}users/${payload}`), authorizationHeaders(token));

    yield put(setUserDetailsSuccess(response.data));
    yield put(getUserChannelsStats(response.data._id));
  } catch (e) {
    if(yield select(userFetchEnterpriseData))
      yield put(toggleEnterpriseData())
    console.error(e)
    notification.error({
      message: "Operation failed!",
      description: e?.response?.data?.message || "Check logs for more info.",
    });
  }
}

function* suspendAccountSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(API(`users/${user._id}`), data, authorizationHeaders(token));
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* toggleEnterpriseSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(API(`enterprise/users/${user._id}/toggle`), data, authorizationHeaders(token));
    if (yield select(userFetchEnterpriseData))
      yield put(toggleEnterpriseData());
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {
    notification.error({
      message: "Operation failed!",
      description: e?.response?.data?.message || "Check logs for more info.",
    });
  }
}

function* manageCreditsSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};
    const updatedCredits: UpdatedCredits = yield select(updatedCreditsSelector);

    yield axios.post(
      API(`users/${user._id}/manage-credits`),
      { ...data, ...updatedCredits },
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* activateSuspendedAccountSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(
      API(`users/${user._id}/activate-closed-account`),
      data,
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* activateNewAccountSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(
      API(`users/${user._id}/activate-new-account`),
      data,
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* sendPasswordResetEmailSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(
      API(`users/${user._id}/send-password-reset-email`),
      data,
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* sendAccountActivationEmailSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(
      API(`users/${user._id}/send-activation-email`),
      data,
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* grantAdminPanelAccessSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(
      API(`users/${user._id}/permissions/admin-panel/grant`),
      data,
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* revokeAdminPanelAccessSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(
      API(`users/${user._id}/permissions/admin-panel/revoke`),
      data,
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* cancelStripeSubscriptionSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(
      API(`users/${user._id}/subscription/cancel`),
      data,
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* restoreStripeSubscriptionSaga() {
  try {
    const token = getAuthToken();
    if (!token) return;

    const user: User = yield select(userDetails);
    const userAction: UserAction = yield select(userActionSelector);
    const data = userAction.comment ? { comment: userAction.comment } : {};

    yield axios.post(
      API(`users/${user._id}/subscription/restore`),
      data,
      authorizationHeaders(token)
    );
    yield put(getUserDetails(user._id));
    yield put(getUserActions());
    yield put(clearUserAction());
  } catch (e) {

  }
}

function* getUserChannelsStatsSaga({payload}: PayloadAction<string>) {
  try{
      const token = getAuthToken();
  if (!token) return;
  const params = { id: payload };
  const { data } = yield call(axios.get,API("jobs/channel/user/"), {
    ...authorizationHeaders(token),
    params,
  });
  yield put(getUserChannelsSuccess(data));
  } catch (e: any) {
    yield put(getUserChannelsFailed(e));
  }
}

function* fetchJobsSaga({
  payload,
}: PayloadAction<fetchJobsPayload | undefined>): any {
  try {
    const token = getAuthToken();
    if (!token) return;
    const paramsPayload = payload ? payload : {};
    const fetchEnterpriseData = (yield select(jobsFetchEnterpriseData)) && (paramsPayload as any)?.search?.["user.email"]
    const request = yield call(axios.get, API(`${fetchEnterpriseData ? "enterprise/" : ""}jobs/`), {
      ...authorizationHeaders(token),
      params : paramsPayload,
    });
    yield put(fetchJobsSuccess(request.data));
  } catch (e: any) {
    yield put(fetchJobsFailed(e));
  }
}

function* fetchRatingJobsSaga({
  payload,
}: PayloadAction<fetchJobsPayload | undefined>): any {
  try {
    const token = getAuthToken();
    if (!token) return;
    const paramsPayload = payload ? payload : {};
    const request = yield call(axios.get, API("jobs/ratings"), {
      ...authorizationHeaders(token),
      params : paramsPayload,
    });
    yield put(fetchRatingJobsSuccess(request.data));
  } catch (e: any) {
    yield put(fetchRatingJobsFailed(e));
  }
}

function* fetchAllRatingJobsSaga({
  payload,
}: PayloadAction<fetchJobsPayload | undefined>): any {
  try {
    const token = getAuthToken();
    if (!token) return;
    const paramsPayload = payload ? payload : {};
    const request: any = yield call(axios.get, API("jobs/ratings"), {
      ...authorizationHeaders(token),
      params : paramsPayload,
    });
    yield put(fetchAllRatingJobsSuccess(request.data));
  } catch (e: any) {
    yield put(fetchAllRatingJobsFailed(e));
  }
}

export default function* authSaga() {
  yield takeEvery(getUsers, getUsersSaga);
  yield takeEvery(searchUsers, searchUsersSaga);
  yield takeEvery(goToUserDetails, goToUserDetailsSaga);
  yield takeEvery(getUserDetails, getUserDetailsSaga);
  yield takeEvery(suspendAccount, suspendAccountSaga);
  yield takeEvery(manageCredits, manageCreditsSaga);
  yield takeEvery(toggleEnterprise, toggleEnterpriseSaga);
  yield takeEvery(activateSuspendedAccount, activateSuspendedAccountSaga);
  yield takeEvery(sendPasswordResetEmail, sendPasswordResetEmailSaga);
  yield takeEvery(sendAccountActivationEmail, sendAccountActivationEmailSaga);
  yield takeEvery(grantAdminPanelAccess, grantAdminPanelAccessSaga);
  yield takeEvery(revokeAdminPanelAccess, revokeAdminPanelAccessSaga);
  yield takeEvery(cancelStripeSubscription, cancelStripeSubscriptionSaga);
  yield takeEvery(restoreStripeSubscription, restoreStripeSubscriptionSaga);
  yield takeEvery(getUserActions, getUserActionsSaga);
  yield takeLatest(getUserChannelsStats, getUserChannelsStatsSaga);
  yield takeLatest(fetchJobs, fetchJobsSaga);
  yield takeLatest(fetchRatingJobs, fetchRatingJobsSaga);
  yield takeLatest(fetchAllRatingJobs, fetchAllRatingJobsSaga);
  yield takeEvery(activateNewAccount, activateNewAccountSaga);

}
