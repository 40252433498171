import {createReducer} from "@reduxjs/toolkit";
import {fetchJobs, fetchJobsFailed, fetchJobsSuccess, toggleEnterpriseData} from "../actions";
import {Job, JobsOrderUsages, Pagination} from "../../types";
import {getImageUrlByProxy} from "../../utils/api";

const DEFAULT_PAGINATION = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  page: 1,
  nextPage: null,
  prevPage: null,
  pagingCounter: 1,
  totalDocs: 0,
  totalPages: 0,
};

const DEFAULT_STATE = {
  jobs: [] as Array<Job>,
  jobsSuccess: false as Boolean,
  jobsFailed: false as Boolean,
  jobsLoading: false as Boolean,
  enterprise: false as boolean,
  sort: {} as JobsOrderUsages,
  pagination: DEFAULT_PAGINATION as Pagination,
};

const mapImages = (images: Job[]) => images.map((job) => ({
  ...job,
  img_url: job.action === 'batch_transform' ? job.data.url : job['img_url'] ? getImageUrlByProxy(job['img_url']): job['img_url']
}));

const userJobsReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(fetchJobs, (state, action) => {
      state.jobsFailed = false;
      state.jobsSuccess = false;
      state.jobsLoading = true;
    })
    .addCase(fetchJobsSuccess, (state, action) => {
      const { docs, sort, ...pagination } = action.payload;
      state.jobsSuccess = true;
      state.jobsFailed = false;
      state.jobsLoading = false;
      state.jobs = mapImages(docs);
      state.sort = sort || {};
      state.pagination = pagination;
    })
    .addCase(fetchJobsFailed, (state, action) => {
      state.jobsFailed = true;
      state.jobsLoading = false;
      state.jobsSuccess = false;
    })
    .addCase(toggleEnterpriseData, (state) => {
      state.enterprise = !state.enterprise;
    });
});

export default userJobsReducer;
