/* eslint no-console: 0 */
import axios from 'axios';
import { takeEvery, call, takeLatest, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { history } from '../store';
import { login, loginFailed, LoginPayload, loginSuccess, LoginSuccessPayload, logout, GoogleOAuthPayload, loginGoogleOAuthSuccess, } from '../actions';
import { removeAuthToken, setAuthToken, getAuthToken } from '../../utils/storage';
import { API } from '../../config';
import { loginGoogleOAuth, getAdminProfile } from '../actions';
import { push } from 'connected-react-router';
import { DASHBOARD_PAGE } from '../../paths';
import { postHeaders, authorizationHeaders } from '../../utils/api';


function* loginSuccessSaga({ payload }: PayloadAction<LoginSuccessPayload>) {
  yield call(setAuthToken,payload.token);
  axios.defaults.headers.common = {
    Authorization: `Bearer ${payload.token}`,
  };
  yield call(history.push, '/');
}

function* logoutSaga() {
  axios.defaults.headers.common = {};
  yield call(removeAuthToken);
  yield put(push('/'));
}

function* loginSaga({ payload } : PayloadAction<LoginPayload>) {
  try {
    const response = yield call(axios.post, API('login'), payload, postHeaders);
    yield put(loginSuccess(response.data));
    setAuthToken(response.data.token);
    yield put(push(DASHBOARD_PAGE))
  } catch(e) {
    console.log(e);
    yield put(loginFailed());
  }
}

function* loginGoogleOAuthSaga({ payload }: PayloadAction<GoogleOAuthPayload>) {
  try {
    const response = yield axios.post(API('auth'), payload, postHeaders);
    setAuthToken(response.data.token);
    yield put(loginGoogleOAuthSuccess(response.data));
    yield put(push(DASHBOARD_PAGE))
  } catch (e) {

  }
}

function* getAdminSaga() {
  try {
    // TODO: should be set in a reducer
    const token = getAuthToken();
    if (!token) return;

    const response = yield axios.get(API('profile'), authorizationHeaders(token));
    // TODO: separate action
    yield put(loginGoogleOAuthSuccess({ token, user: response.data }));
  } catch (e) {

  }
}

export default function* authSaga() {
  yield takeLatest(login, loginSaga);
  yield takeEvery(loginSuccess, loginSuccessSaga);
  yield takeEvery(logout, logoutSaga);
  yield takeEvery(loginGoogleOAuth, loginGoogleOAuthSaga);
  yield takeEvery(getAdminProfile, getAdminSaga);
}
