export const INDEX_PAGE = '/';
export const LOGIN_PAGE = `${INDEX_PAGE}login`;
export const DASHBOARD_PAGE = '/dashboard';
export const USERS_PAGE = `/users`;
export const USER_DETAILS = id => `${USERS_PAGE}/${id}`;
export const MAIN_PAGE = '/main';
export const DOMAINS_PAGE = '/domains'
export const PROMO_CODES_PAGE = '/codes';
export const NOTIFICATION_PAGE = '/notifications';
export const JOBS_PAGE = '/jobs';
export const EVENTS_PAGE = '/events';
export const RATING_JOBS_PAGE = '/ratings';
export const SHOPIFY_PRODUCTS_PAGE = '/shopifyproducts';
export const IMAGE_STATS = '/imagestats';
export const PLATFORMS_PAGE = '/platforms';
export const PROMPTS_PAGE = '/prompts';
export const PAYMENTS_PAGE = '/payments';
