import { combineReducers } from '@reduxjs/toolkit';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { rootReducer } from '../store';
import auth from './authReducer';
import promoCodes from './promoCodesReducer';
import user from './userReducer';
import domain from './domainReducer';
import notifications from './notificationsReducer';
import jobs from "./jobsReducer";
import userJobs from "./userJobsReducer";
import ratingJobs from "./ratingJobsReducer";
import ratingAllJobs from "./ratingAllJobsReducer";

const createRootReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    jobs,
    promoCodes,
    auth,
    user,
    notifications,
    domain,
    userJobs,
    ratingJobs,
    ratingAllJobs,
  });

export type RootState = ReturnType<typeof rootReducer>

export default createRootReducer;
