import {createReducer} from "@reduxjs/toolkit";
import {fetchRatingJobs, fetchRatingJobsFailed, fetchRatingJobsSuccess} from "../actions";
import { Job, JobsOrderUsages, MappedJob, Pagination, ProcessingJob } from "../../types";
import {getImageUrlByProxy} from "../../utils/api";
import _ from 'lodash';

const DEFAULT_PAGINATION = {
  hasNextPage: false,
  hasPrevPage: false,
  limit: 10,
  page: 1,
  nextPage: null,
  prevPage: null,
  pagingCounter: 1,
  totalDocs: 0,
  totalPages: 0,
};

const DEFAULT_STATE = {
  jobs: [] as Array<MappedJob>,
  jobsSuccess: false as Boolean,
  jobsFailed: false as Boolean,
  jobsLoading: false as Boolean,
  sort: {} as JobsOrderUsages & { rating ?: number},
  pagination: DEFAULT_PAGINATION as Pagination,
};

const findSourceImage = (job: Job) => {
  const jobWithImg: any = job.result.find((job: ProcessingJob) => job.img_url);
  return jobWithImg
    ? jobWithImg.img_url && getImageUrlByProxy(jobWithImg.img_url)
    : "";
};

const isPlainJsObject = (variable: any) =>
  typeof variable === "object" && !Array.isArray(variable) && variable !== null;

const mapImages = (jobs: Job[]) =>
  jobs.map((job) => ({
    ...job,
    origin_img_url: findSourceImage(job),
    result_img_url:
      job?.body?.result_url && getImageUrlByProxy(job["body"]["result_url"]),
    user: job.result
      .filter((resultJob) => resultJob.user && isPlainJsObject(resultJob.user))
      .map((job) => job.user)[0],
  }));

const ratingJobsReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(fetchRatingJobs, (state, action) => {
      state.jobsFailed = false;
      state.jobsSuccess = false;
      state.jobsLoading = true;
    })
    .addCase(fetchRatingJobsSuccess, (state, action) => {
      const { docs, sort, ...pagination } = action.payload;
      state.jobsSuccess = true;
      state.jobsFailed = false;
      state.jobsLoading = false;
      state.jobs = mapImages(docs);
      state.sort = sort || {};
      state.pagination = pagination;
    })
    .addCase(fetchRatingJobsFailed, (state, action) => {
      state.jobsFailed = true;
      state.jobsLoading = false;
      state.jobsSuccess = false;
    });
});

export default ratingJobsReducer;
