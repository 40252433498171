import React, {useEffect, useState} from "react";
import {connect, ConnectedProps} from "react-redux";
import {RootState} from "../../redux/reducers";
import {Modal, Table, Space, Button, Form, Input, Row, Col, Checkbox, Divider, Popconfirm, message} from "antd";
import {Platform} from "../../types";
import {fetchPlatformsQuery, queryTypes} from "../../query/queries";
import {deletePlatform, editPlatform} from "../../query/mutations";
import {useMutation, useQuery} from "react-query";
import {QueryCache, ReactQueryCacheProvider} from "react-query";
import {EventsCache} from "../EventsPage";

export const PlatformsCache = new QueryCache();

const Platforms: React.FC<Props> = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState<Platform | undefined>(undefined);

  const {data: items} = useQuery(
    [queryTypes.PLATFORMS_LIST],
    fetchPlatformsQuery
  );

  const [deleteAction] = useMutation(deletePlatform, {
    onSuccess: () => {
      EventsCache.refetchQueries([], { stale: true });
      message.success("Platform deleted!", 2);
    },
    onError: () =>{ message.error("Error check api logs for more info.", 2);},
  });

  const [form] = Form.useForm();

  const removeSizeUnit = (size: string|undefined) => {
    if (size) {
      return `${size}`.replace('MB', '')
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      ...selectedPlatform,
      max_file_size: removeSizeUnit(selectedPlatform?.max_file_size)
    });
    form.setFields([
      {name: 'thumbnail.remove_background', value: selectedPlatform?.thumbnail?.remove_background},
      {name: 'thumbnail.is_square', value: selectedPlatform?.thumbnail?.is_square},
      {name: 'thumbnail.background_color', value: selectedPlatform?.thumbnail?.background_color},
      {name: 'thumbnail.cover_percentage', value: selectedPlatform?.thumbnail?.cover_percentage},
      {name: 'thumbnail.max_file_size', value: removeSizeUnit(selectedPlatform?.thumbnail?.max_file_size)},
    ])
  }, [form, selectedPlatform]);

  const openEditModal = (product: Platform) => {
    setSelectedPlatform(product);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [savePlatform] = useMutation(editPlatform, {
    onSuccess: () => {
      PlatformsCache.refetchQueries([], {stale: true});
    },
    onError: () => {
      console.error(`Error occured, check logs`);
    },
  });

  const getSortedProducts = () => {
    const suppliedList = items?.items.map((p) => {
      return p;
    });
    return suppliedList?.sort(
      (a, b) =>
        ((a.name < b.name && 1) || -1)
    );
  };
  const [fetchedData, setFetchedData] = useState<Platform[] | undefined>(
    undefined
  );

  useEffect(() => {
    setFetchedData(getSortedProducts());
  }, [items]);

  const onFinish = (values: any) => {
    setIsModalVisible(false);
    values._id = selectedPlatform?._id
    values.thumbnail = {
      width: values.width,
      height: values.height,
      remove_background: values['thumbnail.remove_background'],
      is_square: values['thumbnail.is_square'],
      background_color: values['thumbnail.background_color'],
      cover_percentage: values['thumbnail.cover_percentage'],
      max_file_size: `${values['thumbnail.max_file_size'] || 100}MB`,
      format: 'jpg'
    }
    values.format = 'jpg'
    values.max_file_size = `${values.max_file_size || 100}MB`

    savePlatform(values);
    setFetchedData(getSortedProducts());
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Width",
      dataIndex: "width",
      key: "width",
    },
    {
      title: "Height",
      dataIndex: "height",
      key: "height",
    },
    {
      title: "Remove background",
      dataIndex: "remove_background",
      key: "remove_background",
      render: (platform: boolean) => {
        return <Checkbox checked={platform} disabled/>
      }
    },
    {
      title: "Action",
      key: "action",
      render: (platform: Platform) => (
        <>
        <Button type="primary" onClick={() => openEditModal(platform)}>
          Edit
        </Button>
          <Divider type="vertical"/>
            <Popconfirm
    title="Are you sure to delete this platform?"
    onConfirm={() => deleteAction(platform._id)}
    // onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
        <Button type="default">
          Delete
        </Button>
            </Popconfirm>
          </>
      ),
    },
  ];

  const getEmptyPlatform = (): Platform => {
    return {
      height: 0, name: "", remove_background: false, width: 0,
      thumbnail: {
        width: 0,
        height: 0,
        remove_background: false
      }
    }
  }

  return (
    <ReactQueryCacheProvider queryCache={PlatformsCache}>
      <Modal
        title="Edit Platform"
        visible={isModalVisible}
        okText="Save"
        onOk={() => {
          form.submit();
        }}
        onCancel={handleCancel}
        width="1000px"
      >
        <Space>
          <Form
            form={form}
            name="update_product"
            size="small"
            labelCol={{
              span: 12,
            }}
            // wrapperCol={{
            //   span: 8,
            // }}
            initialValues={selectedPlatform}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row>
              <Col span={24}>

                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input/>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Width"
                  name="width"
                >
                  <Input type="number"/>
                </Form.Item>

                <Form.Item
                  label="Height"
                  name="height"
                >
                  <Input type="number"/>
                </Form.Item>

                <Form.Item
                  label="Min width"
                  name="width_min"
                >
                  <Input type="number"/>
                </Form.Item>

                <Form.Item
                  label="Min height"
                  name="height_min"
                >
                  <Input type="number"/>
                </Form.Item>

                <Form.Item
                  label="Max width"
                  name="width_max"
                >
                  <Input type="number"/>
                </Form.Item>

                <Form.Item
                  label="Max height"
                  name="height_max"
                >
                  <Input type="number"/>
                </Form.Item>

                <Form.Item
                  label="Remove background"
                  name="remove_background"
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Checkbox/>
                </Form.Item>

                <Form.Item
                  label="Square"
                  name="is_square"
                  valuePropName="checked"
                >
                  <Checkbox/>
                </Form.Item>

                <Form.Item
                  label="Background color"
                  name="background_color"
                >
                  <Input type="string"/>
                </Form.Item>

                <Form.Item
                  label="Image amount"
                  name="image_amount"
                >
                  <Input type="number"/>
                </Form.Item>

                <Form.Item
                  label="Cover percentage"
                  name="cover_percentage"
                >
                  <Input type="number"/>
                </Form.Item>

                <Form.Item
                  label="File size (MB)"
                  name="max_file_size"
                >
                  <Input type="number"/>
                </Form.Item>

              </Col>

              <Col span={12}>
                <Form.Item
                  label="Thumbnail remove background"
                  name="thumbnail.remove_background"
                  valuePropName="checked"
                >
                  <Checkbox/>
                </Form.Item>

                <Form.Item
                  label="Thumbnail square"
                  name="thumbnail.is_square"
                  valuePropName="checked"
                >
                  <Checkbox/>
                </Form.Item>

                <Form.Item
                  label="Thumbnail background color"
                  name="thumbnail.background_color"
                >
                  <Input type="string"/>
                </Form.Item>

                <Form.Item
                  label="Cover percentage"
                  name="thumbnail.cover_percentage"
                >
                  <Input type="number"/>
                </Form.Item>
                <Form.Item
                  label="File size (MB)"
                  name="thumbnail.max_file_size"
                >
                  <Input type="number"/>
                </Form.Item>

              </Col>
            </Row>
          </Form>
        </Space>
      </Modal>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={2}>
              <h1 className="tab-header">Platforms</h1>
            </Col>
            <Col span={20}></Col>
            <Col span={2}>
              <Button
                type="primary"
                className="btn-position-right"
                onClick={() => openEditModal(getEmptyPlatform())}
              >
                Add new
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table columns={columns} dataSource={fetchedData}/>
    </ReactQueryCacheProvider>
  )
}

const mapDispatchToProps = {};

const mapStateToProps = (state: RootState) => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & {};

export default connector(Platforms);
