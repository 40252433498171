import { CheckboxValueType } from "antd/lib/checkbox/Group";

export type User = {
  _id: string;
  api_key: string;
  google_id: string;
  name: string;
  email: string;
  email_confirmed: boolean;
  terms_accepted: boolean;
  cta_notifications_enabled: boolean;
  disabled: boolean;
  purchased_credits: number;
  stripe: Stripe;
  permissions: Permissions;
  feature_flags: FeatureFlags;
  subscription: {
    plan_usage: any
  },
  devices: Array<string>;
  createdAt: Date;
  updatedAt: Date;
  enterprise?: boolean;
  shopify?: {
    email: string;
  },
  utm?: UtmTags;
};

// interface PlanUsage {
//   deep_image: ApplicationSubscription | {}
// }

export type UserAction = {
  name: string;
  Component: any;
  onAccept: any;
  comment?: string;
}

export type UpdatedCredits = {
  purchasedCredits: number;
  subscriptionCredits: number;
  additionalAmount?: number;
}

export type Plan = {
  _id: string;
  name: string;
  stripe: string;
  type: string;
}

interface ApplicationSubscription {
  available_requests: number;
  total: number;
  started: Date;
  plan: Plan
}

interface Stripe {
  customer: string;
  data: any;
}

interface Permissions {
  adminPanelAccess: boolean;
}

interface FeatureFlags {
  payment_plan: boolean;
  jpeg_artifacts: boolean;
  batch_processing: boolean;
}

export type ValidationErrors<T> = {
  [P in keyof T]?: Array<string>;
} & { non_field_errors: Array<string> };


export type loginPageFormType = {
  username: string,
  password: string,
  remember: boolean,
}

// TODO: reuse Pagination..
export type UserSearchResponse = {
  docs: Array<User>,
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  page: number;
  nextPage: string | null;
  prevPage: string | null;
  pagingCounter: number;
  totalDocs: number;
  totalPages: number;
}

export type Action = {
  _id: string;
  admin: string;
  adminEmail: string;
  account: string;
  accountEmail: string;
  type: string;
  message: string;
  comment: string;
  createdAt: Date;
}

export type UserActionSearchResponse = {
  docs: Array<Action>;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  page: number;
  nextPage: string | null;
  prevPage: string | null;
  pagingCounter: number;
  totalDocs: number;
  totalPages: number;
}

export type Pagination = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  limit: number;
  page: number;
  nextPage: string | null;
  prevPage: string | null;
  pagingCounter: number;
  totalDocs: number;
  totalPages: number;
  total?: number;
}

export enum PROMO_TYPE_KINDS {
  DEFAULT = "default",
  STRIPE_SUBSCRIPTION = "stripe_subscription",
}

export enum PROMO_TYPE_PRODUCT_TYPES {
	ALL = 0,
	YEARLY_SUBSCRIPTION = 1,
	MONTHLY_SUBSCRIPTION = 2,
	SUBSCRIPTION = 3,
	ONETIME = 4,
}

export type PromoType = {
  _id : string,
  name : string,
  value: number,
  usage: number,
  user_usage: number,
  kind: PROMO_TYPE_KINDS,
  mode: boolean,
  createdAt?: Date,
  updatedAt?: Date,
  creator?: User,
  product_type: number,
  applicable_plans: ApplicablePlans,
}

export type PromoTypeCreatePayload = {
  name : string,
  value : number,
  usage : number,
  user_usage : number,
  mode : boolean,
  creator?: User,
  product_type: number,
  kind: PROMO_TYPE_KINDS,
  applicable_plans: ApplicablePlans
}

export type PromoTypeMinimal = {
  _id : string,
  name : string,
  mode : boolean,
}

export type PromoCode = {
  _id : string,
  code : string,
  type : PromoType,
  expires? : string,
  owner? : User,
  createdAt? : Date,
  updatedAt? : Date,
  creator? : User,
  __v? : number,
}

export type PromoCodeCreatePayload = {
  code: string,
  type : PromoTypeMinimal,
  expires?: string,
  owner? : User,
  creator?: User,
}


export type PromoUsage = {
  _id : string,
  code : PromoCode,
  user : User,
  stripe_info : string,
  bonus_credits: number,
  payment_type : string,
  payment_name : string,
  createdAt? : Date,
  updatedAt? : Date,
}

export type Featureflags = {
  payment_plan: boolean;
  jpeg_artifacts: boolean;
  batch_processing: boolean;
  transparency: boolean;
}

export type Subscription = {
  plan_usage: Planusage;
}

export type Planusage = {
  deep_image: Deepimage;
}

export type Deepimage = {
  plan: string;
  available_requests: number;
  total: number;
  started: Started;
}

export type Started = {
  '$date': string;
}

export type Data = {
  id: string;
  object: string;
  address?: any;
  balance: number;
  created: number;
  currency?: any;
  default_source?: any;
  delinquent: boolean;
  description?: any;
  discount?: any;
  email: string;
  invoice_prefix: string;
  invoice_settings: Invoicesettings;
  livemode: boolean;
  metadata: Metadata;
  name?: any;
  next_invoice_sequence: number;
  phone?: any;
  preferred_locales: any[];
  shipping?: any;
  sources: Sources;
  subscriptions: Sources;
  tax_exempt: string;
  tax_ids: Sources;
  tax_info?: any;
  tax_info_verification?: any;
}

export type Sources  = {
  object: string;
  data: any[];
  has_more: boolean;
  total_count: number;
  url: string;
}

export type Metadata = {
}

export type Invoicesettings ={
  custom_fields?: any;
  default_payment_method?: any;
  footer?: any;
}

export type Id = {
  '$oid': string;
}

export type sortOrderCodes = {
  code?: number,
  type?: number,
  expires?: number,
  createdAt?: number,
}

export type sortOrderTypes = {
  name?: number,
  value?: number,
  usage?: number,
  user_usage?: number,
  mode?: number,
  createdAt?: number,
  product_type?: number,
}

export type sortOrderUsages = {
  _id?: number,
  bonus_credits?: number,
  createdAt?:number,
  updatedAt?:number,
}

export type CodeSearchPayload = {
  docs: Array<PromoCode>,
  sort?: sortOrderCodes,
} & Pagination;

export type CodeTypeSearchPayload = {
  docs: Array<PromoType>,
  sort?: sortOrderTypes,
} & Pagination;

export type CodeUsageSearchPayload = {
  docs: Array<PromoUsage>,
  sort?: sortOrderUsages,
} & Pagination;

export type SearchType = {
  page : number,
  limit : number,
  sort : any,
  paginationEnabled: boolean,
  search? : {
    type? : string,
  },
}

export type SearchPromoUsages = {
  search? : {
    user? : {email : string},
    code? : {code : string},
  },
} & SearchType

export type GeneratePromoCodesPayload = {
  type: string | undefined,
  amount : number,
  config: {
    charset: string,
    prefix: string,
    length: number,
  },
  owner?:string,
  expires?:string,
}
export type FetchPromoTypesMinimalSuccessPayload = {
  types: Array<PromoTypeMinimal>
};

export type Notifications = {
  _id : string,
  name : string,
  title : string,
  description : string,
  code?: string,
  icon?: string,
  auth?: boolean,
  admin?: boolean,
  active_start? : string,
  active_end? : string,
  creator? : User,
  createdAt? : string,
  updatedAt? : string,
  type?: string
}

export type EditNotificationPayload = {
  name: string,
  title: string,
  description: string,
  admin: boolean,
  code: string,
  auth: boolean,
  active_start: string,
  active_end: string,
  type: string
}

export type DeleteNotificationPayload = {
  id : string,
}

export type NotificationsSortOrder = {
  title? : number,
  name? : number,
  active_start? : number,
  active_end? : number,
  createdAt? : number,
  updatedAt? : number,
}

export type NotificationVerifyPayload = {
  start: Date,
  end : Date,
}

export type NotificationVerifyFailed = {
  name : string,
}

export type SearchNotifications = {
  search? :  {
    title?: string,
    name?: string,
    creator?: string,
  }
} & SearchType;

export type NotificationsSearchPayload = {
  docs: Array<Notifications>,
  sort?: NotificationsSortOrder,
} & Pagination;

export type CreateNotificationPayload = EditNotificationPayload;

export type SendIconPayload = {_id: string, file: File};

export type DeleteIconPayload = string;

export type PromoCodeMinimal = {_id: string, code: string}

export type fetchPromoCodesMinimalSuccessPayload = { codes: Array<PromoCodeMinimal> };

export type fetchActiveJobsPayload = { jobs : number };

export type setMaintenanceActivePayload = { minutes: number };

export type UserChannelsPayload = { api: number, web: number };

export type EventAction = {
  _id: string;
  action_name: string;
  action_type: string;
  email?: string;
  email_id?: number;
  contact?: string;
};

export type EventGroup = {
  _id: string;
  group_name: string;
  group_title: string;
  group_description: string;
  actions: Array<EventAction>;
};

export type fetchEventGroupsType = {
  docs: Array<EventGroup>;
  hasNextPage: boolean;
  hasPervPage: boolean;
  limit: number;
  page: number;
  pagingCounter: number;
  prevPage: number;
  totalDocs: number;
  totalPages: number;
};

export type fetchEventActionsType = {
  docs: Array<EventAction>;
};

export type fetchEventGroupsSelectType = {
  _id?: number;
  group_name?: number;
  group_title?:number;
  group_description?:number;
  actions?: number;
  createdAt?: number;
  updatedAt?: number;
};

export type EventGroupSearch = {
  _id?: string;
  group_name?:string;
  group_title?:string;
  group_description?:string;
}

export type fetchEventActionsSelectType = {
  _id?: number;
  action_name?: number;
};

export type fetchMauticMailList = {
  id: number,
  name: string,
}

export type groupPaginationConfig = {
  select : fetchEventGroupsSelectType,
  sort : fetchEventGroupsSelectType,
  page: number,
  limit: number,
  pagination: boolean,
}


export type fetchJobsPayload = {
  search? : {
    user? : {email : string},
    code? : {code : string},
    status: string,
    source: string,
  },
} & SearchType

export type fetchJobsRatingsPayload = {
  search : {
    status: string,
    source: string,
    rating?: CheckboxValueType[],
    from?: Date,
    to?: Date,
    search?: string,
    option?: string,
    publishAgreement?: boolean,
    operations?: CheckboxValueType[],
    action?: string,
  },
} & SearchType


export type JobsOrderUsages = {
  _id?: number,
  timestamp?: number,
}

export type ProcessingJobResult = {
  origin_url: string;
  result_url: string;
  cost: number;
}

export type ProcessingJob = {
  user?: {
    email: string,
    _id: string
  };
  source: string;
  _id : string,
  hash : string,
  timestamp : Date,
  action?: string,
  status: string,
  result?: ProcessingJobResult,
  rating?: number;
  rating_description?: {
    text: string,
    option: string,
    optionDescription: string
  },
  img_url?: string
}

export type DataDetail = {
  transformations: Array<string>,
  dimensions: string
  email: string
  url?: string
}

export type JobBody = {
  origin_url: string
  result_url: string
}

export type Job = {
  _id : string,
  hash : string,
  timestamp : Date,
  result: Array<ProcessingJob>,
  body: JobBody,
  data: DataDetail,
  img_url?: string,
  rating?: number,
  rating_description?: {
    text: string,
    option: string,
    optionDescription: string
  },
  action: string
}

export type JobsPayload = {
  docs: Array<Job>,
  sort?: JobsOrderUsages,
} & Pagination;

export type Images = {
  origin_img_url: string,
  result_img_url: string
}

export type MappedJob = Job & Images

export type ShopifyProduct = {
  _id: string,
  key: number,
  id: string,
  name: string,
  billing: string,
  images: number,
  price: number
}

export type ShopifyProductsList = {
  items: ShopifyProduct[]
}

export type ImageStats = {
  source_type: string,
  img_types_stats: {}
}

export type Platform = {
  _id?: string,
  name: string,
  key?: number,
  width?: number,
  height?: number,
  remove_background: boolean,
  is_square?: boolean,
  background_color?: string,

  width_min?: number,
  height_min?: number,

  width_max?: number,
  height_max?: number,
  cover_percentage?: number,
  max_file_size?: string,

  thumbnail?: {
    width?: number,
    height?: number,
    remove_background: boolean,
    format?: string
    is_square?: boolean,
    background_color?: string,
    width_min?: number,
    height_min?: number,
    width_max?: number,
    height_max?: number,
    cover_percentage?: number,
    max_file_size?: string
  }
}


export type ImageStatsList = {
  items: ImageStats[]
}

export type PlatformsList = {
  items: Platform[]
}

export type QuestionnaireConfig = {
  _id: string,
  type: string,
  app: string,
  payload : QuestionnaireConfigPayload
}

export type QuestionnaireConfigPayload = {
  creditsPercentage: number,
  maxCredits: number,
  minCredits: number,
  paidUsers: boolean,
  sharedUrl: string,
  url: string,
  formId: string
}

export type ApplicablePlans = [number]


export type Prompt = {
  _id?: string,
  description: string,
  group: string,
  image_path: string,
  name: string,
  ordering: number,
  thumbnail_path?: string,
  face_id: boolean,
  tags?: string[],
}

export type PromptList = {
  items: Prompt[]
}

export type PromptTag = {
  id: string,
  name: string
}

export type UtmTags = {
  source: string;
  medium: string;
  campaign: string;
  content: string;
}

export type StripeMetadata = {
  payer: string;
  payment_intent: string;
}

export type PaymentMetadata = {
  stripe: StripeMetadata;
  utmTags: UtmTags;
}

export type Payment = {
  _id?: string;
  periodStart: Date;
  periodEnd: Date;
  plan: Plan;  // Referencing the existing Plan type
  user: User;  // Referencing the existing User type
  availableRequests: number;
  subscriptionId: string;
  billing_reason: string;
  metadata: PaymentMetadata;
  invoice: string;
  createdAt: Date;
  updatedAt: Date;
}

export type PaymentList = {
  docs: Array<Payment>,
} & Pagination;

export type UtmSourceList = Array<string>;
