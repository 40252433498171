import React from "react";
import { connect } from "react-redux";
import { RootStateType } from "../../redux/store";
import { user } from "../../redux/selectors/authSelector";
import DownloadFbCatalog from "./DownloadFbCatalog";
import Title from "antd/lib/typography/Title";

type Props = ReturnType<typeof mapState> & typeof mapDispatch;

const DashboardPage: React.FunctionComponent<Props> = ({ user }) => {
  return (
    <>
      <Title level={3}>Dashboard</Title>
      <DownloadFbCatalog />
    </>
  );
};

const mapState = (state: RootStateType) => ({
  user: user(state),
});

const mapDispatch = {};

export default connect(mapState, mapDispatch)(DashboardPage);
