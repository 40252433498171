import axios from "axios";
import { call, takeLatest, put } from "redux-saga/effects";
import { API } from "../../config";
import { authorizationHeaders } from "../../utils/api";
import { getAuthToken } from "../../utils/storage";
import {
  fetchActiveJobsFailed,
  fetchActiveJobs,
  fetchActiveJobsSuccess,
} from "../actions";

const delay = (time: number) =>
  new Promise((resolve) => setTimeout(resolve, time));

function* fetchActiveJobsSaga(): any {
  try {
    const token = getAuthToken();
    if (!token) return;
    const response = yield call(
      axios.get,
      API(`jobs/active`),
      authorizationHeaders(token)
    );
    yield put(fetchActiveJobsSuccess(response.data));
    if (response.data.jobs !== 0) {
      yield call(delay, 5000);
      yield put(fetchActiveJobs());
    }
  } catch (e) {
    yield put(fetchActiveJobsFailed(e));
  }
}

export default function* jobsSaga() {
  yield takeLatest(fetchActiveJobs, fetchActiveJobsSaga);
}
