import React from 'react';
import {login, loginGoogleOAuth} from '../../redux/actions';
import {ConnectedProps, connect} from 'react-redux';
import GoogleLogin, {GoogleLoginResponse} from 'react-google-login';
import {Form, Input, Button} from "antd";

const mapDispatchToProps = {
  loginGoogleOAuth,
  login,
}

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const Login: React.FunctionComponent<Props> = ({loginGoogleOAuth, login}) => {

  const handleSuccess = (values: any) => {
    loginGoogleOAuth({access_token: values.accessToken})
  }

  const handleFailue = (values: GoogleLoginResponse) => {
    // TODO: handle error
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', marginTop: '10vh'}}>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 8,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={login}
        autoComplete="off"
      >
        <Form.Item
          label="Username"
          name="email"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password/>
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
      <div style={{display: 'flex', alignItems: 'center'}}>
          <Button type="primary" htmlType="submit" style={{marginRight: '1rem'}}>
            Submit
          </Button>
        <GoogleLogin
          clientId="702370168417-jjd2ncqmlh3pnhfphtd4cpfuu6r8nu2k.apps.googleusercontent.com"
          buttonText="Login with Google account"
          onSuccess={handleSuccess}
          onFailure={handleFailue}
          cookiePolicy={'single_host_origin'}
        />
      </div>
        </Form.Item>
      </Form>

    </div>
  );
};

export default connector(Login);
