/* eslint no-console: 0 */
import axios from 'axios';
import { takeEvery, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { blacklistDomain, blacklistDomainSuccess } from '../actions/index';
import { getAuthToken } from '../../utils/storage';
import { API } from '../../config';
import { authorizationHeaders } from '../../utils/api';
import { blacklistDomainError } from '../actions/index';



function* blacklistDomainSaga({ payload }: PayloadAction<string>) {
  try {
    const token = getAuthToken();
    if (!token) return; 

    yield axios.post(
      API(`domains/blacklist`),
      { domain: payload },
      authorizationHeaders(token)
    );
    yield put(blacklistDomainSuccess());
  } catch (e) {
    e.response ?
      yield put(blacklistDomainError(e.response.data.message)) :
      yield put(blacklistDomainError(e.message));
  }
}


export default function* domainSaga() {
  yield takeEvery(blacklistDomain, blacklistDomainSaga);
}
