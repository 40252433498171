import voucher_generator from 'voucher-code-generator';
import _ from 'lodash';

export const getVoucherGeneratorConfig = (values: any) => {
    const config = {
      length: values.limit_length ? values.limit_length : 10,
      charset: "",
      prefix: "",
    };
    if (_.includes(values.generator_config, "letters"))
      config.charset = config.charset + voucher_generator.charset("alphabetic");
    if (_.includes(values.generator_config, "numbers"))
      config.charset = config.charset + voucher_generator.charset("numbers");
    if (_.includes(values.generator_config, "type_name"))
      config.prefix = values.type + "-";
    return config;
}

export const setSearchSortObject = (filters: any) => {
  let sort : any = {};
    if (
    filters &&
    filters.column &&
    filters.column.dataIndex &&
    filters.order
  ) {
    sort[filters.column.dataIndex] = filters.order === "ascend" ? 1 : -1;
  }
  return sort;
}

 export const getSortOrderFromValue = (
    value: number
  ): "ascend" | "descend" | undefined => {
    if (value === -1) return "descend";
    if (value === 1) return "ascend";
    return undefined;
  };
