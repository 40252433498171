import { Col, Row } from "antd";
import React from "react";
import ActionsList from "./ActionsList";
import GroupsList from "./GroupsList";
import "./index.scss";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import TestEvents from "./TestEvents";
import { ReactQueryDevtools } from 'react-query-devtools'

export const EventsCache = new QueryCache();

const EventsPage: React.FC = () => {
  return (
    <ReactQueryCacheProvider queryCache={EventsCache}>
      <div id="eventsPage">
        <Row gutter={[0,50]}>
          <Col span={24}>
            <Row>
              <Col span={2} offset={10}>
                <TestEvents />
              </Col>
            </Row>
          </Col>
          <Col span={11} offset={1}>
            <GroupsList />
          </Col>
          <Col span={10} offset={1}>
            <ActionsList />
          </Col>
        </Row>
      </div>
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryCacheProvider>
  );
};

export default EventsPage;
