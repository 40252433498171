import React from 'react';
import { Pie } from '@ant-design/plots';

const RatingsPie = (jobs: any) => {
  const x4Jpg = jobs.jobs.filter((job: any) => job.data.transformations.includes("ganzoom4-jpg90")).length
  const x2Jpg = jobs.jobs.filter((job: any) => job.data.transformations.includes("ganzoom2-jpg90")).length
  const x3Jpg = jobs.jobs.filter((job: any) => job.data.transformations.includes("ganzoom3-jpg90")).length
  const denoise = jobs.jobs.filter((job: any) => job.data.transformations.includes("ganzoomnoise1-jpg90")).length
  const enhance = jobs.jobs.filter((job: any) => job.data.transformations.includes("ganenhance1-jpg90")).length
  const removeBackground = jobs.jobs.filter((job: any) => job.data.transformations.includes("remove_background")).length
  const enhanceLight = jobs.jobs.filter((job: any) => job.data.transformations.includes("enhance_light")).length
  const enhanceLightContrast = jobs.jobs.filter((job: any) => job.data.transformations.includes("enhance_light_contrast")).length

  const data = [
    {
      type: 'x2-jpg',
      value: x2Jpg,
    },
    {
      type: 'x3-jpg',
      value: x3Jpg,
    },
    {
      type: 'x4-jpg',
      value: x4Jpg,
    },
    {
      type: 'denoise',
      value: denoise,
    },
    {
      type: 'enhance',
      value: enhance,
    },
    {
      type: 'remove-background',
      value: removeBackground,
    },
    {
      type: 'enhance-light',
      value: enhanceLight,
    },
    {
      type: 'enhance-light-contrast',
      value: enhanceLightContrast,
    },
  ];
  const config = {
    appendPadding: 10,
    data,
    angleField: 'value',
    colorField: 'type',
    radius: 0.8,
    label: {
      type: 'outer',
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  };
  return <Pie {...config} />;
};

export default RatingsPie;
