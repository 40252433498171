import React, {useEffect, useState} from "react";
import {Col, Row, Table,} from "antd";
import {fetchPaymentsQuery, fetchUtmSourcesQuery, queryTypes,} from "../../query/queries";
import {QueryCache, ReactQueryCacheProvider, useQuery,} from "react-query";
import {MappedJob, Pagination, Payment, User} from "../../types";
import {ReactQueryDevtools} from "react-query-devtools";
import moment from "moment/moment";
import {PaginationProps} from "antd/lib/pagination";
import {Link} from "react-router-dom";
import {USER_DETAILS} from "../../paths";

const getFullDate = (date: string): string => {
  if(!date) return "";
  const dateAndTime = date.split('T');

  return `${dateAndTime[0].split('-').reverse().join('-')} ${dateAndTime[1].replace('Z', '')}`;
};

const Payments = () => {
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [ordering, setOrdering] = useState("-createdAt");
  const [filters, setFilters] = useState({})

  const {data} = useQuery(
    [queryTypes.PAYMENTS_LIST, current, pageSize, ordering, filters],
    fetchPaymentsQuery
  );

  const utmSources = useQuery(
    [queryTypes.UTM_SOURCES_LIST],
    fetchUtmSourcesQuery
  ).data?.map(utm => ({ text: utm, value: utm}));

  const [fetchedData, setFetchedData] = useState<Payment[] | undefined>(
    undefined
  );

const formatStringToDecimal = (str: string) => {
    // Parse the string to a float
    let num = parseFloat(str);

    // Check if the parsed value is a number
    if (isNaN(num)) {
        throw new Error("Invalid number format");
    }

    // Format the number to 2 decimal places
    return num.toFixed(2);
};

  const [tablePagination, setTablePagination] = useState<Pagination>({
    totalDocs: 0,
    page: 1,
    limit: 0,
    hasNextPage: false,
    hasPrevPage: false,
    pagingCounter: 0,
    totalPages: 0,
    nextPage: null,
    prevPage: null
  })

  useEffect(() => {
    if(data) {
    setFetchedData(data.docs);
    setTablePagination(data)

    }
  }, [data]);

  const convertPagination = () => ({
    total: tablePagination.totalDocs,
    current: tablePagination.page,
    pageSize: tablePagination.limit
  })

  const columns = [
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => getFullDate(date),
      sorter: true,
    },
    {
      title: "User",
      dataIndex: "user",
      key: "user",
      render: (user: any, data: MappedJob) => {
        return user && <Link to={USER_DETAILS(user._id)}>{user.email}</Link>;
      },
    },
    {
      title: "Product",
      dataIndex: "invoice",
      key: "invoiceProduct",
      render: (invoice: any) => invoice && `${invoice?.data.serviceName} - ${invoice?.data.price} ${invoice?.data.currency}`
    },
    {
      title: "PLN",
      dataIndex: "invoice",
      key: "invoicePLNValue",
      render: (invoice: any) => invoice && formatStringToDecimal(invoice.data.preparedInvoiceData.totalIncludingTaxPln)
    },
    {
      title: "utm source",
      dataIndex: "metadata",
      key: "utmTagsSource",
      render: (data: any) => data?.utmTags?.source,
      filters: utmSources
    },
    {
      title: "utm medium",
      dataIndex: "metadata",
      key: "utmTagsMedium",
      render: (data: any) => data?.utmTags?.medium
    },
    {
      title: "utm campaign",
      dataIndex: "metadata",
      key: "utmTags.campaign",
      render: (data: any) => data?.utmTags?.campaign,
      sorter: true,
    },
  ];

  return (
    <>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={2}>
              <h1 className="tab-header">Payments</h1>
            </Col>
          </Row>
        </Col>
      </Row>

      <Table
        columns={columns}
        dataSource={fetchedData}
        pagination={convertPagination()}
        onChange={(tablePaginationConfig, filters, sorter, record) => {
          setCurrent(tablePaginationConfig.current || 1);
          setPageSize(tablePaginationConfig.pageSize || 10);
          if (sorter?.order) {
            setOrdering(`${sorter.order === 'ascend' ? '': '-'}${sorter.columnKey}`)
          }
          setFilters(filters)
        }}
        />
    </>
  );
};

export const paymentsCache = new QueryCache();

// @ts-ignore
const withPromptsCache = (WrappedComponent) => {
  // @ts-ignore
  const WithPromptsCache = (props) => {
    return (
      <ReactQueryCacheProvider queryCache={paymentsCache}>
        <WrappedComponent {...props} />
        <ReactQueryDevtools initialIsOpen={false}/>
      </ReactQueryCacheProvider>
    );
  };

  WithPromptsCache.displayName = `withPromptsCache(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;
  return WithPromptsCache;
};

export default withPromptsCache(Payments);
