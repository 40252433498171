import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../redux/reducers";
import { Table, Divider } from "antd";
import { usagePagination } from "../../redux/selectors/codesSelector";
import { fetchPromoUsages } from "../../redux/actions";
import { PromoUsage, SearchPromoUsages, User } from "../../types";
import { PaginationProps } from "antd/lib/pagination";
import moment from "moment";

const CodesUsageHistory: React.FC<Props> = ({
  sortOrder,
  pagination,
  usages,
  fetchUsages,
  user,
}) => {
  const { email } = user;
  useEffect(() => {
    fetchUsages({
      page: 1,
      limit: 10,
      search: {
        user: { email: email },
      },
    } as SearchPromoUsages);
  }, [fetchUsages, email]);

  const getSortOrderFromValue = (
    value: number
  ): "ascend" | "descend" | undefined => {
    if (value === -1) return "descend";
    if (value === 1) return "ascend";
    return undefined;
  };

  const handleTableChange = (
    pagination: PaginationProps,
    sorter: any,
    filters: any
  ) => {
    if (pagination.pageSize && pagination.current) {
      let sort: any = {};
      if (
        filters &&
        filters.column &&
        filters.column.dataIndex &&
        filters.order
      ) {
        sort[filters.column.dataIndex] = filters.order === "ascend" ? 1 : -1;
      }
      fetchUsages({
        page: pagination.current,
        limit: pagination.pageSize,
        sort: sort,
        search: {
          user: { email: email },
        },
      } as SearchPromoUsages);
    }
  };

  const columns = [
    // {
    //   title: "ID",
    //   dataIndex: "_id",
    //   key: "_id",
    //   sorter: (a: PromoUsage, b: PromoUsage) => a._id.localeCompare(b._id),
    // },
    // {
    //   title: "User",
    //   dataIndex: ["user", "email"],
    //   key: "user",
    //   sorter: (a: PromoUsage, b: PromoUsage) => {
    //     if (a.user && b.user && a.user.email && b.user.email) {
    //       return a.user.email.localeCompare(b.user.email);
    //     } else {
    //       return 0;
    //     }
    //   },
    // },
    {
      title: "code",
      dataIndex: ["code", "code"],
      key: "code",
    },
    // {
    //   title: "Session ID",
    //   dataIndex: "session_id",
    //   key: "session_id",
    // },
    {
      title: "Bonus credits",
      dataIndex: "bonus_credits",
      key: "bonus_credits",
      sorter: (a: PromoUsage, b: PromoUsage) =>
        a.bonus_credits - b.bonus_credits,
      sortOrder: sortOrder.bonus_credits
        ? getSortOrderFromValue(sortOrder.bonus_credits)
        : null,
    },
    // {
    //   title: "Payment Name",
    //   dataIndex: "payment_name",
    //   key: "payment_name",
    // },
    // {
    //   title: "Payment Type",
    //   dataIndex: "payment_type",
    //   key: "payment_type",
    // },
    {
      title: "Used",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortOrder.createdAt
        ? getSortOrderFromValue(sortOrder.createdAt)
        : null,
    },
    {
      title: "Updated",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: (a: any, b: any) =>
        moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
      sortOrder: sortOrder.updatedAt
        ? getSortOrderFromValue(sortOrder.updatedAt)
        : null,
    },
  ];

  return (
    <React.Fragment>
      <h3>Codes usage history</h3>
      <Divider/>
      <Table
        dataSource={usages}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          ...pagination,
          pageSizeOptions: ["10", "20", "30", "50", "100"],
          showSizeChanger: true,
        }}
        bordered
        rowKey={(value) => value._id}
      />
    </React.Fragment>
  );
};

const mapDispatchToProps = {
  fetchUsages: fetchPromoUsages,
};

const mapStateToProps = (state: RootState) => ({
  usages: state.promoCodes.usages,
  pagination: usagePagination(state),
  sortOrder: state.promoCodes.usagesSort,
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & { user: User };

export default connector(CodesUsageHistory);
