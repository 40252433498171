import { List, Input, Button } from "antd";
import { FormOutlined, PlusCircleOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { usePaginatedQuery } from "react-query";
import { fetchEventActions, queryTypes } from "../../../query/queries";
import { EventAction } from "../../../types";
import ActionModal from "../ActionModal";
import ActionDelete from "../ActionModal/ActionDelete";
import "./index.scss";

const { Search } = Input;

const ActionsList: React.FC = () => {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [visible, setVisible] = useState(false);
  const [formValues, setFormValues] = useState({} as EventAction);

  const { resolvedData, isLoading, canFetchMore } = usePaginatedQuery(
    [queryTypes.EVENT_ACTIONS_LIST, page, search, {}, true],
    fetchEventActions
  );

  const renderLoad =
    !isLoading && resolvedData && canFetchMore ? (
      <Button type="primary" onClick={() => setPage((old) => old + 1)}>
        Load more
      </Button>
    ) : null;

  return (
    <>
      <ActionModal
        visible={visible}
        setVisible={setVisible}
        initialValues={formValues}
      />
      <div className="event-actions-search">
        <Search
          placeholder="Search actions"
          allowClear
          onSearch={(value: string) => setSearch(value)}
          className="actions-search"
        />
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            setFormValues({} as EventAction);
            setVisible(true);
          }}
        >
          Action
        </Button>
      </div>
      <List
        className="event-action-list"
        size="large"
        loading={isLoading}
        bordered
        loadMore={renderLoad}
        dataSource={resolvedData ? resolvedData.docs : []}
        renderItem={(item: EventAction) => (
          <List.Item>
            {item.action_name}
            <div>
              <ActionDelete name={item.action_name} _id={item._id} />
              <Button
                type="primary"
                icon={<FormOutlined />}
                onClick={() => {
                  setFormValues(item);
                  setVisible(true);
                }}
              ></Button>
            </div>
          </List.Item>
        )}
      />
    </>
  );
};

export default ActionsList;
