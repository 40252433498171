import { createSelector } from "@reduxjs/toolkit";
import { RootState } from '../reducers/index';

export const notificationsBranch = (state: RootState) => state.notifications;

export const notificationsPagination = createSelector(notificationsBranch, (branch) => ({
  total : branch.pagination.totalDocs,
  current : branch.pagination.page,
  pageSize : branch.pagination.limit,
}));

export const MaintenanceSuccessActive = createSelector(notificationsBranch, (branch) => {
  return (!branch.maintenanceLoading && branch.maintenanceSuccess && !branch.maintenanceFailed && branch.maintenance)
});

export const MaintenanceSuccessDisabled = createSelector(notificationsBranch, (branch) => {
  return (!branch.maintenanceLoading && branch.maintenanceSuccess && !branch.maintenanceFailed && !branch.maintenance)
});

export const MaintenanceFailed = createSelector(notificationsBranch, (branch) => {
  if(!branch.maintenanceSuccess && !branch.maintenanceLoading && branch.maintenanceFailed) return branch.maintenance;
  return false
})