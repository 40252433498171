import { Form, Input, message, Modal, Select } from "antd";
import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { EventsCache } from "../";
import { createEventAction, editEventAction } from "../../../query/mutations";
import {
  fetchEventActions,
  fetchEventActionTypes,
  fetchMauticEmails,
  queryTypes,
} from "../../../query/queries";
import _ from "lodash";
import { EventAction } from "../../../types";

const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16 },
};

type Props = {
  visible: boolean;
  setVisible(value: boolean): void;
  initialValues: EventAction;
};

const ActionModal: React.FC<Props> = ({
  visible,
  setVisible,
  initialValues,
}) => {
  const [form] = Form.useForm();
  const [formEditMode, setFormEditMode] = useState(false);
  const [requiredFields, setRequiredFields] = useState([] as Array<string>);

  const [createAction] = useMutation(createEventAction, {
    onSuccess: () => {
      EventsCache.refetchQueries([], { stale: true });
      setVisible(false);
      form.resetFields();
      message.success("Action added", 2);
    },
    onError: () => {
      message.error(`Error occured, check logs`, 2);
    },
  });

  const [editAction] = useMutation(editEventAction, {
    onSuccess: () => {
      EventsCache.refetchQueries([], { stale: true });
      setVisible(false);
      form.resetFields();
      message.success("Action edited", 2);
    },
    onError: () => {
      message.error(`Error occured, check logs`, 2);
    },
  });

  const { data: mauticEmails, isLoading: emailsLoading } = useQuery(
    [queryTypes.MAUTIC_EMAILS_LIST],
    fetchMauticEmails
  );

  const { data: actionTypes, isLoading: actionTypesLoading } = useQuery(
    [queryTypes.EVENT_ACTION_TYPES],
    fetchEventActionTypes
  );

  const { data: actionsNames } = useQuery(
    [queryTypes.EVENT_ACTION_NAME_LIST, 0, "", { action_name: 1 }, false],
    fetchEventActions
  );

  useEffect(() => {
    if (initialValues && initialValues.action_name && visible) {
      setFormEditMode(true);
      setRequiredFields(actionTypes[initialValues.action_type]);
      form.resetFields();
    } else {
      setFormEditMode(false);
      setRequiredFields([]);
      form.resetFields();
    }
  }, [form, initialValues, visible, actionTypes]);

  const rules = {
    action_name: [
      { required: true },
      () => ({
        validator(rule: any, value: any) {
          if (actionsNames && !formEditMode) {
            const filtered = _.find(actionsNames.docs, ["action_name", value]);
            if (filtered) {
              return Promise.reject("Name is already in use");
            }
          }
          return Promise.resolve();
        },
      }),
    ],
  };

  const onValueChanges = (value: any) => {
    if (value && value.action_type) {
      setRequiredFields(actionTypes[value.action_type]);
    }
  };

  const handleFinish = (values: any) => {
    if (!formEditMode) {
      createAction(values);
    }
    if (formEditMode) {
      editAction({ ..._.pick(initialValues, ["_id"]), ...values });
    }
  };

  return (
    <Modal
      title={
        formEditMode && initialValues.action_name
          ? ` Edit ${initialValues.action_name}`
          : "Add Action"
      }
      visible={visible}
      destroyOnClose={false}
      forceRender
      onOk={() => form.submit()}
      onCancel={() => {
        setVisible(false);
        form.resetFields();
      }}
      okText={formEditMode ? "Edit Action" : "Add Action"}
      getContainer={false}
    >
      <Form
        {...layout}
        preserve={false}
        onFinish={handleFinish}
        form={form}
        onValuesChange={onValueChanges}
        initialValues={initialValues}
      >
        <Form.Item rules={rules.action_name} name="action_name" label="Name">
          <Input placeholder="Action name" />
        </Form.Item>
        <Form.Item name="action_type" label="Action type" id="eventActionType">
          <Select
            loading={actionTypesLoading}
            allowClear
            placeholder="Select type"
          >
            {actionTypes &&
              Object.keys(actionTypes).map((item: string) => (
                <Option value={item} key={item}>
                  {item}
                </Option>
              ))}
          </Select>
        </Form.Item>
        {requiredFields.includes("email_id") && (
          <Form.Item label="Email template" name="email_id">
            <Select
              loading={emailsLoading}
              allowClear
              placeholder="Select Email from mautic"
              showSearch
              filterOption={(input, option) => {
                return ((option?.label ?? '') as string).toLowerCase().includes(input.toLowerCase())}
              }
            >
              {mauticEmails &&
                Object.keys(mauticEmails.docs.emails).map((item) => (
                  <Option
                    value={String(mauticEmails.docs.emails[item].id)}
                    key={String(mauticEmails.docs.emails[item].id)}
                  >
                    {mauticEmails.docs.emails[item].name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        )}
        {requiredFields.includes("email") && (
          <Form.Item label="E-mail" name="email">
            <Input type="text" placeholder="Enter email" />
          </Form.Item>
        )}
        {requiredFields.includes("contact") && (
          <Form.Item label="Contact ID" name="contact">
            <Input type="text" placeholder="Enter id" />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default ActionModal;
