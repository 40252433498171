import { createReducer } from "@reduxjs/toolkit";
import {
  fetchActiveJobsSuccess,
  fetchActiveJobsFailed,
  fetchActiveJobs,
} from "../actions";

const DEFAULT_STATE = {
  activeJobs: 0 as number,
  activeJobsSuccess: false as Boolean,
  activeJobsFailed: false as Boolean,
  activeJobsLoading: false as Boolean,
};

const jobsReducer = createReducer(DEFAULT_STATE, (builder) => {
  builder
    .addCase(fetchActiveJobs, (state, action) => {
      state.activeJobsFailed = false;
      state.activeJobsSuccess = false;
      state.activeJobsLoading = true;
    })
    .addCase(fetchActiveJobsSuccess, (state, action) => {
      const { jobs } = action.payload;
      state.activeJobsSuccess = true;
      state.activeJobsFailed = false;
      state.activeJobsLoading = false;
      state.activeJobs = jobs;
    })
    .addCase(fetchActiveJobsFailed, (state, action) => {
      state.activeJobsFailed = true;
      state.activeJobsLoading = false;
      state.activeJobsSuccess = false;
    });
});

export default jobsReducer;
