import { createReducer } from '@reduxjs/toolkit';
import {
  blacklistDomain,
  blacklistDomainSuccess,
  blacklistDomainError, clearDomainState, locationChange
} from '../actions';

const DEFAULT_STATE = {
  blacklistingInProgress: false,
  blacklistSuccess: false,
  blacklistError: ''
};

const domainReducer = createReducer(DEFAULT_STATE, (builder) =>
  builder
    .addCase(locationChange, (state) => {
      state.blacklistError = '';
      state.blacklistSuccess = false;
      state.blacklistingInProgress = false;
    })
    .addCase(blacklistDomain, (state, action) => {
      state.blacklistingInProgress = true;
    })
    .addCase(blacklistDomainSuccess, (state, action) => {
      state.blacklistingInProgress = false;
      state.blacklistSuccess = true;
    })
    .addCase(blacklistDomainError, (state, action) => {
      state.blacklistSuccess = false;
      state.blacklistingInProgress = false;
      state.blacklistError = action.payload
    })
    .addCase(clearDomainState, (state) => {
      state.blacklistError = '';
      state.blacklistSuccess = false;
      state.blacklistingInProgress = false;
    })
);

export default domainReducer;
