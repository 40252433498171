import React from 'react';

type Props = {};

const AppLayout: React.FunctionComponent<Props> = ({ children }) => {

  return (
    <div className='app-layout'>{children}</div>
  );
};

export default AppLayout;
