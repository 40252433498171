import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table } from "antd";
import "./style.scss";
import { PromoCode, SearchType } from "../../../types";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import {
  createPromoCode,
  deletePromoCode,
  editPromoCode,
  fetchPromoCodes,
  fetchPromoTypesMinimal,
} from "../../../redux/actions";
import { RootState } from "../../../redux/reducers";
import LoadingSpinner from "../LoadingSpinner";
import CodesForm from "./Form";
import { PaginationProps } from "antd/lib/pagination";
import { codesPagination } from "../../../redux/selectors/codesSelector";
import { setSearchSortObject } from "../../../utils/codes";

const mapStateToProps = (state: RootState) => ({
  codes: state.promoCodes.codes,
  codesLoading: state.promoCodes.codesLoading,
  types: state.promoCodes.typesMinimal,
  pagination: codesPagination(state),
  sortOrder: state.promoCodes.codesSort,
});

const mapDispatchToProps = {
  fetchCodes: fetchPromoCodes,
  fetchTypes: fetchPromoTypesMinimal,
  editCode: editPromoCode,
  createCode: createPromoCode,
  deleteCode: deletePromoCode,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const PromoCodes: React.FunctionComponent<Props> = ({
  codes,
  codesLoading,
  fetchCodes,
  editCode,
  deleteCode,
  createCode,
  types,
  pagination,
  sortOrder,
  fetchTypes,
}) => {
  const [formValues, setFormValues] = useState<PromoCode>({} as PromoCode);
  const [formModalVisibility, setFormModalVisibility] = useState(false);

  const openFormModal = () => setFormModalVisibility(true);
  const closeFormModal = () => {
    setFormModalVisibility(false);
    setFormValues({} as PromoCode);
  };

  useEffect(() => {
    fetchCodes({
      page: 1,
      limit: 10,
    } as SearchType);
    fetchTypes();
  }, [fetchCodes, fetchTypes]);

  const getSortOrderFromValue = (
    value: number
  ): "ascend" | "descend" | undefined => {
    if (value === -1) return "descend";
    if (value === 1) return "ascend";
    return undefined;
  };

  const columns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      sorter: (a: PromoCode, b: PromoCode) => a.code.localeCompare(b.code),
      sortOrder: sortOrder.code ? getSortOrderFromValue(sortOrder.code) : null,
    },
    {
      title: "Type",
      dataIndex: ["type", "name"],
      key: "type",
    },
    {
      title: "Owner",
      dataIndex: ["owner", "email"],
      key: "owner",
    },
    {
      title: "Expires",
      dataIndex: "expires",
      key: "expires",
      sorter: (a: any, b: any) =>
        moment(a.expires).unix() - moment(b.expires).unix(),
      sortOrder: sortOrder.expires
        ? getSortOrderFromValue(sortOrder.expires)
        : null,
    },
    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortOrder.createdAt
        ? getSortOrderFromValue(sortOrder.createdAt)
        : null,
    },
    {
      title: "Creator",
      dataIndex: ["creator", "email"],
      key: "creator",
    },
  ];

  const onRowClick = (record: PromoCode) => {
    return {
      onClick: () => {
        setFormValues(record);
        openFormModal();
      },
    };
  };

  const handleTableChange = (
    pagination: PaginationProps,
    sorter: any,
    filters: any
  ) => {
    if (pagination.pageSize && pagination.current) {
      const sort = setSearchSortObject(filters);
      fetchCodes({
        page: pagination.current,
        limit: pagination.pageSize,
        sort: sort,
      } as SearchType);
    }
  };

  const addCode = () => {
    setFormValues({} as PromoCode);
    openFormModal();
  };

  return (
    <Row>
      {codesLoading && <LoadingSpinner />}
      {!codesLoading && (
        <Col span={24}>
          <Row>
            <Col span={2}>
              <h1 className="tab-header">Codes</h1>
            </Col>
            <Col span={20}></Col>
            <Col span={2}>
              <Button
                type="primary"
                className="btn-position-right"
                onClick={addCode}
              >
                Add new
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={codes}
            columns={columns}
            onRow={onRowClick}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              pageSizeOptions: ["10", "20", "30", "50", "100"],
              showSizeChanger: true,
            }}
            bordered
            rowKey={(value) => value._id}
          />
        </Col>
      )}
      <CodesForm
        initialValues={formValues}
        closeFormModal={closeFormModal}
        onCodeEditSubmit={editCode}
        typesArr={types}
        deleteCode={deleteCode}
        createCode={createCode}
        visible={formModalVisibility}
      />
    </Row>
  );
};

export default connector(PromoCodes);
