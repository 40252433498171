import { createSelector } from "@reduxjs/toolkit";
import { RootStateType } from '../store';

export const codesBranch = (state: RootStateType) => state.promoCodes;
export const codeSearch = createSelector(codesBranch, (branch) => branch.codesSearch);
export const codes = createSelector(codesBranch, (branch) => branch.codes);
export const typesSearch = createSelector(codesBranch, (branch)=> branch.typesSearch);
export const types = createSelector(codesBranch, (branch) => branch.types);
export const usages = createSelector(codesBranch, (branch) => branch.usages);
export const codesPagination = createSelector(codesBranch, (branch) => ({
  total : branch.codesPagination.totalDocs,
  current: branch.codesPagination.page,
  pageSize: branch.codesPagination.limit,
}));
export const typesPagination = createSelector(codesBranch, (branch) => ({
  total : branch.typesPagination.totalDocs,
  current : branch.typesPagination.page,
  pageSize : branch.typesPagination.limit,
}));
export const usagePagination = createSelector(codesBranch, (branch) => ({
  total : branch.usagesPagination.totalDocs,
  current : branch.usagesPagination.page,
  pageSize : branch.usagesPagination.limit,
}));