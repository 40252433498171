import React from 'react';

export const popOverTypeForm = {
    value: (
      <div>
        <p>Percentage bonus given by code of that type.</p>
        <p>0: Unlimited</p>
      </div>
    ),
    usage: (
      <div>
        <p>How many times code of that type can be used by UNIQUE users.</p>
        <p>0: Unlimited</p>
      </div>
    ),
    user_usage: (
      <div>
        <p>How many times code of that type can be used by the same user.</p>
        <p>0: Unlimited</p>
      </div>
    ),
    mode: (
      <div>
        <p>
          Two-way : Bonus credits are also received by second user specified in
          owner field.
        </p>
        <p>
          One-way : Bonus credits are received only by user who enters the code.
        </p>
      </div>
    ),
    kind: (
      <div>
        <p>
          default : User gets more credits from purchase.
        </p>
        <p>
          stripe_subscription : Promo code is only valid for subscription. The price discount is granted for each month of the subscription.
        </p>
      </div>
    ),
    product_type: (
      <div>
        <p>
          0: Default behavior.
        </p>
        <p>1: Valid for annual subscription only.</p>
        <p>2: Valid for monthly subscription only.</p>
        <p>3: Valid for subscriptions.</p>
        <p>4: Valid for one time payments.</p>
      </div>
    ),
  };

export const popOverCodesForm = {
    checkboxGroup: (
      <div>
        <ul>
          <li>
            <p>Letters : Adds to generator pool capital and small letters (A-z).</p>
          </li>
          <li>
            <p>Numbers : Adds to generator pool of numbers from 0 to 9.</p>
          </li>
          <li>
            <p>
              Type name : Adds contents of type field as a prefix ending with
              '-'.
            </p>
          </li>
        </ul>
        <p>
          Note : If both charset option checkboxes are UNCHECKED generator will
          use BOTH letters and numbers option.
        </p>
      </div>
    ),
    expires: (
      <div>
        <p>After that date, code is no longer usable.</p>
      </div>
    ),
    owner: (
      <div>
        <p>Email of user that will benefit from code usage by another user.</p>
        <p>
          Field is required when Type field is set to a type with mode set to
          Two-way ( true )
        </p>
      </div>
    ),
  };

export const popoverGeneratorForm = {

    checkboxGroup: (
      <div>
        <ul>
          <li>
            <p>Letters : Adds to generator pool capital and small letters (A-z).</p>
          </li>
          <li>
            <p>Numbers : Adds to generator pool of numbers from 0 to 9.</p>
          </li>
          <li>
            <p>
              Type name : Adds contents of type field as a prefix ending with
              '-'.
            </p>
          </li>
        </ul>
        <p>
          Note : If both charset option checkboxes are UNCHECKED generator will
          use BOTH letters and numbers option.
        </p>
      </div>
    ),
    expires: (
      <div>
        <p>After that date, code is no longer usable.</p>
      </div>
    ),
    owner: (
      <div>
        <p>Email of user that will benefit from code usage by another user.</p>
        <p>
          Filed is required when Type field is set to a type with mode set to
          Two-way ( true )
        </p>
      </div>
    ),
    limit_length: (
      <div>
        <p>Generated string length.</p>
        <p>Note : Does not include type name length.</p>
      </div>
    ),
    amount: (
      <div>
        <p>Generator will yield given amount of codes.</p>
      </div>
    ),
    type: (
      <div>
        <p>Type of generated codes.</p>
        <p>Note : Types can be added in types card.</p>
      </div>
    ),

};
