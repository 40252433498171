import React from "react";
import { useQuery } from 'react-query';
import { fetchChannelsStats } from "./query";
import "./index.scss";

const Channels: React.FC = () => {

  const stats = useQuery('stats', fetchChannelsStats, { refetchInterval: 10000 });

  return (
    <div className="channels-container">
      <div className="channels-header">
        <p>Channels stats</p>
      </div>
      <div className="channels-content">
        <div className="api">
          <p>Api requests: {stats.data && stats.data.api ? stats.data.api : ""}</p>
        </div>
        <div className="web">
          <p>Web requests: {stats.data && stats.data.web ? stats.data.web : ""}</p>
        </div>
      </div>
    </div>
  );
};

export default Channels;
