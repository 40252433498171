import React from 'react';
import { Spin } from 'antd';

type Props = {
  message?: string;
};

const Loader: React.FunctionComponent<Props> = ({
  message = 'Loading'
}) => {
  return <div className='loader'><Spin tip={message} /></div>;
};

export default Loader;
