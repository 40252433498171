import React, { useEffect, useState } from "react";
import {PROMO_TYPE_PRODUCT_TYPES, PromoType, SearchType} from "../../../types";
import { Button, Col, Row, Table } from "antd";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../../redux/reducers";
import {
  createPromoType,
  deletePromoType,
  editPromoType,
  fetchPromoTypes,
} from "../../../redux/actions";
import TypesForm from "./Form";
import LoadingSpinner from "../LoadingSpinner";
import { PaginationProps } from "antd/lib/pagination";
import { typesPagination } from "../../../redux/selectors/codesSelector";
import moment from "moment";
import { setSearchSortObject } from "../../../utils/codes";

const mapStateToProps = (state: RootState) => ({
  types: state.promoCodes.types,
  typesLoading: state.promoCodes.typesLoading,
  pagination: typesPagination(state),
  sortOrder: state.promoCodes.typesSort,
});

const mapDispatchToProps = {
  fetchTypes: fetchPromoTypes,
  editType: editPromoType,
  createType: createPromoType,
  deleteType: deletePromoType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

const PromoTypes: React.FC<Props> = ({
  fetchTypes,
  editType,
  createType,
  deleteType,
  typesLoading,
  types,
  pagination,
  sortOrder,
}) => {
  const [formModalVisibility, setFormModalVisibility] = useState(false);
  const [formValues, setFormValues] = useState({} as PromoType);
  const openFormModal = () => {
    setFormModalVisibility(true);
  };
  const closeFormModal = () => {
    setFormModalVisibility(false);
    setFormValues({} as PromoType);
  };
  const addNew = () => {
    setFormValues({} as PromoType);
    openFormModal();
  };

  useEffect(() => {
    fetchTypes({
      limit: 10,
      page: 1,
    } as SearchType);
  }, [fetchTypes]);

  const onRowClick = (record: PromoType) => {
    return {
      onClick: () => {
        setFormValues(record);
        openFormModal();
      },
    };
  };

  const getSortOrderFromValue = (
    value: number
  ): "ascend" | "descend" | undefined => {
    if (value === -1) return "descend";
    if (value === 1) return "ascend";
    return undefined;
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a: PromoType, b: PromoType) => a.name.localeCompare(b.name),
      sortOrder: sortOrder.name ? getSortOrderFromValue(sortOrder.name) : null,
    },
    {
      title: "Bonus",
      dataIndex: "value",
      key: "value",
      sorter: (a: PromoType, b: PromoType) => a.value - b.value,
      sortOrder: sortOrder.value
        ? getSortOrderFromValue(sortOrder.value)
        : null,
      render: (val: any) => <span>{val}%</span>,
    },
    {
      title: "Usage",
      dataIndex: "usage",
      key: "usage",
      sorter: (a: PromoType, b: PromoType) => a.usage - b.usage,
      sortOrder: sortOrder.usage
        ? getSortOrderFromValue(sortOrder.usage)
        : null,
      render: (val: any) => <span>{val === 0 ? "unlimited" : val}</span>,
    },
    {
      title: "User usage",
      dataIndex: "user_usage",
      key: "user_usage",
      sorter: (a: PromoType, b: PromoType) => a.user_usage - b.user_usage,
      sortOrder: sortOrder.user_usage
        ? getSortOrderFromValue(sortOrder.user_usage)
        : null,
      render: (val: any) => <span>{val === 0 ? "unlimited" : val}</span>,
    },
    {
      title: "Kind",
      dataIndex: "kind",
      key: "kind",
      render: (val: any) => <span>{val}</span>,
    },
    {
      title: "Mode",
      dataIndex: "mode",
      key: "mode",
      render: (val: any) => <span>{val ? "Two-way" : "One-way"}</span>,
    },
    {
      title: "Product type",
      dataIndex: "product_type",
      key: "product_type",
      sorter: (a: PromoType, b: PromoType) => a.product_type - b.product_type,
      sortOrder: sortOrder.product_type
        ? getSortOrderFromValue(sortOrder.product_type)
        : null,
      render: (val: any) => (
        <span>{PROMO_TYPE_PRODUCT_TYPES[val]}</span>
      ),
    },

    {
      title: "Created",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) =>
        moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      sortOrder: sortOrder.createdAt
        ? getSortOrderFromValue(sortOrder.createdAt)
        : null,
    },
    {
      title: "Creator",
      dataIndex: ["creator", "email"],
      key: "creator", // sorter: (a: PromoType, b: PromoType) => {
      //   if ( a.creator && b.creator && a.creator.email && b.creator.email) {
      //     return a.creator.email.localeCompare(b.creator.email);
      //   } else {
      //     return 0;
      //   }
      // },
    },
  ];

  const handleTableChange = (
    pagination: PaginationProps,
    sorter: any,
    filters: any
  ) => {
    if (pagination.pageSize && pagination.current) {
      const sort = setSearchSortObject(filters);
      fetchTypes({
        page: pagination.current,
        limit: pagination.pageSize,
        sort: sort,
      } as SearchType);
    }
  };

  return (
    <Row>
      {!typesLoading && (
        <Col span={24}>
          <Row>
            <Col span={2}>
              <h1 className="tab-header">CodeTypes</h1>
            </Col>
            <Col span={20}></Col>
            <Col span={2}>
              <Button
                type="primary"
                onClick={addNew}
                className="btn-position-right"
              >
                Add new
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={types}
            columns={columns}
            onRow={onRowClick}
            onChange={handleTableChange}
            pagination={{
              ...pagination,
              pageSizeOptions: ["10", "20", "30", "50", "100"],
              showSizeChanger: true,
            }}
            bordered
            rowKey={(value) => value._id}
          />
        </Col>
      )}
      {typesLoading && <LoadingSpinner />}
      <TypesForm
        initialValues={formValues}
        closeFormModal={closeFormModal}
        onTypeEditSubmit={editType}
        createType={createType}
        deleteType={deleteType}
        visible={formModalVisibility}
      />
    </Row>
  );
};

export default connector(PromoTypes);
