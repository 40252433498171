import React from "react";
import { Col, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./style.scss";

const LoadingSpinner: React.FC = () => {
  return (
    <React.Fragment>
      <Col span={6}></Col>
      <Col span={12}>
        <Spin
          className="spinner-center"
          indicator={<LoadingOutlined style={{ fontSize: "15vw" }} spin />}
        />
      </Col>
      <Col span={6}></Col>
    </React.Fragment>
  );
};

export default LoadingSpinner;
