import React from 'react';

type Props = {};

const MainPage: React.FunctionComponent<Props> = () => {

  return (
    <div>Main page</div>
  );
};

export default MainPage;
